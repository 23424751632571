export default function getStyle() {
  const styles = (theme) => ({
    root: {
      marginBottom: theme.spacing(10),
    },
    meritTitle: {
      fontSize: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    meritContent: {
      padding: '30px',
      background: '#F4F4F4',
      borderRadius: '10px',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '20px',
      },
    },
    meritChard: {
      fontWeight: 'bold',
      color: '#23456F',
      marginLeft: '8px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    meritIcon: {
      fontSize: '60px',
      color: '#23456F',
    },
    meritXsMt: {
      [theme.breakpoints.down('xs')]: {
        marginTop: '20px',
      },
    },
    title: {
      borderBottom: 'solid 3px #C3D600',
      position: 'relative',
      marginBottom: '25px',
      marginTop: '100px',
      '&:after': {
        position: 'absolute',
        content: '" "',
        display: 'block',
        borderBottom: 'solid 3px #EC991E',
        bottom: '-3px',
        width: '20%',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.8rem',
        paddingTop: 0,
      },
    },
    buttonRoot: {
      fontSize: '1.8rem',
      width: '400px',
      padding: '10px',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)',
      marginRight: '100px',
      [theme.breakpoints.down('md')]: {
        width: '45%',
        marginRight: '10px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    entryBtn: {
      color: '#fff',
      backgroundColor: '#FF9900',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#FF9900',
      },
    },
    companyBtn: {
      color: '#fff',
      backgroundColor: '#338888',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#338888',
      },
    },
    btnIcon: {
      position: 'absolute',
      right: '50px',
      [theme.breakpoints.down('md')]: {
        right: '5px',
      },
    },
    btnXsMt: {
      [theme.breakpoints.down('xs')]: {
        marginTop: '30px',
      },
    },
    attention: {
      fontSize: '18px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
      },
    },
    flowRoot: {
      padding: 0,
      marginTop: '50px',
      listStyle: 'none',
    },
    flowIcon: {
      fontSize: '12px',
      color: '#fff',
      background: '#6595AA',
      padding: '5px 10px',
      marginBottom: '10px',
      display: 'block',
      borderRadius: '20px',
      position: 'relative',
      zIndex: 100,
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        fontSize: '10px',
      },
    },
    flowLi: {
      position: 'relative',
      marginBottom: '40px',
      '&:not(:first-child)::before': {
        content: '""',
        height: '60px',
        display: 'block',
        borderLeft: '4px dotted #e5e5e5',
        position: 'absolute',
        top: '-40px',
        left: 'calc(10% + 30px - 2px)',
        zIndex: 10,
      },
      '&:not(:last-child) dl::before': {
        content: '""',
        border: 'solid transparent',
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderWidth: '22px',
        borderTopColor: '#338888',
      },
      '&:not(:last-child) dl::after': {
        content: '""',
        border: 'solid transparent',
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderWidth: '20px',
        borderTopColor: '#fff',
      },
    },
    flowDl: {
      padding: '20px 30px',
      display: 'flex',
      alignItems: 'center',
      border: '2px solid #338888',
      borderRadius: '10px',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '10px',
      },
    },
    flowdt: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#6595AA',
      textAlign: 'center',
      flexBasis: '20%',
      [theme.breakpoints.down('xs')]: {
        flexBasis: '30%',
      },
    },
    flowContent: {
      marginLeft: '40px',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '15px',
        fontSize: '15px',
      },
    },
    flowTitle: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
  });
  return styles;
}
