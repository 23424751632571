import { ACTION_TYPE } from './action';

// #region Reducer
const transfer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET_TRANSFER_FLG:
      return {
        ...state,
        isUpdateSuccess: false,
        datas: null,
      };
    case ACTION_TYPE.GET_TRANSFER_SUCCESS:
      return {
        ...state,
        datas: action.datas,
      };
    case ACTION_TYPE.UPDATE_TRANSFER_SUCCESS:
      return {
        ...state,
        updateDatas: action.datas,
        isUpdateSuccess: true,
      };
    default:
      return state;
  }
};

export default transfer;
