import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export function getInitSelectValues() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.GUEST.MEMBERENTRY)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getZipCodeAddress(ZipCodeRequest) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.COMMON.ZIPCODEADDRESS, { zipCode: ZipCodeRequest.zipCode })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function saveEntry(memberEntry) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.GUEST.MEMBERENTRY, memberEntry)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getDuplicationCheckContract(contract) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.GUEST.DUPLICATIONCHECKCONTRACT, contract)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
