import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
} from '@material-ui/core';
import TabPanel from '../../../../../components/atoms/tabPanel/TabPanel';
import LinkLabel from '../../../../../components/atoms/linkLabel/LinkLabel';
import {
  actSetRegularSearchCondition, actCallApiGetRegulars, actCallApiUpdateRegularStatus,
  actMypageResetFlg,
} from '../../../../../redux/mypage/mypage/action';
import ResponsiveBookingTable from './ResponsiveBookingTable';
import InfoDialog from '../../../../../components/templates/infoDialog/InfoDialog';
import {
  checkDoTransfer,
  checkDoAbsence,
  checkDoTransferCancel,
  checkDoAbsenceCancel,
} from './regular-list-proc';
import Loading from '../../../../../components/atoms/loading/Loading';
import redirectLinks from '../../../../../constants/redirectLinks';
import MonthTitle from '../monthTitle/MonthTitle';
import { createRegularCondition } from '../../mypage-proc';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  ul: {
    paddingInlineStart: '0px',
  },
});

class RegularList extends React.Component {
  constructor(props) {
    super(props);
    const condition = createRegularCondition(props);

    this.state = {
      condition,
      confirmDialogOpen: false,
      confirmMessages: [],
      buttonMessage: '',
      buttonType: null,
      targetId: null,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mypage.regularCondition !== this.props.mypage.regularCondition) {
      if (this.props.mypage.regularCondition) {
        this.setCondition(this.props.mypage.regularCondition);
      }
    }
    if (prevProps.mypage.isRegularStatusUpdate !== this.props.mypage.isRegularStatusUpdate) {
      if (this.props.mypage.isRegularStatusUpdate) {
        this.setCompleteMessage();
      }
    }
  }

  setCompleteMessage = () => {
    const { buttonType, condition } = this.state;
    let confirmMessages;
    switch (buttonType) {
      case 1:
        confirmMessages = [
          '欠席連絡が完了しました。',
          '欠席の取消は開催直前まで行えます。',
        ];
        break;
      case 2:
        confirmMessages = [
          '欠席取消が完了しました。',
        ];
        break;
      case 3:
        confirmMessages = [
          '振替キャンセルが完了しました。',
        ];
        break;
      default:
    }
    this.props.dispatch(actCallApiGetRegulars(condition));
    this.setState({
      isLoading: false,
      buttonMessage: null,
      confirmDialogOpen: true,
      confirmMessages,
    });
  }

  setCondition = (condition) => {
    this.setState({
      condition,
    });
  }

  handleDateChange = (_, values) => {
    const { condition } = this.state;
    condition.targetMonth = values;
    this.props.dispatch(actSetRegularSearchCondition(condition));
    this.props.dispatch(actCallApiGetRegulars(condition));
  }

  onContentClick = (_, id) => {
    const content = id === 0 ? null : id;
    const { condition } = this.state;
    condition.content = content;
    condition.page = 0;
    this.props.dispatch(actSetRegularSearchCondition(condition));
    this.props.dispatch(actCallApiGetRegulars(condition));
  }

  handleChangePage = (_, newPage) => {
    const { condition } = this.state;
    condition.page = newPage;
    this.props.dispatch(actSetRegularSearchCondition(condition));
    this.props.dispatch(actCallApiGetRegulars(condition));
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { condition } = this.state;
    condition.records = rowsPerPage;
    this.props.dispatch(actSetRegularSearchCondition(condition));
    this.props.dispatch(actCallApiGetRegulars(condition));
  }

  onActionClick = () => {
    this.setState({ isLoading: true, confirmDialogOpen: false });
    this.props.dispatch(actMypageResetFlg());
    const { buttonType, targetId } = this.state;
    this.props.dispatch(actCallApiUpdateRegularStatus({ id: targetId, type: buttonType }));
  }

  onAbsenceClick = (_, row) => {
    const { condition } = this.state;
    let confirmMessages = ['欠席連絡を行います。'];
    let buttonMessage = '欠席をする';
    if (!checkDoAbsence(row)) {
      confirmMessages = ['欠席連絡可能期限を過ぎているため、欠席連絡できません', '恐れ入りますが、店舗に直接ご連絡ください'];
      buttonMessage = null;
      this.props.dispatch(actCallApiGetRegulars(condition));
    }
    this.setState({
      confirmDialogOpen: true,
      confirmMessages,
      buttonMessage,
      buttonType: 1,
      targetId: row.id,
    });
  }

  onAbsenceCancelClick = (_, row) => {
    const { condition } = this.state;
    let confirmMessages = ['欠席を取り消します。'];
    let buttonMessage = '欠席取消をする';
    if (!checkDoAbsenceCancel(row)) {
      confirmMessages = ['欠席取消可能期限を過ぎているため、欠席取消できません', '恐れ入りますが、店舗に直接ご連絡ください'];
      buttonMessage = null;
      this.props.dispatch(actCallApiGetRegulars(condition));
    }
    this.setState({
      confirmDialogOpen: true,
      confirmMessages,
      buttonMessage,
      buttonType: 2,
      targetId: row.id,
    });
  }

  onTransferCancelClick = (_, row) => {
    const { condition } = this.state;
    let confirmMessages = ['振替をキャンセルします。'];
    let buttonMessage = '振替をキャンセルする';
    if (!checkDoTransferCancel(row)) {
      confirmMessages = ['振替キャンセル可能期限を過ぎているため、振替キャンセルできません', '恐れ入りますが、店舗に直接ご連絡ください'];
      buttonMessage = null;
      this.props.dispatch(actCallApiGetRegulars(condition));
    }
    this.setState({
      confirmDialogOpen: true,
      confirmMessages,
      buttonMessage,
      buttonType: 3,
      targetId: row.id,
    });
  }

  onTransferClick = (_, row) => {
    const { condition } = this.state;
    if (!checkDoTransfer(row)) {
      const confirmMessages = ['振替期限を過ぎているため、振替できません', '恐れ入りますが、店舗に直接ご連絡ください'];
      const buttonMessage = null;
      this.props.dispatch(actCallApiGetRegulars(condition));
      this.setState({
        confirmDialogOpen: true,
        confirmMessages,
        buttonMessage,
      });
    } else {
      this.props.history.push({
        pathname: redirectLinks.TRANSFER_REGIST,
        state: {
          row,
        },
      });
    }
  }

  onClickCloseDialog = () => {
    this.setState({ confirmDialogOpen: false });
  }

  render() {
    const { classes, value, index } = this.props;
    const { regularDatas, regularContents } = this.props.mypage;
    const {
      targetMonth, content, page, records,
    } = this.state.condition;
    const {
      confirmDialogOpen, confirmMessages, buttonMessage, isLoading,
    } = this.state;

    return (
      <TabPanel value={value} index={index} style={{ width: '100%' }}>
        <Loading open={isLoading} />
        <Box className={classes.root}>
          <Box align="right" display={regularContents && regularContents.length > 1 ? '' : 'none'}>
            <ul className={classes.ul}>
              <LinkLabel searchContent={content} id={0} onClick={this.onContentClick} isFirst />
              {regularContents && regularContents.map((regularContent) => (
                <LinkLabel
                  key={regularContent.name}
                  searchContent={content}
                  targetContent={regularContent.id}
                  id={regularContent.id}
                  name={regularContent.name}
                  onClick={this.onContentClick}
                />
              ))}
            </ul>
          </Box>

          <MonthTitle
            targetMonth={targetMonth}
            handleChange={this.handleDateChange}
          />

          <ResponsiveBookingTable
            datas={regularDatas || []}
            page={page}
            records={records}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onAbsenceClick={this.onAbsenceClick}
            onAbsenceCancelClick={this.onAbsenceCancelClick}
            onTransferCancelClick={this.onTransferCancelClick}
            onTransferClick={this.onTransferClick}
          />
          <InfoDialog
            messages={confirmMessages}
            firstOnFunc={this.onClickCloseDialog}
            firstFuncText={buttonMessage ? 'キャンセル' : '閉じる'}
            secondOnFunc={this.onActionClick}
            secondFuncText={buttonMessage}
            open={confirmDialogOpen}
          />
        </Box>
      </TabPanel>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, mypage: state.mypage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(RegularList)));
