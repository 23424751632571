import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ContractInfoTable from './ContractInfoTable';
import ContractEditTable from './ContractEditTable';
import MemberEditTable from './MemberEditTable';
import TabPanel from '../../../../../components/atoms/tabPanel/TabPanel';
import { actCallApiContract, actEditFlgReset } from '../../../../../redux/mypage/mypage/action';

class ContractInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFlag: 1,
      updateFlag: false,
      data: {
        storeName: '',
        member: {},
        contract: {},
        contractList: [],
      },
    };
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mypage.contractDatas !== this.props.mypage.contractDatas) {
      if (this.props.mypage.contractDatas) {
        this.setData(this.props.mypage.contractDatas);
      }
    }
    if (prevProps.value !== this.props.value) {
      if (this.props.value === 2) {
        this.onReference();
      }
    }
    if (prevProps.mypage.updateFlag !== this.props.mypage.updateFlag) {
      if (this.props.mypage.updateFlag) {
        this.getData();
        this.onReference();
        this.setUpdateFlag();
      }
    }
  }

  getData() {
    this.props.dispatch(actCallApiContract());
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onReference = () => {
    this.setState({ displayFlag: 1 });
    this.scrollToTop();
  }

  setUpdateFlag = () => {
    this.setState({ updateFlag: true });
  }

  setData = (data) => {
    this.setState({ data });
  }

  handleClose = () => {
    this.setState({ updateFlag: false });
  }

  onClickContract = () => {
    this.props.dispatch(actEditFlgReset());
    this.setState({ displayFlag: 2 });
    this.scrollToTop();
  }

  onClickMember = () => {
    this.props.dispatch(actEditFlgReset());
    this.setState({ displayFlag: 3 });
    this.scrollToTop();
  }

  render() {
    const { value, index } = this.props;
    const {
      displayFlag, data, updateFlag,
    } = this.state;
    return (
      <TabPanel value={value} index={index} style={{ width: '100%' }}>
        <Snackbar open={updateFlag} autoHideDuration={5000} onClose={this.handleClose}>
          <MuiAlert onClose={this.handleClose} severity="success">
            登録が完了しました
          </MuiAlert>
        </Snackbar>
        <Box display={displayFlag === 1 ? '' : 'none'}>
          <ContractInfoTable
            onClickContract={this.onClickContract}
            onClickMember={this.onClickMember}
            data={data}
          />
        </Box>
        <Box display={displayFlag === 2 ? '' : 'none'}>
          <ContractEditTable data={data} isOpen={displayFlag === 2} onCancel={this.onReference} />
        </Box>

        <Box display={displayFlag === 3 ? '' : 'none'}>
          <MemberEditTable data={data} onCancel={this.onReference} isOpen={displayFlag === 3} />
        </Box>
      </TabPanel>
    );
  }
}

const mapStateToProps = (state) => ({ mypage: state.mypage });

export default (withRouter(connect(mapStateToProps)(ContractInfo)));
