import { subDays, addDays, format } from 'date-fns';

export function getBackDayParam(parma) {
  const targetDate = format(subDays(new Date(parma.targetDate), 1), 'yyyy/MM/dd');

  return {
    ...parma,
    targetDate,
  };
}

export function getNextDayParam(parma) {
  const targetDate = format(addDays(new Date(parma.targetDate), 1), 'yyyy/MM/dd');

  return {
    ...parma,
    targetDate,
  };
}
