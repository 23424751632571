import {
  REST_API,
} from '../../../constants/urls';
import HttpConnection from '../../../utils/http-connection';

export function getSurveyDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.SURVEY_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function createSurvey(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MYPAGE.SURVEY_DETAIL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
