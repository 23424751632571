// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_NOTICES: 'CALL_API_GET_NOTICES',
  CALL_API_GET_NOTICES_SUCCESS: 'CALL_API_GET_NOTICES_SUCCESS',
};
// #endregion

export const actCallApiGetNotices = () => ({
  type: ACTION_TYPE.CALL_API_GET_NOTICES,
});
export const actCallApiGetNoticesSuccess = (noticeList) => ({
  type: ACTION_TYPE.CALL_API_GET_NOTICES_SUCCESS,
  noticeList,
});
