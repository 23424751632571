import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * Function use for call api login
 *
 * @param {object} userInfo
 */
export default function login(userInfo) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER.LOGIN, { email: userInfo.loginId, password: userInfo.password })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
