import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { format, isEqual } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Button,
  Box,
  ButtonGroup,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Hidden,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';
import TableFrameCell from '../../../../../components/atoms/tableFrameCell/TableFrameCell';
import TabPanel from '../../../../../components/atoms/tabPanel/TabPanel';
import redirectLinks from '../../../../../constants/redirectLinks';
import {
  actCallApiRoomBooking, actSetBookingCondition,
} from '../../../../../redux/booking/bookingList/action';
import {
  getBookingParam, getToday, getTodayParam,
} from '../../booking-list-proc';
import {
  getBackDayParam, getNextDayParam,
} from './room-proc';
import MemberRuleButton from '../MemberRuleButton';

const styles = (theme) => ({
  root: {
    marginBottom: '100px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  container: {
    maxHeight: 700,
  },
  tdDisabled: {
    background: '#e6e6e6',
    color: '#999999',
    fontSize: '1rem',
  },
  calendarLink: {
    color: '#2196f3',
    textDecoration: 'underline',
    fontSize: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tdHoliday: {
    background: '#f5f5f5',
    color: '#000000',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    border: 'solid 1px #DDD',
  },
});

class Room extends Component {
  constructor() {
    super();
    this.state = { roomTabIndex: 0 };

    this.today = getToday();
  }

  onClick = (_, data) => {
    this.props.history.push({
      pathname: redirectLinks.BOOKING_DETAIL,
      state: { bookingId: data.id, contentId: data.contentId },
    });
  };

  onBackClick = () => {
    const param = getBackDayParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param));
    this.props.dispatch(actCallApiRoomBooking(getBookingParam(param)));
  }

  onNextClick = () => {
    const param = getNextDayParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param));
    this.props.dispatch(actCallApiRoomBooking(getBookingParam(param)));
  }

  onTodayClick = () => {
    const param = getTodayParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param));
    this.props.dispatch(actCallApiRoomBooking(getBookingParam(param)));
  }

  handleChange = (_, newValue) => {
    this.setState({ roomTabIndex: newValue });
  }

  createElement = (paramDatas, time, room) => {
    const { classes } = this.props;

    const datas = paramDatas.filter(
      (row) => row.startTime === time && row.roomId === room.id,
    );

    const key = `${time}-${room.name}`;

    if (datas.length <= 0) {
      return (
        <TableFrameCell align="center" key={key}>
          <div>-</div>
        </TableFrameCell>
      );
    }

    const data = datas[0];

    if (!data.reception) {
      return (
        <TableFrameCell align="center" className={classes.tdDisabled} key={key}>
          <div>×</div>
        </TableFrameCell>
      );
    }
    return (
      <TableFrameCell align="center" key={key}>
        <div className={classes.calendarLink} onClick={(event) => this.onClick(event, data)} role="button" aria-hidden="true">
          〇
        </div>
      </TableFrameCell>
    );
  }

  render() {
    const {
      targetDate,
      classes,
      rooms,
      times,
      datas,
      memberRule,
      holidays,
    } = this.props;

    const { roomTabIndex } = this.state;
    const isHoliday = holidays.indexOf(format(new Date(targetDate), 'yyyy/MM/dd')) > -1;

    return (
      <div className={classes.root}>
        <MemberRuleButton value={memberRule} />
        <Box mt={3}>
          <ButtonGroup size="small" aria-label="large outlined button group">
            <Button onClick={this.onTodayClick}>今日</Button>
            <Button onClick={this.onBackClick} disabled={isEqual(this.today, new Date(targetDate))}><ArrowBackIosIcon fontSize="small" /></Button>
            <Button onClick={this.onNextClick}><ArrowForwardIosIcon fontSize="small" /></Button>
          </ButtonGroup>
        </Box>
        <Box textAlign="center" mt={2} mb={1}>
          {format(new Date(targetDate), 'yyyy年M月dd日 (EEEEEE)', { locale: ja })}
        </Box>
        {
          isHoliday && (
            <div className={classes.tdHoliday}>
              休館日
            </div>
          )
        }
        {
          !isHoliday && (
            <>
              <Hidden xsDown>
                <Box mt={1} align="center">
                  <TableContainer className={classes.container}>
                    <Table className={classes.table} stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableFrameCell> </TableFrameCell>
                          {rooms.map((room) => (
                            <TableFrameCell align="center" key={`pcTitle${room.id}`}>{room.name}</TableFrameCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {times.map((time) => (
                          <TableRow key={time}>
                            <TableFrameCell>{time}</TableFrameCell>
                            {rooms.map((room) => (
                              this.createElement(datas, time, room)
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Hidden>
              <Hidden smUp>
                <div className={classes.root}>
                  <Tabs
                    value={roomTabIndex}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {rooms.map((room) => (
                      <Tab label={room.name} key={`spTitle${room.id}`} style={{ textTransform: 'none' }} />
                    ))}
                  </Tabs>
                  <Box mt={2}>
                    {rooms.map((room, index) => (
                      <TabPanel value={roomTabIndex} index={index} key={`roomTab${room.id}`}>
                        <TableContainer>
                          <Table className={classes.table}>
                            <TableBody>
                              {times.map((time) => (
                                <TableRow key={`${room.id + time}spData`}>
                                  <TableFrameCell width="50%">{time}</TableFrameCell>
                                  {this.createElement(datas, time, room)}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    ))}
                  </Box>
                </div>
              </Hidden>
            </>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ bookingList: state.bookingList });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Room)));
