import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { isEqual } from 'date-fns';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Button,
  Box, ButtonGroup, Grid, Hidden, Fab,
  TableContainer, Table, TableHead,
  TableRow, TableBody, TableCell,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';
import TableFrameCell from '../../../../../components/atoms/tableFrameCell/TableFrameCell';
import redirectLinks from '../../../../../constants/redirectLinks';
import {
  actCallApiGolfBooking, actSetBookingCondition,
} from '../../../../../redux/booking/bookingList/action';
import {
  getDays, getTargetNumber, getNextWeekParam, getBackWeekParam,
  isWeekBackDisabled, getTimes,
} from './golf-proc';
import {
  getTodayParam, getBookingParam,
} from '../../booking-list-proc';
import MemberRuleButton from '../MemberRuleButton';

const styles = (theme) => ({
  root: {
    marginBottom: '100px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '70px',
    },
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tdDisabled: {
    background: '#e6e6e6',
    color: '#999999',
  },
  calendarTd: {
    border: 'solid 1px #DDD',
    fontSize: '13px',
    padding: '5px',
    height: '50px',
    width: '60px',
  },
  calendarTdNoTarget: {
    border: 'solid 1px #DDD',
    fontSize: '13px',
    padding: '5px',
    height: '50px',
    width: '95px',
  },
  headerTd: {
    border: 'solid 1px #DDD',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: '5px',
    background: '#fff',
  },
  calendarLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gridRoot: {
    marginTop: '20px',
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  remainingContent: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  fabSizeSmall: {
    width: '35px',
    height: '35px',
  },
  tableContainer: {
    maxHeight: 700,
  },
  stickyTop: {
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
  },
  stickySecond: {
    top: '35px',
    left: 0,
    zIndex: 3,
    position: 'sticky',
  },
  tdHoliday: {
    background: '#f5f5f5',
    color: '#000000',
    fontWeight: 'bold',
    border: 'solid 1px #DDD',
    fontSize: '13px',
    padding: '5px',
    height: '50px',
    // width: '60px',
  },
});

class Golf extends Component {
  constructor(props) {
    super(props);
    this.targets = [{ title: 'シミュ\nレーション', targetType: 1 }, { title: 'レッスン', targetType: 2 }, { title: '的打ち', targetType: 3 }];
    this.state = { spTargetDate: props.targetDate };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.targetDate !== this.props.targetDate) {
      if (this.props.targetDate) {
        this.onSpDayClick(null, this.props.targetDate);
      }
    }
  }

  onSpDayClick = (_, spTargetDate) => {
    this.setState({ spTargetDate });
  }

  onBackClick = () => {
    const param = getBackWeekParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param));
    this.props.dispatch(actCallApiGolfBooking(getBookingParam(param)));
  }

  onNextClick = () => {
    const param = getNextWeekParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param));
    this.props.dispatch(actCallApiGolfBooking(getBookingParam(param)));
  }

  onTodayClick = () => {
    const param = getTodayParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param));
    this.props.dispatch(actCallApiGolfBooking(getBookingParam(param)));
  }

  onClick = (_, data, targetType, storeId) => {
    let url = redirectLinks.BOOKING_DETAIL;
    if (data.number === 0 && data.isCancelWaitAccepted) {
      url = redirectLinks.BOOKING_DETAIL_CANCEL_WAITE;
    }

    this.props.history.push({
      pathname: url,
      state: {
        bookingId: data.id, golfTargetType: targetType, contentId: 2, storeId,
      },
    });
  }

  freeHitElement = (datas, time, targetDate, type, idx, arrayLength) => {
    const {
      classes, holidays, isTargetAccepted, storeId,
    } = this.props;
    const isHoliday = holidays.indexOf(targetDate) > -1;
    if (isHoliday) {
      return (type === 1 && idx === 0)
        ? (<td colSpan={isTargetAccepted ? 3 : 2} rowSpan={String(arrayLength)} className={classes.tdHoliday} align="center" key={`dataElement${type}`}><div>休館日</div></td>)
        : null;
    }
    const data = getTargetNumber(datas, time, targetDate, type);

    if (data === null || (data.number === -1)) {
      return (<td className={isTargetAccepted ? classes.calendarTd : classes.calendarTdNoTarget} align="center" key={`dataElement${type}`}><div>-</div></td>);
    }

    if (data.number === -2) {
      return (
        <td className={`${isTargetAccepted ? classes.calendarTd : classes.calendarTdNoTarget} ${classes.tdDisabled}`} align="center" key={`dataElement${type}`}>
          <div>×</div>
          <div className={classes.remainingContent}>
            受付終了
          </div>
        </td>
      );
    }

    if (data.number === 0 && !data.isCancelWaitAccepted) {
      return (<td className={`${isTargetAccepted ? classes.calendarTd : classes.calendarTdNoTarget} ${classes.tdDisabled}`} align="center" key={`dataElement${type}`}><div>×</div></td>);
    }

    if (data.number === 0 && data.isCancelWaitAccepted) {
      return (
        <td className={isTargetAccepted ? classes.calendarTd : classes.calendarTdNoTarget} align="center" key={`dataElement${type}`}>
          <div className={classes.calendarLink} onClick={(event) => this.onClick(event, data, type, storeId)} role="button" aria-hidden="true">
            △
          </div>
          <div className={classes.remainingContent}>
            {`残 (${data.number}) `}
          </div>
        </td>
      );
    }

    return (
      <td className={isTargetAccepted ? classes.calendarTd : classes.calendarTdNoTarget} align="center" key={`dataElement${type}`}>
        <div className={classes.calendarLink} onClick={(event) => this.onClick(event, data, type, storeId)} role="button" aria-hidden="true">
          〇
        </div>
        <div className={classes.remainingContent}>
          {`残 (${data.number}) `}
        </div>
      </td>
    );
  }

  render() {
    const {
      datas, classes, targetDate, memberRule, isTargetAccepted,
    } = this.props;
    const { spTargetDate } = this.state;
    const days = getDays(targetDate);
    const times = getTimes(datas);

    return (
      <div className={classes.root}>
        <Box mt={{ xs: 2, sm: 0 }}>
          <MemberRuleButton value={memberRule} />
          <Box mt={3}>
            <Alert severity="warning">受付終了と表示されていても予約を受付可能な場合もあります。詳しくは店舗までお問い合わせください。</Alert>
          </Box>
          <Grid container className={classes.gridRoot}>
            <Grid item xs align="left">
              <ButtonGroup size="small" aria-label="large outlined button group">
                <Button onClick={this.onTodayClick}>今日</Button>
                <Button onClick={this.onBackClick} disabled={isWeekBackDisabled(targetDate)}><ArrowBackIosIcon fontSize="small" /></Button>
                <Button onClick={this.onNextClick}><ArrowForwardIosIcon fontSize="small" /></Button>
              </ButtonGroup>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={6} className={classes.textCenter}>
                {`${days[0].dateWeek} ～ ${days[6].dateWeek}`}
              </Grid>
              <Grid item xs align="right"> </Grid>
            </Hidden>
          </Grid>
        </Box>
        <Hidden smDown>
          <Box mt={1} align="center">
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerTd} rowSpan={2}> </TableCell>
                    {days.map((column) => (
                      <TableCell className={`${classes.headerTd} ${classes.stickyTop}`} colSpan={isTargetAccepted ? 3 : 2} align="center" key={`${column.day}tableTh`}>
                        {`${column.day}日 (${column.week})`}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    {days.map((column) => (
                      this.targets.map((target) => (
                        target.title === '的打ち' && !isTargetAccepted ? ''
                          : (
                            <TableCell className={`${classes.headerTd} ${classes.stickySecond}`} align="center" key={`${column.day} ${target.title}`}>
                              {target.title.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
                            </TableCell>
                          )

                      ))
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {times.map((time, idx, array) => (
                    <tr key={`${time}tableRow`}>
                      <td className={classes.headerTd} align="center">{time}</td>
                      {days.map((column) => (
                        this.targets.map((target) => (
                          target.title === '的打ち' && !isTargetAccepted ? ''
                            : (
                              this.freeHitElement(
                                datas, time, column.date, target.targetType, idx, array.length,
                              )
                            )
                        ))
                      ))}
                    </tr>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.textCenter} mt={2}>
            {days.map((column, index) => (
              <Box ml={{ xs: index === 0 ? 0 : 2, sm: index === 0 ? 0 : 5 }} key={`${column.day}spFab`}>
                <Box textAlign="center" fontSize={14}>{column.month}</Box>
                <Fab
                  color={isEqual(new Date(spTargetDate), new Date(column.date)) ? 'primary' : 'default'}
                  size="small"
                  variant={!isEqual(new Date(spTargetDate), new Date(column.date)) ? 'extended' : 'round'}
                  classes={{
                    sizeSmall: classes.fabSizeSmall,
                  }}
                  onClick={(event) => this.onSpDayClick(event, column.date)}
                >
                  {column.day}
                </Fab>
                <Box textAlign="center" fontSize={14} mt={1}>{column.week}</Box>
              </Box>
            ))}
          </Box>
          <Box mt={3} align="center">
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableFrameCell width="25%"> </TableFrameCell>
                    <TableFrameCell align="center" width="25%">
                      シミュ
                      <br />
                      レーション
                    </TableFrameCell>
                    <TableFrameCell align="center" width="25%">レッスン</TableFrameCell>
                    {isTargetAccepted && (
                    <TableFrameCell align="center" width="25%">的打ち</TableFrameCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {times.map((time, idx, array) => (
                    <TableRow key={`${time}spRow`}>
                      <TableFrameCell align="center">{time}</TableFrameCell>
                      <>
                        {this.freeHitElement(datas, time, spTargetDate, 1, idx, array.length)}
                        {this.freeHitElement(datas, time, spTargetDate, 2, idx, array.length)}
                        {isTargetAccepted
                        && (this.freeHitElement(datas, time, spTargetDate, 3, idx, array.length))}
                      </>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ bookingList: state.bookingList });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Golf)));
