import { call, put, takeLatest } from 'redux-saga/effects';
import { getMe, getStores, actNoticesAgreeEntry } from '../../services/common/common.service';
import {
  ACTION_TYPE, actGetMeError, actGetMeSuccess,
  actGetStoresSuccess, actNotProcServerError, actCallApiNoticesAgreeEntrySuccess,
} from './action';

function* callApiGetMe() {
  try {
    const result = yield call(getMe);
    yield put(actGetMeSuccess(result));
  } catch (error) {
    yield put(actGetMeError(error));
  }
}

function* callApiGetStores() {
  try {
    const stores = yield call(getStores);
    yield put(actGetStoresSuccess(stores));
  } catch (error) {
    yield put(actNotProcServerError(error));
  }
}

function* callNoticesAgreeEntry({ noticesIds }) {
  try {
    yield call(actNoticesAgreeEntry(noticesIds));
    yield put(actCallApiNoticesAgreeEntrySuccess());
  } catch (error) {
    yield put(actNotProcServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallCommon() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ME, callApiGetMe);
  yield takeLatest(ACTION_TYPE.CALL_API_STORES, callApiGetStores);
  yield takeLatest(ACTION_TYPE.CALL_API_NOTICES_AGREE_ENTRY, callNoticesAgreeEntry);
}
