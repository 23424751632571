import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Button,
  Chip,
} from '@material-ui/core';
import DangerButton from '../../../../../components/atoms/dangerButton/DangerButton';
import redirectLinks from '../../../../../constants/redirectLinks';
import { checkDoCancel, checkOnlineCulture } from './booking-list-proc';
import Pagination from '../../../../../components/atoms/pagination/Pagination';

const styles = () => ({
  cardTop: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  flexTitle: {
    flex: '1 1',
  },
  bookingDetailButton: {
    marginLeft: '20px',
  },
  bookingAttendentButton: {
    marginRight: '20px',
  },
  openedChip: {
    height: '25px',
    background: '#0070c0',
    color: '#fff',
  },
});

class DoingBookingTable extends React.Component {
  onClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 0 },
    });
  }

  onCancelClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 1 },
    });
  }

  onAttendanceClick = (_, row) => {
    const setUrl = `${redirectLinks.ATTENDANCE_ONLINE_CULTURE}/?key=${row.bookingCode}`;
    this.props.history.push({
      pathname: setUrl,
    });
  }

  render() {
    const {
      classes,
      datas,
      page,
      records,
      handleChangeRowsPerPage,
      handleChangePage,
    } = this.props;
    const rows = datas.content ? datas.content.filter((row) => row.memberStatus === 5) : [];

    return (
      <>
        {rows.map((row) => (
          <Box boxShadow={3} mt={3} key={`doingBooking-${row.bookingCode}`}>
            <Box p={3}>
              <div className={classes.cardTop}>
                <div className={classes.flexTitle}>
                  <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">{row.menuName}</Box>
                </div>
                <div>
                  <Chip color="secondary" label={row.contentName} />
                </div>
              </div>
              { row.bookingStatus === 3 && (
                <Box mt={2}><Chip label="開講" className={classes.openedChip} /></Box>
              )}
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={15}>予約番号</Box>
                <Box fontSize={15}>{row.bookingCode}</Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={15}>ご予約日時</Box>
                <Box fontSize={15}>{`${row.targetDate} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}</Box>
              </Box>
              <Box mt={2} display={row.roomName ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>Room</Box>
                <Box fontSize={15}>{row.roomName}</Box>
              </Box>
              <Box mt={2} display={row.boxNumber ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>打席番号</Box>
                <Box fontSize={15}>{row.boxNumber}</Box>
              </Box>
            </Box>
            <Box className={classes.cardTop} pr={4} pb={2}>
              <div className={classes.flexTitle} />
              <Box display="flex">
                { checkOnlineCulture(row) && (<Box mr={2}><Button variant="contained" color="primary" onClick={(event) => this.onAttendanceClick(event, row)}>出席する</Button></Box>)}
                { checkDoCancel(row) && (<Box mr={2}><DangerButton variant="contained" label="キャンセルする" onClick={(event) => this.onCancelClick(event, row)} /></Box>) }
                <Button variant="contained" onClick={(event) => this.onClick(event, row)}>予約内容詳細</Button>
              </Box>
            </Box>
          </Box>
        ))}
        <Box border={1} borderColor="grey.500" display={rows.length === 0 ? 'flex' : 'none'} minHeight={100} textAlign="center" justifyContent="center" alignItems="center">
          予約中の予約情報はありません。
        </Box>
        <Pagination
          totalElements={datas.totalElements}
          records={records}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(DoingBookingTable));
