import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    background: '#E7E6E6',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    margin: '0 -1px -1px 0',
  },
  inputArea: {
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    margin: '0 -1px -1px 0',
  },
  brealAll: {
    wordBreak: 'break-all',
  },
}));

export default function SideDisplayGrid(props) {
  const { title, content, isNotShow } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item md={3} xs={12} className={classes.title} style={{ display: isNotShow ? 'none' : '' }}>
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Grid>
      <Grid item md={9} xs={12} className={classes.inputArea} style={{ display: isNotShow ? 'none' : '' }}>
        <Box display="flex">
          <Box className={classes.brealAll}>{content && content.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
        </Box>
      </Grid>
    </>
  );
}

SideDisplayGrid.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  isNotShow: PropTypes.bool,
};

SideDisplayGrid.defaultProps = {
  content: '',
  isNotShow: false,
};
