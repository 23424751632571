import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, makeStyles } from '@material-ui/core';
import RequiredChip from '../../atoms/requiredChip/RequiredChip';

const useStyles = makeStyles((theme) => ({
  title: {
    background: '#E7E6E6',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    margin: '0 -1px -1px 0',
  },
  inputArea: {
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    margin: '0 -1px -1px 0',
  },
}));

export default function SideInputGrid(props) {
  const {
    title,
    isRequired,
    children,
    key,
  } = props;
  const classes = useStyles();

  return (
    <Grid container key={key}>
      <Grid item md={3} xs={12} className={classes.title}>
        <Box fontWeight="fontWeightBold">
          {title}
          {isRequired && <RequiredChip />}
        </Box>
      </Grid>
      <Grid item md={9} xs={12} className={classes.inputArea}>
        {children}
      </Grid>
    </Grid>
  );
}

SideInputGrid.propTypes = {
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SideInputGrid.defaultProps = {
  isRequired: false,
};
