import format from 'date-fns/format';

export function createRegularCondition(props) {
  const targetMonth = format(new Date(), 'yyyy/MM');
  let param = { targetMonth, page: 0, records: 10 };
  if (props.mypage.regularCondition) {
    param = props.mypage.regularCondition;
  }
  return param;
}

export function createBookingCondition(props) {
  const targetMonth = format(new Date(), 'yyyy/MM');
  let param = {
    targetMonth,
    content: null,
    page: 0,
    records: 50,
    reservationType: 1,
    alignment: 'booking',
  };
  if (props.mypage.bookingCondition) {
    param = props.mypage.bookingCondition;
  }
  return param;
}

export function createSurveyListCondition(props) {
  const targetMonth = format(new Date(), 'yyyy/MM');
  let param = {
    targetMonth,
    page: 0,
    records: 50,
    alignment: 'unanswered',
  };
  if (props.surveyList.surveyListCondition) {
    param = props.surveyList.surveyListCondition;
  }
  return param;
}
