import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  liFirst: {
    display: 'inline-block',
  },
  li: {
    display: 'inline-block',
    borderLeft: '1px solid #000',
  },
  text: {
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  buttonRoot: {
    color: '#2196f3',
    textDecoration: 'underline',
    padding: 0,
    '&:hover': {
      color: '#2196f3',
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
}));

export default function LinkLabel(props) {
  const {
    searchContent, targetContent, id, name, onClick, isFirst,
  } = props;
  const classes = useStyles();

  const onContentClick = (event, contentId) => {
    onClick(event, contentId);
  };

  const lable = <li className={isFirst ? classes.liFirst : classes.li} key={id}><Box variant="subtitle1" mx={1} className={classes.text}>{name}</Box></li>;
  const link = (
    <li className={isFirst ? classes.liFirst : classes.li} key={id}>
      <Box mx={1}>
        <Button
          color="primary"
          className={classes.buttonRoot}
          size="small"
          onClick={(event) => onContentClick(event, id)}
        >
          {name}
        </Button>
      </Box>
    </li>
  );
  return (
    searchContent === targetContent ? (lable) : (link)
  );
}

LinkLabel.propTypes = {
  searchContent: PropTypes.number,
  targetContent: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

LinkLabel.defaultProps = {
  searchContent: null,
  targetContent: null,
  id: 0,
  name: '全て',
  onClick: null,
};
