import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export function getMe() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.USER.ME)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getStores() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.COMMON.STORES, { isNotDispMain: true })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function actNoticesAgreeEntry(noticesIds) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.USER.NOTICES_AGREE_ENTRY, noticesIds)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
