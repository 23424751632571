// #region Type
export const ACTION_TYPE = {
  RESET_TRANSFER_FLG: 'RESET_TRANSFER_FLG', // 振替登録で使用するフラグ

  CALL_API_GET_TRANSFERS: 'CALL_API_GET_TRANSFERS',
  GET_TRANSFER_SUCCESS: 'GET_TRANSFER_SUCCESS',

  CALL_API_UPDATE_TRANSFER: 'CALL_API_UPDATE_TRANSFER',
  UPDATE_TRANSFER_SUCCESS: 'UPDATE_TRANSFER_SUCCESS',
};
// #endregion

export const actCallApiResetTransferFlg = () => ({
  type: ACTION_TYPE.RESET_TRANSFER_FLG,
});

// #region 振替対象取得
export const actCallApiGetTransfers = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_TRANSFERS,
  param,
});

export const actGetTransferSuccess = (datas) => ({
  type: ACTION_TYPE.GET_TRANSFER_SUCCESS,
  datas,
});
// #endregion

// #region 振替登録
export const actCallApiUpdateTransfer = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_TRANSFER,
  param,
});

export const actUpadeteTransferSuccess = (datas) => ({
  type: ACTION_TYPE.UPDATE_TRANSFER_SUCCESS,
  datas,
});
// #endregion
