import React from 'react';
import { Container, Box } from '@material-ui/core';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/atoms/loading/Loading';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import redirectLinks from '../../../constants/redirectLinks';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import {
  actCallApiGetSurveyDetail,
  actCallApiCreateSurvey,
  actResetSurveyDetailFlg,
} from '../../../redux/mypage/surveyDetail/action';
import SurveyDetailInput from './SurveyDetailInput';
import SurveyDetailCorrect from './SurveyDetailCorrect';
import SurveyDetailPointGrantModal from './SurveyDetailPointGrantModal';

class SurveyDetail extends React.Component {
  constructor(props) {
    super(props);

    let bookingId;
    let contentId;

    if (props.location.state && props.location.state.bookingId) {
      const locationState = props.location.state;
      bookingId = locationState.bookingId;
      contentId = locationState.contentId;
    }

    this.state = {
      isLoading: false,
      isComplete: false,
      surveyData: { data: {}, surveySelects: [], errorMessages: [] },
      completeData: { point: 0, isPointed: false, errorMessages: [] },
      bookingId,
      contentId,
      rating: null,
      comment: '',
      errorMessage: '',
      isPointGrantOpen: false,
    };

    this.breadcrumbs = [
      { name: 'マイページ', url: redirectLinks.MYPAGE },
      { name: '授業アンケート' },
    ];

    if (!bookingId) {
      this.props.history.push({
        pathname: redirectLinks.MYPAGE,
      });
    } else {
      this.props.dispatch(actCallApiGetSurveyDetail({ bookingId, contentId }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.surveyDetail.data !== this.props.surveyDetail.data) {
      if (this.props.surveyDetail.data) {
        this.setData(this.props.surveyDetail.data);
      }
    }
    if (prevProps.surveyDetail.isUpdateSuccess !== this.props.surveyDetail.isUpdateSuccess) {
      if (this.props.surveyDetail.isUpdateSuccess) {
        this.setComplete(this.props.surveyDetail.completeData);
      }
    }
  }

  setComplete(completeData) {
    this.setState({
      isLoading: false,
      isComplete: true,
      completeData,
      isPointGrantOpen: completeData.pointed,
    });
  }

  setData(surveyData) {
    this.setState({ surveyData });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onClickSend = () => {
    this.props.dispatch(actResetSurveyDetailFlg());
    const {
      bookingId, contentId, rating, comment,
    } = this.state;
    let errorMessage = '';
    let isLoading = true;
    if (!rating) {
      errorMessage = '選択してください';
      isLoading = false;
    }

    this.setState({ isLoading, errorMessage });

    if (!isLoading) {
      return;
    }

    this.props.dispatch(
      actCallApiCreateSurvey({
        bookingId,
        contentId,
        rating,
        comment,
      }),
    );
  };

  pointGrantClose = () => {
    this.setState({ isPointGrantOpen: false });
  }

  render() {
    const {
      isLoading, isComplete, surveyData, completeData,
      rating, comment, errorMessage, isPointGrantOpen,
    } = this.state;

    const isError = surveyData.errorMessages.length > 0;
    const classInfo = surveyData.data || {};

    return (
      <>
        <Loading open={isLoading} />
        <Header isNotShowBtn />
        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />

          <SurveyDetailPointGrantModal
            open={isPointGrantOpen}
            point={completeData.point || 0}
            onClose={this.pointGrantClose}
          />

          {isError && (
            <Box border={1} borderColor="grey.500" textAlign="center" fontSize="large" mt={5}>
              {surveyData.errorMessages}
            </Box>
          )}

          {!isError && (
            <>
              {
                !isComplete && (
                  <SurveyDetailInput
                    surveyData={surveyData}
                    rating={rating}
                    comment={comment}
                    onChange={this.onChange}
                    onClickSend={this.onClickSend}
                    errorMessage={errorMessage}
                  />
                )
              }

              {isComplete && (
                <SurveyDetailCorrect classInfo={classInfo} completeData={completeData} />
              )}
            </>
          )}
        </Container>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, surveyDetail: state.surveyDetail });

export default withRouter(connect(mapStateToProps)(SurveyDetail));
