import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export default function getBanners() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.TOP.BANNER_LIST)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
