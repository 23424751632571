import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getTransfers,
  updateTransfer,
} from '../../../services/mypage/transferRegist.service';
import {
  ACTION_TYPE,
  actGetTransferSuccess,
  actUpadeteTransferSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

/**
 * Function using for call api login
 *
 * @param userInfo
 */
function* callApiGetTransfers(param) {
  try {
    const datas = yield call(getTransfers, param);
    yield put(actGetTransferSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdateTransfer(param) {
  try {
    const datas = yield call(updateTransfer, param);
    yield put(actUpadeteTransferSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallTransfer() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_TRANSFERS, callApiGetTransfers);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_TRANSFER, callApiUpdateTransfer);
}
