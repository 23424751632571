import React from 'react';
import {
  Card,
  CardContent,
  Box,
  Button,
  withStyles,
  styled,
} from '@material-ui/core';
import { Alert, ToggleButton as MuiToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ArrowForwardIos as ArrowForwardIosIcon, ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import SearchSelectBox from '../../../../../components/atoms/searchSelectBox/SearchSelectBox';
import MemberRuleButton from './MemberRuleButton';

const styles = (theme) => ({
  selectSize: {
    width: '200px;',
  },
  selectedToggle: {
    backgroundColor: theme.palette.primary.main,
  },
});

const ToggleButton = styled(MuiToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#B1CA02',
    color: 'white',
  },
  '&.Mui-disabled, &.Mui-disabled': {
    backgroundColor: '#7f7f7f',
    color: 'white',
  },
  color: 'black',
  width: '40px',
  marginTop: '4px',
  marginRight: '8px',
  marginBottom: '4px',
  border: '1px solid rgba(0, 0, 0, 0.12) !important',
  borderRadius: '4px !important',
});

class BookingDisplayDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: null,
      selects: [],
      selectedGolfBox: null,
      isShowError: false,
      errorMessage: null,
      isComplete: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShow !== this.props.isShow) {
      if (this.props.isShow) {
        this.setResetData();
      }
    }
  }

  setResetData = () => {
    this.setState({ isShowError: false });
  }

  onGolfBoxChange = (_, selectValue) => {
    this.setState({ selectedGolfBox: selectValue });
  }

  onChange = (event) => {
    const { timeList } = this.props.roomData;
    const { value } = event.target;
    const tempSelectList = [];
    timeList.forEach((item) => {
      if (item.id <= value.id) {
        tempSelectList.push(item.id);
      }
    });
    this.setState({ selectedValue: value, selects: tempSelectList });
  }

  onNextClick = (event) => {
    const { onUserNextClick, data, golfType } = this.props;
    const { selects, selectedGolfBox } = this.state;
    let isShowError = false;
    let errorMessage = null;
    if (data.contentId === 2 && golfType !== 2 && selectedGolfBox === null) {
      isShowError = true;
      errorMessage = '打席番号を選択してください';
    }
    if (isShowError) {
      this.setState({ isShowError, errorMessage });
      window.scrollTo(0, 0);
      return;
    }
    onUserNextClick(event, selects, selectedGolfBox);
  }

  onComplete = () => {
    this.setState({ isComplete: true });
  };

  nextDisabled() {
    const { data, isAgereShow } = this.props;
    const { selectedValue, isComplete } = this.state;

    if (isAgereShow && !isComplete) {
      return true;
    }
    if (data.contentId !== 5) {
      return false;
    }
    return selectedValue === null;
  }

  render() {
    const {
      data, roomData, onBookingListBackClick, classes, boxInfo, golfType, isAgereShow,
    } = this.props;
    const {
      selectedValue, isShowError, errorMessage, selectedGolfBox,
    } = this.state;

    return (
      <Box display={data.id ? '' : 'none'}>
        <Box mb={3} style={{ display: isShowError ? '' : 'none' }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
        <Box fontWeight="fontWeightBold" m={1} fontSize={15}>選択されたメニューを確認してください。</Box>
        <Box fontWeight="fontWeightBold" m={1} fontSize={15}>内容がよろしければ、「この内容で次へ」ボタンをクリックしてください。</Box>

        <Card variant="outlined">
          <CardContent>
            <Box>
              <Box fontWeight="fontWeightBold" fontSize={18}>ご予約日時</Box>
              <Box>{`${data.targetDate} (${data.week}) ${data.startTime} ～ ${data.contentId !== 5 ? data.endTime : ''}`}</Box>
            </Box>
            <Box mt={2}>
              <Box fontWeight="fontWeightBold" fontSize={18}>メニュー</Box>
              <Box>{data.menuName}</Box>
            </Box>
            <Box mt={2} display={data.content ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" fontSize={18}>内容</Box>
              <Box>
                {data.content && data.content.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Box>
            </Box>
            <Box mt={2} display={data.roomName ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" fontSize={18}>Room</Box>
              <Box>{data.roomName}</Box>
            </Box>
            <Box mt={2} display={data.teacherName ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" fontSize={18}>講師</Box>
              <Box>{data.teacherName}</Box>
            </Box>
            <Box mt={2} display={data.annotation ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" fontSize={18}>特記事項</Box>
              <Box>{data.annotation}</Box>
            </Box>
            <Box mt={2} display={data.materialFee ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" fontSize={18}>教材費</Box>
              <Box>{`${data.materialFee}円`}</Box>
            </Box>
            <Box mt={2} display={data.managementFee ? '' : 'none'}>
              <Box fontWeight="fontWeightBold" fontSize={18}>管理費</Box>
              <Box>{`${data.managementFee}円`}</Box>
            </Box>
            <Box display={data.contentId === 5 ? '' : 'none'}>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={18}>最大収容人数</Box>
                <Box>{roomData.room.maximumNumber}</Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={18}>料金説明</Box>
                <Box>
                  {roomData.room.priceExplanation && roomData.room.priceExplanation.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
                </Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={18}>設備</Box>
                <Box>{roomData.room.facility}</Box>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box display={data.contentId === 2 && golfType !== 2 ? '' : 'none'} mt={3}>
          <Box fontWeight="fontWeightBold" m={1} fontSize={15}>ご予約される打席をご選択ください</Box>
          <Card variant="outlined">
            <CardContent>
              <Box fontWeight="fontWeightBold" fontSize={18}>打席番号</Box>
              <Box>
                <ToggleButtonGroup
                  value={selectedGolfBox}
                  exclusive
                  onChange={this.onGolfBoxChange}
                  style={{ flexWrap: 'wrap' }}
                >
                  {
                    boxInfo && boxInfo.map((c) => (
                      <ToggleButton
                        value={c.id}
                        aria-label={c.name}
                        disabled={c.disabled}
                        color="primary"
                      >
                        {c.name}
                      </ToggleButton>
                    ))
                  }
                </ToggleButtonGroup>
              </Box>
              {
                data.golfBatterUrl && (
                  <Box>
                    <img style={{ maxWidth: '100%' }} alt="" src={data.golfBatterUrl} />
                  </Box>
                )
              }
            </CardContent>
          </Card>
        </Box>

        {isAgereShow && (
          <Box mt={3}>
            <Box fontWeight="fontWeightBold" m={1} fontSize={15}>ご予約される前に必ずご確認ください</Box>
            <Card variant="outlined">
              <CardContent>
                <MemberRuleButton value={data.memberRule} onComplete={this.onComplete} />
              </CardContent>
            </Card>
          </Box>
        )}

        <Box display={data.contentId === 5 ? '' : 'none'} mt={3}>
          <Box fontWeight="fontWeightBold" m={1} fontSize={15}>ご予約される所要時間をご選択ください</Box>
          <Card variant="outlined">
            <CardContent>
              <Box fontWeight="fontWeightBold" fontSize={18}>所要時間</Box>
              <Box>
                <SearchSelectBox
                  options={roomData.timeList}
                  onChange={this.onChange}
                  value={selectedValue}
                  disableClearable
                  id="select-detail"
                  classes={classes.selectSize}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box my={3} textAlign="center">
          <Button
            variant="contained"
            className="custom-button"
            onClick={onBookingListBackClick}
            startIcon={<ArrowBackIosIcon />}
          >
            前の画面に戻る
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '1rem' }}
            onClick={this.onNextClick}
            endIcon={<ArrowForwardIosIcon />}
            disabled={this.nextDisabled()}
          >
            この内容で次へ
          </Button>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(BookingDisplayDetail);
