import { ACTION_TYPE } from './action';

// #region Reducer
const noticeList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_NOTICES_SUCCESS:
      return {
        ...state,
        noticeList: action.noticeList,
      };
    default:
      return state;
  }
};

export default noticeList;
