import React from 'react';
import {
  makeStyles,
  useScrollTrigger,
  Zoom,
  Fab,
  Link,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    padding: '20px',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      padding: '0',
      paddingTop: '8px',
    },
  },
  anchorRoot: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  ul: {
    [theme.breakpoints.down('xs')]: {
      paddingInlineStart: 0,
    },
  },
  link: {
    display: 'block',
    padding: '0 10px',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
    },
  },
  li: {
    display: 'inline-block',
    height: '1em',
    borderLeft: '1px solid white',
    lineHeight: '1.0',
  },
  liFirst: {
    display: 'inline-block',
    height: '1em',
    lineHeight: '1.0',
  },
  copyright: {
    paddingLeft: '4px',
    color: '#fff',
    fontSize: '15px',
    lineHeight: '26px',
    margin: '0',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '8px',
      fontSize: '10px',
    },
  },
}));

function ScrollTop() {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.anchorRoot}>
        <Fab color="primary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
}

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <ul className={classes.ul}>
        <li className={classes.liFirst}><Link href="https://www.vertex-group.co.jp/privacy/" className={classes.link}>プライバシーポリシー</Link></li>
      </ul>
      <h1 className={classes.copyright}>Copyright© ELC All Rights Reserved.</h1>
      <ScrollTop />
    </footer>
  );
}
