// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_POINT_SUMMARY: 'CALL_API_GET_POINT_SUMMARY',
  CALL_API_GET_POINT_SUMMARY_SUCCESS: 'CALL_API_GET_POINT_SUMMARY_SUCCESS',

  CALL_API_GET_POINT_HISTORY: 'CALL_API_GET_POINT_HISTORY',
  CALL_API_GET_POINT_HISTORY_SUCCESS: 'CALL_API_GET_POINT_HISTORY_SUCCESS',
};
// #endregion

export const actCallApiGetPointSummary = () => ({
  type: ACTION_TYPE.CALL_API_GET_POINT_SUMMARY,
});
export const actCallApiGetPointSummarySuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_POINT_SUMMARY_SUCCESS,
  datas,
});

export const actCallApiGetPointHistory = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_POINT_HISTORY,
  param,
});
export const actCallApiGetPointHistorySuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_POINT_HISTORY_SUCCESS,
  datas,
});
