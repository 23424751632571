export default function getStyle() {
  const styles = (theme) => ({
    root: {
      marginBottom: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0',
      },
    },
    divTop: {
      width: '100%',
      background: '#B1CA02',
      marginBottom: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '7px',
      },
    },
    caption: {
      color: '#fff',
      fontSize: '15px',
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
      },
    },
    logo: {
      width: '60%',
      height: 'auto',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '90%',
      },
    },
    btnArea: {
      position: 'absolute',
      right: '15px',
      top: '-6px',
      display: 'table',
      overflow: 'hidden',
      zIndex: 10,
      [theme.breakpoints.down('md')]: {
        right: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        position: 'static',
        width: '100%',
      },
    },
    entryLink: {
      backgroundColor: '#F0463F',
    },
    loginLink: {
      backgroundColor: '#FF9900',
    },
    logoutLink: {
      backgroundColor: '#808080',
    },
    mypageLink: {
      backgroundColor: '#23456F',
    },
    linkRoot: {
      color: '#fff',
      width: '200px',
      lineHeight: '90px',
      textAlign: 'center',
      display: 'table-cell',
      border: 'none',
      outline: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('md')]: {
        width: '160px',
      },
      [theme.breakpoints.down('xs')]: {
        lineHeight: '50px',
        width: '50%',
      },
    },
    logoutLinkRoot: {
      color: '#fff',
      width: '130px',
      lineHeight: '90px',
      textAlign: 'center',
      display: 'table-cell',
      border: 'none',
      outline: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('md')]: {
        display: 'block',
        lineHeight: '40px',
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        lineHeight: '50px',
        width: '100%',
      },
    },
    linkBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    linkSpan: {
      verticalAlign: 'super',
      fontSize: '16px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    menuLogo: {
      width: '30px',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '25px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '20px',
      },
    },
  });
  return styles;
}
