import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Box,
  withStyles,
  Button,
} from '@material-ui/core';
import Datepicker from '../../../../components/atoms/datepicker/Datepicker';
import SearchSelectBox from '../../../../components/atoms/searchSelectBox/SearchSelectBox';
// import { getBookingParam, getNewContent } from '../booking-list-proc';
import { getBookingParam } from '../booking-list-proc';
import {
  actSetBookingCondition, actCallApiCultureBooking,
  actCallApiRentalCoatTableTennisBooking, actCallApiRentalCoatBadmintonBooking,
  actCallApiRoomBooking, actCallApiGolfBooking, actCallApiOnlineCultureBooking,
} from '../../../../redux/booking/bookingList/action';
import ErrorDialog from '../../../../components/templates/errorDialog/ErrorDialog';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class SearchForm extends Component {
  constructor(props) {
    super(props);

    let storeValue = null;
    if (props.bookingList.condition && props.bookingList.condition.storeValue) {
      storeValue = props.bookingList.condition.storeValue;
    }
    let targetDate = null;
    if (props.bookingList.condition && props.bookingList.condition.targetDate) {
      targetDate = props.bookingList.condition.targetDate;
    }
    let contentValue = null;
    if (props.bookingList.condition && props.bookingList.condition.contentValue) {
      contentValue = props.bookingList.condition.contentValue;
    }
    let contents = null;
    if (storeValue != null && props.bookingList.selects) {
      contents = this.getContentsOptions(storeValue.id);
    }
    this.state = {
      searchCondition: {
        targetDate,
        storeValue,
        contentValue,
      },
      contents,
      errorOpen: false,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingList.condition !== this.props.bookingList.condition) {
      if (this.props.bookingList.condition) {
        this.setCondition(this.props.bookingList.condition);
      }
    }
  }

  getContentsOptions = (storeId) => {
    const { bookingList } = this.props;
    const contents = [];

    if (bookingList.selects) {
      const { contentLinks, contents: allContents } = bookingList.selects;

      return contentLinks.reduce((acc, link) => {
        if (link.mstStoreId === storeId) {
          const targetContent = allContents.find((row) => row.id === link.mstContentId);
          if (targetContent) {
            acc.push({
              id: targetContent.id,
              name: targetContent.name,
            });
          }
        }
        return acc;
      }, []);
    }
    return contents;
  }

  setCondition = (searchCondition) => {
    this.setState({ searchCondition });
  }

  setStores = (selectSearchStores) => {
    const { selects } = this.state;
    this.setState({
      selects: {
        ...selects,
        selectSearchStores,
      },
    });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition, contents } = this.state;

    let newContents = contents;
    let newSearchCondition = searchCondition;
    if (name === 'storeValue') {
      newContents = this.getContentsOptions(value.id);
      newSearchCondition = {
        ...searchCondition,
        contentValue: null,
      };
    }

    this.setState({
      searchCondition: {
        ...newSearchCondition,
        [name]: value,
      },
      contents: newContents,
    });
  }

  onSearchClick = () => {
    const { searchCondition } = this.state;
    const { onModalClose } = this.props;

    if (!searchCondition.contentValue || !searchCondition.contentValue.id) {
      this.setState({ errorOpen: true, errorMessage: ['コンテンツを選択して下さい'] });
    } else {
      this.props.dispatch(actSetBookingCondition(searchCondition));
      const param = getBookingParam(searchCondition);
      switch (searchCondition.contentValue.id) {
        case 1:
          this.props.dispatch(actCallApiCultureBooking(param));
          break;
        case 17:
          this.props.dispatch(actCallApiOnlineCultureBooking(param));
          break;
        case 2:
          this.props.dispatch(actCallApiGolfBooking(param));
          break;
        case 5:
          this.props.dispatch(actCallApiRoomBooking(param));
          break;
        case 3:
          this.props.dispatch(actCallApiRentalCoatTableTennisBooking(param));
          break;
        case 4:
          this.props.dispatch(actCallApiRentalCoatBadmintonBooking(param));
          break;
        default:
          break;
      }
      if (onModalClose) {
        onModalClose();
      }
    }
  }

  handleErrorDialogClose = () => {
    this.setState({ errorOpen: false });
  }

  render() {
    const {
      classes,
    } = this.props;

    const stores = this.props.bookingList.selects ? this.props.bookingList.selects.stores : [];

    const {
      searchCondition,
      contents,
      errorOpen,
      errorMessage,
    } = this.state;

    return (
      <div>
        <Box className={classes.textCenter}>
          <Datepicker
            value={searchCondition.targetDate}
            name="targetDate"
            onChange={this.onChange}
            disableToolbar
            variant="static"
          />
        </Box>
        <Box mt={1} px={2}>
          <SearchSelectBox
            name="storeValue"
            options={stores}
            onChange={this.onChange}
            label="店舗名"
            value={searchCondition.storeValue}
            disableClearable
          />
        </Box>
        <Box mt={1} px={2}>
          <SearchSelectBox
            name="contentValue"
            options={contents}
            onChange={this.onChange}
            label="コンテンツ名"
            value={searchCondition.contentValue}
            disableClearable
          />
        </Box>
        <Box my={2} textAlign="center">
          <Button color="primary" variant="contained" onClick={this.onSearchClick}>検索する</Button>
        </Box>
        <ErrorDialog
          open={errorOpen}
          handleClose={this.handleErrorDialogClose}
          messages={errorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ bookingList: state.bookingList });

export default withStyles(styles)(connect(mapStateToProps)(SearchForm));
