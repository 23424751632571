export function createContractForm() {
  return {
    id: null,
    zipCode: '',
    selectPrefecture: {
      id: null,
      name: '',
    },
    city: '',
    street: '',
    email: '',
    phone: '',
    phoneTwo: '',
    emergencyLastName: '',
    emergencyFirstName: '',
    emergencyPhone: '',
  };
}

export function createMemberForm() {
  return {
    id: null,
    zipCode: '',
    selectPrefecture: {
      id: null,
      name: '',
    },
    city: '',
    street: '',
    email: '',
    phone: '',
    phoneTwo: '',
  };
}

export function errorMessageForm() {
  return {
    zipCode: '',
    selectPrefecture: '',
    city: '',
    street: '',
    email: '',
    phone: '',
    phoneTwo: '',
    emergencyLastName: '',
    emergencyFirstName: '',
    emergencyPhone: '',
  };
}

export function errorMessageFormMember() {
  return {
    zipCode: '',
    phone: '',
    phoneTwo: '',
    email: '',
  };
}
