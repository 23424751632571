import { ACTION_TYPE } from './action';

const common = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_ME_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isGetMeError: true,
      };
    case ACTION_TYPE.GET_ME_SUCCESS:
      return {
        ...state,
        user: action.data,
        noticeList: action.data.noticeList,
        isGetMeError: false,
      };
    case ACTION_TYPE.GET_STORES_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case ACTION_TYPE.CALL_API_SERVER_ERROR:
      return {
        ...state,
        isServerError: true,
      };
    case ACTION_TYPE.CALL_API_RESET_SERVER_ERROR:
      return {
        ...state,
        isServerError: false,
      };
    default:
      return state;
  }
};

export default common;
