import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: '#f50057',
    borderRadius: '0px',
    marginLeft: '5px',
  },
}));

export default function RequiredChip(props) {
  const classes = useStyles();
  const { isNotShow } = props;

  return (
    <Chip label="必須" size="small" className={classes.root} style={{ display: isNotShow ? 'none' : '' }} />
  );
}
