import React from 'react';
import { TableCell, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tdRoot: {
    border: 'solid 1px #DDD',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      padding: '5px',
    },
  },
}));

export default function TableFrameCell(props) {
  const {
    children, align, width, colSpan, className,
  } = props;
  const classes = useStyles();

  return (
    <TableCell
      align={align}
      width={width}
      colSpan={colSpan}
      className={className}
      classes={{
        root: classes.tdRoot,
      }}
    >
      {children}
    </TableCell>
  );
}
