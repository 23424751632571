import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumbs, withStyles, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '13px',
    },
  },
  breadcrumbsText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class BasicBreadcrumbs extends Component {
  handleClick = (url) => {
    this.props.history.push({
      pathname: url,
    });
  }

  render() {
    const { breadcrumbs, classes } = this.props;
    return (
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.root}>
        <div className={`${classes.link} ${classes.breadcrumbsText}`} onClick={() => this.handleClick('/')} aria-hidden="true" key="link-01">
          TOP
        </div>
        {
          breadcrumbs.map((path) => (path.url
            ? (
              <div className={`${classes.link} ${classes.breadcrumbsText}`} onClick={() => this.handleClick(path.url)} aria-hidden="true" key={`link-${path.url}`}>
                {path.name}
              </div>
            ) : (
              <Typography color="textPrimary" key={`label-${path.url}`} className={classes.breadcrumbsText}>{path.name}</Typography>
            )))
        }
      </Breadcrumbs>
    );
  }
}

export default withStyles(styles)(withRouter(BasicBreadcrumbs));

BasicBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired).isRequired,
};
