// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_TOP: 'CALL_API_INIT_TOP',
  GET_INIT_TOP_SUCCESS: 'GET_INIT_TOP_SUCCESS',
};
// #endregion

// #region action
export const actCallApiInitTop = () => ({
  type: ACTION_TYPE.CALL_API_INIT_TOP,
});

export const actGetInitTopSuccess = (banners) => ({
  type: ACTION_TYPE.GET_INIT_TOP_SUCCESS,
  banners,
});

// #endregion
