import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Container,
  Box,
  TextField,
  Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import redirectLinks from '../../../constants/redirectLinks';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import Loading from '../../../components/atoms/loading/Loading';
import {
  actCallApiForgotEmail, actResetPasswordResetFlg,
} from '../../../redux/other/resetPassword/action';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  boxRoot: {
    maxWidth: '530px',
    margin: '60px auto 40px',
    boxSizing: 'border-box',
    padding: '20px',
    border: '2px solid #d9d9d9',
    borderRadius: '10px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
});
// #endregion

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailErrorMessage: '',
      memberNo: '',
      memberNoErrorMessage: '',
      loading: false,
      isComplete: false,
    };
    this.breadcrumbs = [
      { name: 'パスワードを忘れた場合' },
    ];
    this.props.dispatch(actResetPasswordResetFlg());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetPassword !== this.props.resetPassword) {
      if (this.props.resetPassword.isForgotEmailSuccess) {
        this.setComplete();
      }
    }
  }

  onSendClick = () => {
    const { email, memberNo } = this.state;
    let loading = false;
    const emailErrorMessage = this.validation(email, true);
    const memberNoErrorMessage = this.validation(memberNo, false);
    if (!emailErrorMessage && !memberNoErrorMessage) {
      this.props.dispatch(actCallApiForgotEmail({ userType: '1', email, code: memberNo }));
      loading = true;
    }

    this.setState({
      emailErrorMessage,
      memberNoErrorMessage,
      loading,
    });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { emailErrorMessage, memberNoErrorMessage } = this.state;
    let emailMessage = emailErrorMessage;
    let memberMessage = memberNoErrorMessage;

    if (name === 'email') {
      emailMessage = this.validation(value, true);
    } else {
      memberMessage = this.validation(value, false);
    }
    this.setState({
      [name]: value,
      emailErrorMessage: emailMessage,
      memberNoErrorMessage: memberMessage,
    });
  }

  onTopClick = () => {
    window.location.href = redirectLinks.BASE;
  }

  setComplete() {
    this.setState({ isComplete: true, loading: false });
  }

  validation = (value, isEmail) => {
    if (!value) return '入力してください';
    if (isEmail) {
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!regex.test(value)) return '正しい形式でメールアドレスを入力してください';
    }
    return '';
  };

  render() {
    const { classes } = this.props;
    const {
      email, emailErrorMessage,
      memberNo, memberNoErrorMessage,
      loading, isComplete,
    } = this.state;

    return (
      <div className={classes.root}>
        <Header isNotShowBtn />
        <Loading open={loading} />

        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />
          <Box className={classes.boxRoot} display={isComplete ? 'none' : ''}>
            <Box>
              <Alert severity="info">
                <Box>
                  ご利用者様情報でご登録いただいたメールアドレスにパスワード再設定ページへのアドレスを含むメールを送信いたします。
                </Box>
                <Box mt={1}>
                  メールをご登録いただいていない場合、メール送信が行えないため、恐れ入りますが、店舗にパスワードをリセットしたい旨をお問合せください。
                </Box>
              </Alert>
            </Box>
            <Box mt={3}>
              <Box fontWeight="fontWeightBold" fontSize={{ xs: 14 }}>会員番号</Box>
              <TextField
                id="memberNo"
                variant="outlined"
                fullWidth
                name="memberNo"
                inputProps={{ maxLength: 10 }}
                onChange={this.onChange}
                value={memberNo}
              />
              <FormErrorText>{memberNoErrorMessage}</FormErrorText>
            </Box>
            <Box mt={3}>
              <Box fontWeight="fontWeightBold" fontSize={{ xs: 14 }}>メールアドレス</Box>
              <TextField
                id="email"
                name="email"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 80 }}
                onChange={this.onChange}
                value={email}
              />
              <FormErrorText>{emailErrorMessage}</FormErrorText>
            </Box>
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onSendClick}
              >
                送信
              </Button>
            </Box>
          </Box>
          <Box className={classes.boxRoot} display={isComplete ? '' : 'none'}>
            <Box>
              ご登録されたメールアドレスにパスワード再設定のご案内をお送りしました。
            </Box>
            <Box>
              メール本文にあるリンクをクリックしてパスワードを設定してください。
            </Box>
            <Box>
              パスワード再設定のリンクは、24時間で無効になります。
            </Box>
            <Box>
              メールを確認後、すぐにお手続きをしてください。
            </Box>
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onTopClick}
              >
                TOP画面に戻る
              </Button>
            </Box>
          </Box>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ resetPassword: state.resetPassword });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ForgotPassword)));
