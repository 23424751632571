import { ACTION_TYPE } from './action';

const resetPassword = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.FORGOT_EMAIL_SUCCESS:
      return {
        ...state,
        isForgotEmailSuccess: true,
      };
    case ACTION_TYPE.CHECK_EXPIRATION_SUCCESS:
      return {
        ...state,
        isExpirationFinish: true,
        isExpirationError: action.result,
      };
    case ACTION_TYPE.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordSuccess: true,
      };
    case ACTION_TYPE.RESET_PASSWORD_RESET_FLG:
      return {
        ...state,
        isExpirationFinish: false,
        isResetPasswordSuccess: false,
        isForgotEmailSuccess: false,
        isExpirationError: false,
      };
    default:
      return state;
  }
};

export default resetPassword;
