import React from 'react';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { getWeekly, getSpecificDateDatas } from './booking-calendar-proc';
import BookingCard from '../bookingCard/BookingCard';

const useStyles = makeStyles((theme) => ({
  tdDisabled: {
    background: '#e6e6e6',
    color: '#999999',
  },
  calendarTable: {
    borderCollapse: 'separate',
    textAlign: 'center',
    tableLayout: 'fixed',
    borderSpacing: '0px',
  },
  calendarTd: {
    border: 'solid 1px #DDD',
    width: '14.2%',
    verticalAlign: 'top',
  },
  moreLink: {
    color: '#2196f3',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  stickyHead: {
    border: 'solid 1px #DDD',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fff',
  },
  holidayStyle: {
    fontWeight: 'bold',
  },
  tdHoliday: {
    background: '#f5f5f5',
    color: '#000000',
  },
}));

export default function Weekly(props) {
  const classes = useStyles();

  const {
    isView,
    targetDate,
    datas,
    changeViewMoreLink,
    holidays,
  } = props;

  const weekly = getWeekly(targetDate, holidays);

  const dayElement = (item) => {
    const dayDatas = getSpecificDateDatas(item.date, datas);

    let element = dayDatas.slice(0, 10).map((data) => (
      <BookingCard data={data} key={`${data.id}weekly`} />
    ));
    let linkElement = null;

    if (dayDatas.length > 10) {
      const data = dayDatas[0];
      linkElement = (<div className={classes.moreLink} onClick={(event) => changeViewMoreLink(event, data.date)} role="button" aria-hidden="true">さらに表示する</div>);
    }

    if (item.isHoliday) {
      element = null;
      linkElement = (
        <div className={classes.holidayStyle}>
          休館日
        </div>
      );
    }

    return (
      <div>
        {' '}
        {element}
        {' '}
        {linkElement}
        {' '}
      </div>
    );
  };

  return (
    <Box display={isView ? '' : 'none'} mt={2} mx={1}>
      <table width="100%" className={classes.calendarTable}>
        <thead>
          <tr>
            {weekly.map((item) => (
              <th className={classes.stickyHead} key={`${item.day}week`}>{`${item.day} (${item.week})`}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {weekly.map((item) => (
              <td key={`${item.day}weekData`} className={`${!item.isSomeMonth && classes.tdDisabled} ${(item.isSomeMonth && item.isHoliday) && classes.tdHoliday} ${classes.calendarTd}`} height="200px">
                {dayElement(item)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Box>
  );
}
