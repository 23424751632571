import React, { Component } from 'react';
import {
  subDays, format, subMinutes,
} from 'date-fns';
import ja from 'date-fns/locale/ja';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Grid,
  withStyles,
  Box,
  Button,
} from '@material-ui/core';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import redirectLinks from '../../../constants/redirectLinks';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import DangerButton from '../../../components/atoms/dangerButton/DangerButton';
import SideDisplayGrid from '../../../components/organisms/sideDisplayGrid/SideDisplayGrid';
import { checkDoCancel } from '../mypage/tabContent/bookingList/booking-list-proc';
import { actCallApiUpdateBookingCancel, actCallApiUpdateBookingConfirm } from '../../../redux/mypage/mypage/action';
import Loading from '../../../components/atoms/loading/Loading';

const styles = () => ({
  button: {
    marginLeft: '10px',
  },
});

class MyPageBookingDetail extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop();

    if (!props.location.state) {
      props.history.push({
        pathname: redirectLinks.MYPAGE,
      });
    }

    const { data, procType } = props.location.state;

    let title = '予約内容詳細';
    if (procType === 1) {
      title = '予約キャンセルの確認';
    }
    if (procType === 2) {
      title = '予約の確定';
    }

    this.breadcrumbs = [
      { url: redirectLinks.MYPAGE, name: 'マイページ' },
      { name: title },
    ];

    this.state = {
      loading: false,
      isCancelComplete: false,
      data: data || this.getDataObject(),
      procType,
      isConfirmComplete: false,
      errors: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mypage.bookingCancelResult !== this.props.mypage.bookingCancelResult) {
      if (this.props.mypage.bookingCancelResult) {
        this.cancelComplete(this.props.mypage.bookingCancelResult);
      }
    }
    if (prevProps.mypage.bookingConfirmResult !== this.props.mypage.bookingConfirmResult) {
      if (this.props.mypage.bookingConfirmResult) {
        this.confirmComplete(this.props.mypage.bookingConfirmResult);
      }
    }
  }

  getDataObject() {
    return {
      bookingCode: null,
      targetDate: null,
      targetedOn: null,
      weekName: null,
      startTime: null,
      endTime: null,
      contentId: null,
      contentName: null,
      menuName: null,
      bookingStatusName: null,
      memberStatus: null,
      memberStatusName: null,
      content: null,
      annotation: null,
      roomName: null,
      teacherName: null,
      materialFee: null,
      cancelDatetime: null,
    };
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onBackClick = () => {
    this.props.history.push(redirectLinks.MYPAGE);
  }

  onCancelClick = () => {
    const { data } = this.props.location.state;
    const bookingData = this.state.data;
    this.setState({ loading: true });
    this.props.dispatch(
      actCallApiUpdateBookingCancel({
        bookingCode: data.bookingCode,
        contentId: data.contentId,
        storeId: bookingData.storeId,
        reserveClosed: data.overBookingLimit,
      }),
    );
  }

  onConfirmClick = () => {
    const { data } = this.props.location.state;
    const bookingData = this.state.data;
    this.setState({ loading: true });
    this.props.dispatch(
      actCallApiUpdateBookingConfirm({
        bookingCode: data.bookingCode,
        contentId: data.contentId,
        storeId: bookingData.storeId,
      }),
    );
  }

  cancelComplete(param) {
    const { data } = this.state;
    this.setState({
      isCancelComplete: true,
      loading: false,
      data: param.data || data,
      errors: param.errorMessages,
    });
  }

  confirmComplete(param) {
    const { data } = this.state;
    this.setState({
      isConfirmComplete: true,
      loading: false,
      data: param.data || data,
      errors: param.errorMessages,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      loading, isCancelComplete, data, procType, errors, isConfirmComplete,
    } = this.state;

    return (
      <div>
        <Header isNotShowBtn />
        <Loading open={loading} />

        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />

          <Box mt={3} display={procType === 2 && data.bookingEnabledReception && !isConfirmComplete ? '' : 'none'}>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">以下の予約を確定します。</Box>
          </Box>

          <Box mt={3} display={procType === 1 && !isCancelComplete && checkDoCancel(data) ? '' : 'none'}>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">以下の予約をキャンセルします。</Box>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">キャンセルを取り消すことはできませんので、ご注意ください。</Box>
          </Box>

          <Box mt={3} display={procType === 1 && !isCancelComplete && !checkDoCancel(data) ? '' : 'none'}>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">キャンセル可能期限を過ぎている為、キャンセルできません。</Box>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">恐れ入りますが、店舗に直接ご連絡ください。</Box>
          </Box>

          <Box mt={3} display={isCancelComplete && errors.length === 0 ? '' : 'none'}>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">以下の予約キャンセルが完了しました。</Box>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">万が一誤ってキャンセルを行った場合は、恐れ入りますが店舗に直接ご連絡ください。</Box>
          </Box>

          <Box mt={3} display={isConfirmComplete && errors.length === 0 ? '' : 'none'}>
            <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">以下の予約を確定しました。</Box>
          </Box>

          <Box mt={3} display={(isCancelComplete || isConfirmComplete) && errors.length > 0 ? '' : 'none'}>
            {errors && errors.map((message) => (
              <Box key={message} textAlign="center" color="#f50057" fontWeight="fontWeightBold">
                {message}
              </Box>
            ))}
            {isCancelComplete && (
              <Box textAlign="center" color="#f50057" fontWeight="fontWeightBold">恐れ入りますが、店舗に直接ご連絡ください。</Box>
            )}
          </Box>

          <Box mt={3}>
            <Grid container spacing={0}>

              <SideDisplayGrid title="予約番号" content={data.bookingCode} />
              <SideDisplayGrid title="予約日時" content={`${data.targetDate} (${data.weekName}) ${data.startTime} ～ ${data.endTime}`} />
              <SideDisplayGrid title="コンテンツ" content={data.contentName} />
              <SideDisplayGrid title="メニュー" content={data.menuName} />
              <SideDisplayGrid title="ご予約状況" content={data.memberStatusName} />
              <SideDisplayGrid title="内容" content={data.content} isNotShow={!data.content} />
              <SideDisplayGrid title="Room" content={data.roomName} isNotShow={!data.roomName} />
              <SideDisplayGrid title="講師" content={data.teacherName} isNotShow={!data.teacherName} />
              <SideDisplayGrid title="特記事項" content={data.annotation} isNotShow={!data.annotation} />
              <SideDisplayGrid title="教材費" content={`${data.materialFee}円`} isNotShow={!data.materialFee} />
              <SideDisplayGrid title="管理費" content={`${data.managementFee}円`} isNotShow={!data.managementFee} />
              <SideDisplayGrid title="打席番号" content={`${data.boxNumber} `} isNotShow={!data.boxNumber} />
              <SideDisplayGrid title="キャンセル期限" content={`${format(subMinutes(new Date(`${data.targetedOn} ${data.startTime}`), data.cancelDatetime), 'yyyy年M月dd日 (EEEEEE) HH:mm', { locale: ja })}まで`} isNotShow={!(checkDoCancel(data) && data.contentId === '2')} />
              <SideDisplayGrid title="キャンセル期限" content={`${format(subDays(new Date(data.targetedOn), data.cancelDatetime), 'yyyy年M月dd日 (EEEEEE)', { locale: ja })} 23:59 まで`} isNotShow={!(checkDoCancel(data) && data.contentId !== '2')} />
            </Grid>
          </Box>
          <Box my={3} justifyContent="center" alignItems="center" display="flex">
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.onBackClick}
            >
              前の画面に戻る
            </Button>
            {checkDoCancel(data) && procType === 1 && !isCancelComplete && (
              <DangerButton variant="contained" label="キャンセルする" className={classes.button} onClick={this.onCancelClick} />
            )}
            {data.bookingEnabledReception && procType === 2 && !isConfirmComplete && (
              <DangerButton variant="contained" label="予約を確定する" className={classes.button} onClick={this.onConfirmClick} />
            )}
          </Box>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ mypage: state.mypage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MyPageBookingDetail)));
