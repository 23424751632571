import React from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

class PasswordField extends React.Component {
  constructor() {
    super();

    this.state = {
      isShowPassword: false,
    };
  }

  handleShowPassword = () => {
    this.setState((prevState) => ({
      isShowPassword: !prevState.isShowPassword,
    }));
  }

  render() {
    const {
      isShowPassword,
    } = this.state;

    const {
      value,
      onChange,
      isError,
      name,
      required,
    } = this.props;

    return (
      <OutlinedInput
        type={isShowPassword ? 'text' : 'password'}
        fullWidth
        autoComplete="off"
        name={name}
        value={value}
        onChange={onChange}
        error={isError}
        required={required}
        inputProps={{
          maxLength: 16,
          autoComplete: 'new-password',
        }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              onClick={this.handleShowPassword}
            >
              {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )}
      />
    );
  }
}

export default PasswordField;

PasswordField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

PasswordField.defaultProps = {
  value: null,
  isError: false,
};
