import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getBookingDetail, createBookingDetail,
} from '../../../services/booking/bookingDetail.service';
import {
  ACTION_TYPE,
  actGetBookingDetailSuccess,
  actCreateBookingSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetBookingDetail(param) {
  try {
    const result = yield call(getBookingDetail, param.param);
    yield put(actGetBookingDetailSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiCreateBooking(param) {
  try {
    const result = yield call(createBookingDetail, param.param);
    yield put(actCreateBookingSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallBookingDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_BOOKING_DETAIL, callApiGetBookingDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_BOOKING, callApiCreateBooking);
}
