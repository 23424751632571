import React from 'react';
import {
  Button, Box, makeStyles,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    color: '#f50057',
    fontWeight: '600',
  },
  buttonColor: {
    color: '#f50057',
  },
  content: {
    color: '#000',
  },
}));

export default function InfoDialog(props) {
  const classes = useStyles();
  const {
    open,
    firstOnFunc,
    firstFuncText,
    secondOnFunc,
    secondFuncText,
    messages,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={firstOnFunc}
      disableBackdropClick
      maxWidth="sm"
    >
      <DialogContent dividers>
        <Box id="alert-dialog-slide-description" className={classes.content}>
          {messages && messages.map((message) => (
            <Box mb={2} key={message}>
              {message}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        {firstFuncText && (
          <Button onClick={firstOnFunc}>{firstFuncText}</Button>
        )}
        {secondFuncText && (
          <Button onClick={secondOnFunc} className={classes.buttonColor}>{secondFuncText}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
