// #region Type
export const ACTION_TYPE = {
  CALL_API_STORES: 'CALL_API_STORES',
  GET_STORES_SUCCESS: 'GET_STORES_SUCCESS',
  CALL_API_GET_ME: 'CALL_API_GET_ME',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  CALL_API_NOTICES_AGREE_ENTRY: 'CALL_API_NOTICES_AGREE_ENTRY',
  CALL_API_NOTICES_AGREE_ENTRY_SUCCESS: 'CALL_API_NOTICES_AGREE_ENTRY_SUCCESS',
  GET_ME_ERROR: 'GET_ME_ERROR',
  CALL_API_SERVER_ERROR: 'CALL_API_SERVER_ERROR',
  CALL_API_RESET_SERVER_ERROR: 'CALL_API_RESET_SERVER_ERROR',
  NOT_PROC_SERVER_ERROR: 'NOT_PROC_SERVER_ERROR',
};
// #endregion

// #region action

export const actCallApiStores = () => ({
  type: ACTION_TYPE.CALL_API_STORES,
});

export const actGetStoresSuccess = (stores) => ({
  type: ACTION_TYPE.GET_STORES_SUCCESS,
  stores,
});

export const actCallApiMe = () => ({
  type: ACTION_TYPE.CALL_API_GET_ME,
});

export const actGetMeSuccess = (data) => ({
  type: ACTION_TYPE.GET_ME_SUCCESS,
  data,
});

export const actCallApiNoticesAgreeEntry = (noticesIds) => ({
  type: ACTION_TYPE.CALL_API_NOTICES_AGREE_ENTRY,
  noticesIds,
});

export const actCallApiNoticesAgreeEntrySuccess = () => ({
  type: ACTION_TYPE.CALL_API_NOTICES_AGREE_ENTRY_SUCCESS,
});

export const actGetMeError = (errorMessage) => ({
  type: ACTION_TYPE.GET_ME_ERROR,
  errorMessage,
});

export const actCallApiServerError = () => ({
  type: ACTION_TYPE.CALL_API_SERVER_ERROR,
});
export const actCallApiResetServerError = () => ({
  type: ACTION_TYPE.CALL_API_RESET_SERVER_ERROR,
});

export const actNotProcServerError = () => ({
  type: ACTION_TYPE.NOT_PROC_SERVER_ERROR,
});

// #endregion
