import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSurveyDetail,
  createSurvey,
} from '../../../services/mypage/surveyDetail/surveyDetail.service';
import { ACTION_TYPE, actGetSurveyDetailSuccess, actCreateSurveySuccess } from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetSurveyDetail({ param }) {
  try {
    const result = yield call(getSurveyDetail, param);
    yield put(actGetSurveyDetailSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiCreateSurvey({ param }) {
  try {
    const result = yield call(createSurvey, param);
    yield put(actCreateSurveySuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSurveyDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SURVEY_DETAIL, callApiGetSurveyDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_SURVEY, callApiCreateSurvey);
}
