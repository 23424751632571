import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'yyyy年MMM', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'MMM', { locale: this.locale });
  }
}

export default function Monthpicker(props) {
  const {
    value,
    handleChange,
    disableToolbar,
    variant,
    label,
    minDate,
  } = props;

  const onChange = (event, changeValue) => {
    const newValue = changeValue ? changeValue.replace('年', '/').replace('月', '') : '';
    handleChange(event, newValue);
  };

  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDatePicker
        size="small"
        autoComplete="off"
        openTo="month"
        views={['year', 'month']}
        inputVariant="outlined"
        variant={variant}
        margin="dense"
        format="yyyy年MM月"
        id="date-picker-inline"
        value={value}
        minDate={minDate}
        onChange={onChange}
        label={label}
        autoOk
        disableToolbar={disableToolbar}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        helperText=""
      />
    </MuiPickersUtilsProvider>
  );
}

Monthpicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disableToolbar: PropTypes.bool,
  variant: PropTypes.string.isRequired,
};

Monthpicker.defaultProps = {
  value: null,
  disableToolbar: false,
};
