import {
  subDays, addDays, format, isBefore, isEqual,
} from 'date-fns';
import ja from 'date-fns/locale/ja';

function createDateObject(targetDate, index) {
  const fnsDate = addDays(new Date(targetDate), index);

  return {
    date: format(fnsDate, 'yyyy/MM/dd'),
    month: format(fnsDate, 'M月'),
    dateWeek: format(fnsDate, 'yyyy年M月dd日 (EEEEEE)', { locale: ja }),
    day: format(fnsDate, 'd'),
    week: format(fnsDate, 'EEEEEE', { locale: ja }),
  };
}

export function getDays(paramDate) {
  const days = [];
  for (let i = 0; i < 7; i += 1) {
    days.push(createDateObject(paramDate, i));
  }
  return days;
}

export function getTargetNumber(datas, time, targetDate, targetType) {
  const dataList = datas.filter(
    (row) => row.startTime === time && row.targetDate === targetDate,
  );
  if (dataList.length === 0) {
    return null;
  }
  const data = dataList[0];
  let returnNumber = null;
  let isCancelWaitAccepted = false;

  switch (targetType) {
    case 1: {
      returnNumber = data.freeFrame;
      isCancelWaitAccepted = data.freeCancelWaitAccepted;
      break;
    }
    case 2:
      returnNumber = data.lessonFrame;
      isCancelWaitAccepted = data.lessonCancelWaitAccepted;
      break;
    case 3:
      returnNumber = data.targetFrame;
      isCancelWaitAccepted = data.targetCancelWaitAccepted;
      break;
    default:
      break;
  }
  return {
    number: returnNumber,
    isCancelWaitAccepted,
    id: data.id,
  };
}

export function getBackWeekParam(parma) {
  const targetDate = format(subDays(new Date(parma.targetDate), 7), 'yyyy/MM/dd');

  return {
    ...parma,
    targetDate,
  };
}

export function getNextWeekParam(parma) {
  const targetDate = format(addDays(new Date(parma.targetDate), 7), 'yyyy/MM/dd');

  return {
    ...parma,
    targetDate,
  };
}

export function isWeekBackDisabled(targetDate) {
  const fnsDate = new Date(targetDate);
  const today = new Date(format(new Date(), 'yyyy/MM/dd'));
  return isBefore(fnsDate, today) || isEqual(fnsDate, today);
}

export function getTimes(datas) {
  return datas.reduce((result, current) => {
    const element = result.find((p) => p === current.startTime);
    if (!element) {
      result.push(current.startTime);
    }
    return result;
  }, []);
}
