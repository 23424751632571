import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSummary, getHistory,
} from '../../../services/mypage/pointHistory.service';
import {
  ACTION_TYPE,
  actCallApiGetPointSummarySuccess,
  actCallApiGetPointHistorySuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetSummary() {
  try {
    const datas = yield call(getSummary);
    yield put(actCallApiGetPointSummarySuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetPointHistory({ param }) {
  try {
    const datas = yield call(getHistory, param);
    yield put(actCallApiGetPointHistorySuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallPointHistory() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_POINT_SUMMARY, callApiGetSummary);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_POINT_HISTORY, callApiGetPointHistory);
}
