import React from 'react';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { format } from 'date-fns';
import { getDayWeek, getSpecificDateDatas, getDayStyle } from './booking-calendar-proc';
import BookingCard from '../bookingCard/BookingCard';

const useStyles = makeStyles(() => ({
  calendarTable: {
    border: 'solid 1px #DDD',
    textAlign: 'center',
    borderCollapse: 'separate',
  },
  menudiv: {
    borderRadius: '5px',
    color: 'black',
    border: '0px!important',
    display: 'block!important',
  },
  stickyHead: {
    border: 'solid 1px #DDD',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: '#fff',
  },
  tdHoliday: {
    background: '#f5f5f5',
    color: '#000000',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
}));

export default function Daily(props) {
  const classes = useStyles();

  const {
    isView,
    targetDate,
    datas,
    times,
    holidays,
  } = props;

  const dayDatas = getSpecificDateDatas(targetDate, datas);
  const isHoliday = holidays.indexOf(format(targetDate, 'yyyy/MM/dd')) > -1;

  return (
    <Box display={isView ? '' : 'none'} mt={2} mx={1}>
      <div className={classes.calendarTable}>
        <div className={classes.stickyHead}>{getDayWeek(targetDate)}</div>
        {
          isHoliday && (
            <div className={classes.tdHoliday}>
              休館日
            </div>
          )
        }
        {
          !isHoliday && (
            <div className="rbc-time-content">
              <div className="rbc-time-gutter rbc-time-column">
                {times.map((time) => (
                  <div className="rbc-timeslot-group" key={`${time}daylytime`}>
                    <div className="rbc-time-slot">
                      <span className="rbc-label">{time}</span>
                    </div>
                    <div className="rbc-time-slot" />
                  </div>
                ))}
              </div>
              <div className="rbc-day-slot rbc-time-column">
                {times.map((time) => (
                  <div className="rbc-timeslot-group" key={`${time}dailytimeslot`}>
                    <div className="rbc-time-slot" />
                    <div className="rbc-time-slot" />
                  </div>
                ))}
                <div className="rbc-events-container">
                  {dayDatas.map((data, index) => (
                    <BookingCard
                      data={data}
                      isDayShow
                      key={`${data.id}daily`}
                      style={getDayStyle(data, times[0], index, dayDatas)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </Box>
  );
}
