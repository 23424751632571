import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export function getSelects(memberId) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.SELECTS, memberId)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getCultureBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.CULTURE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRentalCoatTableTennisBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.RENTAL_COAT_TABLE_TENNIS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRentalCoatBadmintonBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.RENTAL_COAT_BADMINTON, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRoomBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.ROOM, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getGolfBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.GOLF, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getOnlineCultureBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.BOOKING.ONLINE_CULTURE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
