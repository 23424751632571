import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Container,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TabPanel from '../../../../../components/atoms/tabPanel/TabPanel';
import UnansweredTable from './UnansweredTable';
import AnsweredTable from './AnsweredTable';
import MonthTitle from '../monthTitle/MonthTitle';
import AllTable from './AllTable';
import {
  actSetSearchCondition, actCallApiGetSurveyUnanswered,
  actCallApiGetSurveyAnswered, actCallApiGetSurveyAll,
} from '../../../../../redux/mypage/surveyList/action';
import { createSurveyListCondition } from '../../mypage-proc';

// #region
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  toggleButtonRoot: {
    color: '#000!important',
  },
  serachBox: {
    padding: '8px',
    alignItems: 'center',
  },
  contentRoot: {
    paddingLeft: '0',
  },
});
// #endregion

class SurveyList extends React.Component {
  constructor(props) {
    super(props);
    const condition = createSurveyListCondition(props);
    this.search(condition);
    this.state = {
      condition,
    };
  }

  handleAlignment = (_, newValue) => {
    if (!newValue) {
      return;
    }
    const { condition } = this.state;
    condition.alignment = newValue;
    this.search(condition);
  }

  handleDateChange = (_, values) => {
    const { condition } = this.state;
    condition.targetMonth = values;
    this.search(condition);
  }

  handleChangePage = (_, newPage) => {
    const { condition } = this.state;
    condition.page = newPage;
    this.search(condition);
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { condition } = this.state;
    condition.records = rowsPerPage;
    this.search(condition);
  }

  search = (condition) => {
    this.props.dispatch(actSetSearchCondition(condition));
    if (condition.alignment === 'unanswered') {
      this.props.dispatch(actCallApiGetSurveyUnanswered(condition));
    }
    if (condition.alignment === 'answered') {
      this.props.dispatch(actCallApiGetSurveyAnswered(condition));
    }
    if (condition.alignment === 'all') {
      this.props.dispatch(actCallApiGetSurveyAll(condition));
    }
  }

  render() {
    const { classes, value, index } = this.props;
    const {
      targetMonth, page, records, alignment,
    } = this.state.condition;
    const {
      unansweredList, answeredList, allList,
    } = this.props.surveyList;

    return (
      <TabPanel value={value} index={index} style={{ width: '100%' }}>
        <Box className={classes.root}>
          <Container className={classes.serachBox}>
            <Box>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={this.handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="unanswered" className={classes.toggleButtonRoot}>
                  未回答
                </ToggleButton>
                <ToggleButton value="answered" className={classes.toggleButtonRoot}>
                  回答済
                </ToggleButton>
                <ToggleButton value="all" className={classes.toggleButtonRoot}>
                  全て
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Container>

          <Container className={classes.contentRoot}>
            <Box display={alignment === 'unanswered' ? '' : 'none'}>
              <UnansweredTable
                datas={unansweredList || []}
                page={page}
                records={records}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Box>

            <Box display={alignment !== 'unanswered' ? '' : 'none'}>
              <MonthTitle
                targetMonth={targetMonth}
                handleChange={this.handleDateChange}
              />

              <Box display={alignment === 'answered' ? '' : 'none'}>
                <AnsweredTable
                  datas={answeredList || []}
                  page={page}
                  records={records}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Box>

              <Box display={alignment === 'all' ? '' : 'none'}>
                <AllTable
                  datas={allList || []}
                  page={page}
                  records={records}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </TabPanel>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, surveyList: state.surveyList });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(SurveyList)));
