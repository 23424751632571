import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box,
  Button,
} from '@material-ui/core';
import {
  subMinutes, format,
} from 'date-fns';
import redirectLinks from '../../../../../constants/redirectLinks';
import {
  actSetMypageTabValue,
} from '../../../../../redux/mypage/mypage/action';

const styles = () => ({
  link: {
    color: '#2196f3',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class CompleteBooking extends Component {
  onBackClick = () => {
    this.props.history.push(redirectLinks.BOOKING);
  }

  onNextClick = () => {
    this.props.history.push(redirectLinks.MYPAGE);
  }

  onTopClick = () => {
    this.props.history.push(redirectLinks.BASE);
  }

  getOnlineCulturePossibleEnterDate = () => {
    const {
      targetedOn, startTime,
      possibleEnterNumber,
    } = this.props.data;

    return format(subMinutes(new Date(`${targetedOn} ${startTime}`), possibleEnterNumber), 'M月dd日 HH時mm分');
  }

  setMyPageUrl = () => {
    this.props.dispatch(actSetMypageTabValue(0));
    window.location.href = redirectLinks.MYPAGE;
  }

  render() {
    const { data, classes } = this.props;

    return (
      <Box my={{ xs: 3, sm: 2, md: 0 }}>
        <Box fontWeight="fontWeightBold" m={1} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} textAlign="center">ご予約が完了しました</Box>
        <Box m={1} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} fontWeight="fontWeightBold" textAlign="center">{`${data.name}様、ご予約ありがとうございます。`}</Box>

        <Box mt={2}>
          <Box textAlign="center" bgcolor="#f5f5f5" p={2} borderLeft="1px solid rgb(170, 170, 170)" borderRight="1px solid rgb(170, 170, 170)" borderTop="1px solid rgb(170, 170, 170)">
            <Box fontWeight="fontWeightBold">ご予約番号</Box>
          </Box>
          <Box textAlign="center" border={1} borderColor="#aaa" p={2}>
            <Box>{data.bookingCode}</Box>
          </Box>
        </Box>

        <Box mt={5} display={data.email ? '' : 'none'}>
          <Box m={1} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} fontWeight="fontWeightBold" textAlign="center">ご予約確認メールを下記メールアドレス宛てに送信致しました。</Box>
          <Box textAlign="center" bgcolor="#f5f5f5" p={2} borderLeft="1px solid rgb(170, 170, 170)" borderRight="1px solid rgb(170, 170, 170)" borderTop="1px solid rgb(170, 170, 170)">
            <Box fontWeight="fontWeightBold">送信先メールアドレス</Box>
          </Box>
          <Box textAlign="center" border={1} borderColor="#aaa" p={2}>
            <Box>{data.email}</Box>
          </Box>
        </Box>

        { !data.guestBooking && (
          <Box mt={5} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} fontWeight="fontWeightBold" textAlign="center">マイページからもご予約内容の確認が行えます。</Box>
        )}
        { data.contentId === 17 && (
          <Box my={5}>
            <Box fontWeight="fontWeightBold" fontSize={20}>オンラインカルチャー受講方法</Box>
            <Box p={{ xs: 2, sm: 3 }} border={1} borderColor="#aaa">
              <Box textAlign="left">
                <Box>{`${this.getOnlineCulturePossibleEnterDate()}より出席が可能になります。`}</Box>
              </Box>
              <Box mt={3}>
                <Box fontSize={{ xs: '13px', sm: '15px' }} fontWeight="fontWeightBold">
                  マイページより
                </Box>
                <Box display="flex" fontSize={{ xs: '13px', sm: '15px' }}>
                  <div role="button" aria-hidden="true" className={classes.link} onClick={this.setMyPageUrl}>
                    マイページ
                  </div>
                  <div>
                    よりご予約をご確認ください。
                  </div>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box alignSelf="center" fontSize={{ xs: '13px', sm: '15px' }} mr={2}>
                    時間になりましたら、
                  </Box>
                  <Box display="flex">
                    <Box mr={2}>
                      <Button variant="contained" color="primary">出席する</Button>
                    </Box>
                    <Box alignSelf="center" justify="center" fontSize={{ xs: '13px', sm: '15px' }}>
                      ボタンが表示されます。
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" mt={{ xs: 1 }}>
                  <Box fontSize={{ xs: '13px', sm: '15px' }} mr={2}>
                    <Button variant="contained" color="primary">出席する</Button>
                  </Box>
                  <Box alignSelf="center" fontSize={{ xs: '13px', sm: '15px' }}>
                    より受講開始をしてください。
                  </Box>
                </Box>
              </Box>
              <Box mt={3}>
                <Box fontWeight="fontWeightBold">
                  メールより
                </Box>
                <Box>
                  {!data.todayOnlineRecept && (
                    <Box fontSize={{ xs: '13px', sm: '15px' }}>
                      {`${format(new Date(data.targetedOn), 'M月dd日')} 8時にご登録メールアドレスに受講用URLを送信いたします。`}
                    </Box>
                  )}
                  {data.todayOnlineRecept && (
                    <Box fontSize={{ xs: '13px', sm: '15px' }}>
                      予約受付完了メールに受講用URLを送信いたしました。
                    </Box>
                  )}
                  <Box fontSize={{ xs: '13px', sm: '15px' }}>
                    そちらのURLより受講開始してください。
                  </Box>
                  {!data.todayOnlineRecept && (
                    <Box>
                      <Box fontSize={{ xs: '13px', sm: '15px' }}>
                        ※メール送信時間は前後する可能性がありますので、ご了承ください。
                      </Box>
                      <Box fontSize={{ xs: '13px', sm: '15px' }}>
                        ※メールアドレスが登録されていない場合、送信されません。
                      </Box>
                    </Box>
                  )}
                  <Box fontSize={{ xs: '13px', sm: '15px' }}>
                    ※出席可能時間前に出席はできません。
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <Box my={5} align="center">
          <Button variant="contained" className="custom-button" onClick={this.onBackClick}>続けて予約する</Button>
          { !data.guestBooking && (
            <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={this.onNextClick}>マイページを確認する</Button>
          )}
          { data.guestBooking && (
            <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={this.onTopClick}>トップに戻る</Button>
          )}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({ mypage: state.mypage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(CompleteBooking)));
