// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_SURVEY_DETAIL: 'CALL_API_GET_SURVEY_DETAIL',
  GET_SURVEY_DETAIL_SUCCESS: 'GET_SURVEY_DETAIL_SUCCESS',
  CALL_API_CREATE_SURVEY: 'CALL_API_CREATE_SURVEY',
  CREATE_SURVEY_SUCCESS: 'CREATE_SURVEY_SUCCESS',
  RESET_SURVEY_DETAIL_FLG: 'RESET_SURVEY_DETAIL_FLG',
};
// #endregion

// #region action
export const actCallApiGetSurveyDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_DETAIL,
  param,
});

export const actGetSurveyDetailSuccess = (data) => ({
  type: ACTION_TYPE.GET_SURVEY_DETAIL_SUCCESS,
  data,
});

export const actCallApiCreateSurvey = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_SURVEY,
  param,
});

export const actCreateSurveySuccess = (data) => ({
  type: ACTION_TYPE.CREATE_SURVEY_SUCCESS,
  data,
});

export const actResetSurveyDetailFlg = () => ({
  type: ACTION_TYPE.RESET_SURVEY_DETAIL_FLG,
});
// #endregion
