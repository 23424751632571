import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Button,
  Chip,
} from '@material-ui/core';
import DangerButton from '../../../../../components/atoms/dangerButton/DangerButton';
import redirectLinks from '../../../../../constants/redirectLinks';
import { checkDoCancel } from './booking-list-proc';
import Pagination from '../../../../../components/atoms/pagination/Pagination';

const styles = (theme) => ({
  cardTop: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  flexTitle: {
    flex: '1 1',
  },
  bookingDetailButton: {
    marginLeft: '20px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '10px',
    },
  },
});

class DoingBookingTable extends React.Component {
  onClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 0 },
    });
  }

  onCancelClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 1 },
    });
  }

  onBookingClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 2 },
    });
  }

  render() {
    const {
      classes,
      datas,
      page,
      records,
      handleChangeRowsPerPage,
      handleChangePage,
    } = this.props;
    const rows = datas.content ? datas.content.filter((row) => row.memberStatus === 10) : [];

    return (
      <>
        {rows.map((row) => (
          <Box boxShadow={3} mt={3} key={`doingBooking-${row.bookingCode}`}>
            <Box p={3}>
              {row.bookingEnabledReception && (
                <Box mb={1}>
                  <Chip label="キャンセルが出たため、空きがあります" color="primary" size="small" />
                </Box>
              )}
              <div className={classes.cardTop}>
                <div className={classes.flexTitle}>
                  <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">{row.menuName}</Box>
                </div>
                <div>
                  <Chip color="secondary" label={row.contentName} />
                </div>
              </div>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={15}>予約番号</Box>
                <Box fontSize={15}>{row.bookingCode}</Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={15}>ご予約日時</Box>
                <Box fontSize={15}>{`${row.targetDate} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}</Box>
              </Box>
              <Box mt={2} display={row.roomName ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>Room</Box>
                <Box fontSize={15}>{row.roomName}</Box>
              </Box>
            </Box>
            <Box className={classes.cardTop} pr={{ xs: 1, md: 4 }} pl={{ xs: 1, md: 0 }} pb={2}>
              <div className={classes.flexTitle} />
              <Box display="flex">
                { row.bookingEnabledReception && (
                  <Box mr={{ xs: 1, md: 2 }}><Button variant="contained" color="primary" onClick={(event) => this.onBookingClick(event, row)}>予約する</Button></Box>
                )}
                { checkDoCancel(row) ? (<DangerButton variant="contained" label="キャンセルする" onClick={(event) => this.onCancelClick(event, row)} />) : (<div />)}
                <Button variant="contained" className={classes.bookingDetailButton} onClick={(event) => this.onClick(event, row)}>予約内容詳細</Button>
              </Box>
            </Box>
          </Box>
        ))}
        <Box border={1} borderColor="grey.500" display={rows.length === 0 ? 'flex' : 'none'} minHeight={100} textAlign="center" justifyContent="center" alignItems="center">
          キャンセル待ちの予約情報はありません。
        </Box>
        <Pagination
          totalElements={datas.totalElements}
          records={records}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(DoingBookingTable));
