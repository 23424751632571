import React from 'react';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { getMonthly, getSpecificDateDatas } from './booking-calendar-proc';

const useStyles = makeStyles((theme) => ({
  tdDisabled: {
    background: '#e6e6e6',
    color: '#999999',
  },
  calendarTable: {
    border: 'solid 1px #DDD',
    borderCollapse: 'collapse',
    textAlign: 'center',
    tableLayout: 'fixed',
  },
  calendarTd: {
    border: 'solid 1px #DDD',
    width: '14.2%',
    verticalAlign: 'top',
  },
  moreLink: {
    color: '#2196f3',
    textDecoration: 'underline',
    marginTop: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  tdHeight: {
    height: '150px',
    [theme.breakpoints.down('xs')]: {
      height: '100px',
    },
  },
  tdHoliday: {
    background: '#f5f5f5',
    color: '#000000',
  },
  holidayStyle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
}));

export default function Monthly(props) {
  const classes = useStyles();

  const {
    isView,
    targetDate,
    datas,
    changeViewMoreLink,
    holidays,
  } = props;

  const dayElement = (item) => {
    const dayDatas = getSpecificDateDatas(item.date, datas);
    let linkElement = null;
    if (item.isHoliday && item.isSomeMonth) {
      linkElement = (
        <div className={classes.holidayStyle}>
          休館日
        </div>
      );
    }
    if (!item.isHoliday && dayDatas.length > 0) {
      const data = dayDatas[0];
      linkElement = (
        <div className={classes.moreLink} onClick={(event) => changeViewMoreLink(event, data.date)} role="button" aria-hidden="true">
          この日の教室情報を見る
        </div>
      );
    }
    return (
      <div>
        {' '}
        {linkElement}
        {' '}
      </div>
    );
  };

  const monthly = getMonthly(targetDate, holidays);

  return (
    <Box display={isView ? '' : 'none'} mt={2} mx={1}>
      <table width="100%" className={classes.calendarTable}>
        <thead>
          <tr>
            <th className={classes.calendarTd}>月</th>
            <th className={classes.calendarTd}>火</th>
            <th className={classes.calendarTd}>水</th>
            <th className={classes.calendarTd}>木</th>
            <th className={classes.calendarTd}>金</th>
            <th className={classes.calendarTd}>土</th>
            <th className={classes.calendarTd}>日</th>
          </tr>
        </thead>
        <tbody>
          {monthly.map((weekRow, index) => (
            <tr key={`${weekRow[index].date}month`}>
              {weekRow.map((item) => (
                <td key={item.day} className={`${!item.isSomeMonth && classes.tdDisabled} ${(item.isSomeMonth && item.isHoliday) && classes.tdHoliday} ${classes.calendarTd} ${classes.tdHeight}`}>
                  <div>{item.day}</div>
                  {dayElement(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}
