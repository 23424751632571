import { ACTION_TYPE } from './action';

const bookingDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCreateSuccess: false,
      };
    case ACTION_TYPE.CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        successData: action.data,
        isCreateSuccess: true,
      };
    case ACTION_TYPE.RESET_CREATE_BOOKING_FLG_RESET:
      return {
        ...state,
        isCreateSuccess: false,
      };
    default:
      return state;
  }
};

export default bookingDetail;
