import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box,
  TextField,
  FormControl,
  Button,
  withStyles,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FormErrorText from '../../../../../components/atoms/formErrorText/FormErrorText';
import SearchSelectBox from '../../../../../components/atoms/searchSelectBox/SearchSelectBox';
import SideInputGrid from '../../../../../components/organisms/sideInputGrid/SideInputGrid';
import { errorMessageFormMember, createMemberForm } from './contract-proc';
import validation from './validation';
import { getZipCodeAddress, actCallApiSelectValues, actCallApiMemberEdit } from '../../../../../redux/mypage/mypage/action';
import SideDisplayGrid from '../../../../../components/organisms/sideDisplayGrid/SideDisplayGrid';

// #region css
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  buttonFirst: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginBottom: '20px',
    },
  },
});
// #endregion

class MemberEditTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: errorMessageFormMember(),
      member: createMemberForm(),
      isLoading: false,
      errorMessageShowFlg: false,
      selectValues: {
        prefecturesSelectList: [],
      },
    };

    props.dispatch(actCallApiSelectValues());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mypage.zipcode !== this.props.mypage.zipcode) {
      if (this.props.mypage.zipcode) {
        this.setAddress(this.props.mypage.zipcode);
      }
    }
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        this.setMember(this.props.data);
      }
    }
    if (prevProps.mypage.selectValues !== this.props.mypage.selectValues) {
      if (this.props.mypage.selectValues) {
        this.setSelectValues(this.props.mypage.selectValues);
      }
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  setMember = (data) => {
    this.setState({
      member: data.member,
      errorMessage: errorMessageFormMember(),
      isLoading: false,
      errorMessageShowFlg: false,
    });
  }

  setAddress = (value) => {
    const { member, errorMessage } = this.state;
    let selectPrefecture = null;
    let city = '';
    let street = '';
    let zipCode = '';

    if (value.results && value.results.length > 0) {
      selectPrefecture = {
        name: value.results[0].address1,
        id: parseInt(value.results[0].prefcode, 10),
      };
      city = value.results[0].address2;
      street = value.results[0].address3;
    }
    if (!value.results && member.zipCode) {
      zipCode = '存在しない郵便番号です';
    }
    this.setState({
      member: {
        ...member,
        selectPrefecture,
        city,
        street,
      },
      errorMessage: {
        ...errorMessage,
        zipCode,
      },
      isLoading: false,
    });
  }

  setSelectValues = (selectValues) => {
    this.setState({ selectValues });
  }

  onChangeTextField = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage } = this.state;
    member[name] = value;

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: validation.formValidate(name, value, null),
      },
    });
  }

  onChangeZipCode = (event) => {
    const { name, value } = event.target;
    const { member, errorMessage } = this.state;

    const error = validation.formValidate('zipCodeNorequired', value);
    let isLoading = false;
    if (!error) {
      isLoading = true;
      this.props.dispatch(getZipCodeAddress({
        zipCode: value,
      }));
    }

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        zipCode: error,
      },
      isLoading,
    });
  }

  onChangePrefecture = (event) => {
    const { member } = this.state;
    const { name, value } = event.target;

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
    });
  }

  onChangePhone = (event) => {
    const { member, errorMessage } = this.state;
    const { name, value } = event.target;

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        phone: validation.formValidate('phone', value),
      },
    });
  }

  onChangePhoneTwo = (event) => {
    const { member, errorMessage } = this.state;
    const { name, value } = event.target;

    this.setState({
      member: {
        ...member,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        phoneTwo: validation.formValidate('phoneTwo', value),
      },
    });
  }

  onClickUpdate = () => {
    const {
      errorMessage,
      member,
    } = this.state;

    let tempMessage = errorMessageFormMember();

    const phone = validation.formValidate('phone', member.phone);

    const phoneTwo = validation.formValidate('phoneTwo', member.phoneTwo);

    const zipCode = validation.formValidate('zipCodeNorequired', member.zipCode);

    tempMessage = {
      ...tempMessage,
      phone,
      phoneTwo,
      zipCode,
    };

    Object.keys(errorMessage).map(
      (key) => {
        if ((key === 'phone')
        || (key === 'phoneTwo')
        || (key === 'zipCodeNorequired')) { return null; }

        tempMessage = {
          ...tempMessage,
          [key]: validation.formValidate(key, member[key]),
        };
        return null;
      },
    );

    const isError = Object.values(tempMessage).filter((value) => value !== '').length > 0;

    if (!isError) {
      this.props.dispatch(actCallApiMemberEdit(member));
    }
    this.setState({ errorMessageShowFlg: isError, errorMessage: tempMessage });
    this.scrollToTop();
  }

  onClickCancel = () => {
    this.props.onCancel();
  }

  render() {
    const { classes } = this.props;

    const {
      errorMessage,
      errorMessageShowFlg,
      member,
      isLoading,
      selectValues,
    } = this.state;

    return (
      <Box className={classes.root}>
        <Box mb={3}>
          <Alert style={{ display: errorMessageShowFlg ? '' : 'none' }} severity="error">入力エラーがあります。確認してください。</Alert>
        </Box>

        <div>
          <Box mt={3} fontWeight="fontWeightBold" fontSize="h6.fontSize">ご利用者様情報</Box>
          <hr />

          <Grid container spacing={0}>
            <SideDisplayGrid title="氏名" content={`${member.lastName} ${member.firstName}`} />
            <SideDisplayGrid title="氏名カナ" content={`${member.lastNameKana} ${member.firstNameKana}`} />
          </Grid>

          <SideInputGrid title="郵便番号">
            <FormControl component="fieldset">
              <Box display="flex">
                <Box>
                  <TextField
                    name="zipCode"
                    id="zipCodeMember"
                    value={member.zipCode || ''}
                    variant="outlined"
                    onChange={this.onChangeZipCode}
                    placeholder="950-0032"
                    autoComplete="off"
                    error={errorMessage.zipCode !== ''}
                    inputProps={{ maxLength: 8 }}
                  />
                </Box>
              </Box>
              <Box display="flex">
                <FormErrorText>{errorMessage.zipCode}</FormErrorText>
              </Box>
              <Box>
                <FormHelperText style={{ color: 'black' }}>
                  郵便番号をご入力頂くと、住所の一部が自動入力されます
                </FormHelperText>
              </Box>
              <Box display={isLoading ? '' : 'none'}>
                <FormHelperText style={{ color: 'black' }}>
                  検索しています..
                </FormHelperText>
              </Box>
            </FormControl>
          </SideInputGrid>

          <SideInputGrid title="都道府県">
            <SearchSelectBox
              id="selectPrefecture"
              name="selectPrefectureCodeMember"
              style={{ width: '50%' }}
              onChange={this.onChangePrefecture}
              value={member.selectPrefecture}
              options={selectValues.prefecturesSelectList}
              placeholder="選択してください"
              disableClearable
            />
          </SideInputGrid>

          <SideInputGrid title="市区町村">
            <TextField
              name="city"
              id="cityMember"
              variant="outlined"
              autoComplete="off"
              onChange={this.onChangePrefecture}
              placeholder="例）新潟市江南区両川"
              value={member.city || ''}
              inputProps={{ maxLength: 255 }}
              fullWidth
            />
            <FormErrorText>{errorMessage.city}</FormErrorText>
          </SideInputGrid>

          <SideInputGrid title="町名・番地・その他">
            <TextField
              name="street"
              id="streetMember"
              variant="outlined"
              onChange={this.onChangePrefecture}
              placeholder="例）2丁目3927-15"
              autoComplete="off"
              value={member.street || ''}
              inputProps={{ maxLength: 255 }}
              fullWidth
            />
            <FormErrorText>{errorMessage.street}</FormErrorText>
          </SideInputGrid>

          <SideInputGrid title="メールアドレス" isRequired>
            <TextField
              name="email"
              id="emailMember"
              variant="outlined"
              onChange={this.onChangeTextField}
              placeholder="例）example@email.com"
              value={member.email}
              autoComplete="off"
              error={errorMessage.email !== ''}
              inputProps={{ maxLength: 80 }}
              fullWidth
            />
            <FormErrorText>{errorMessage.email}</FormErrorText>
          </SideInputGrid>

          <SideInputGrid title="電話番号" isRequired>
            <FormControl component="fieldset">
              <Box display="flex">
                <Box>
                  <TextField
                    name="phone"
                    id="phoneMember"
                    variant="outlined"
                    onChange={this.onChangePhone}
                    placeholder="025-250-7112"
                    autoComplete="off"
                    value={member.phone}
                    error={errorMessage.phone !== ''}
                    inputProps={{ maxLength: 15 }}
                  />
                </Box>
              </Box>
              <Box display="flex">
                <FormErrorText>{ errorMessage.phone }</FormErrorText>
              </Box>
            </FormControl>
          </SideInputGrid>

          <SideInputGrid title="電話番号2">
            <FormControl component="fieldset">
              <Box display="flex">
                <Box key="phoneTwoKey">
                  <TextField
                    name="phoneTwo"
                    id="phoneTwoMember"
                    variant="outlined"
                    onChange={this.onChangePhoneTwo}
                    placeholder="090-1234-5678"
                    autoComplete="off"
                    value={member.phoneTwo || ''}
                    error={errorMessage.phoneTwo !== ''}
                    inputProps={{ maxLength: 15 }}
                  />
                </Box>
              </Box>
              <Box display="flex">
                <FormErrorText>{ errorMessage.phoneTwo }</FormErrorText>
              </Box>
            </FormControl>
          </SideInputGrid>

          <Grid container spacing={0}>
            <SideDisplayGrid title="生年月日" content={member.formatBirthday} />
            <SideDisplayGrid title="性別" content={member.gender} />
          </Grid>

          <Box align="center" textAlign="center" mt={5} display={{ xs: 'block', sm: 'flex' }} justifyContent="center">
            <Box>
              <Button
                variant="contained"
                onClick={this.onClickCancel}
                className={classes.buttonFirst}
              >
                キャンセル
              </Button>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={this.onClickUpdate}
              >
                保存
              </Button>
            </Box>
          </Box>
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({ mypage: state.mypage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MemberEditTable)));
