import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getCommonInfo,
  getContract,
  updatePassword,
  getRegulars,
  updateRegularStatus,
  getBookings,
  getBookingContents,
  updateBookingCancel,
  updateBookingConfirm,
  getZipCodeAddress,
  getSelectValues,
  contractEdit,
  memberEdit,
} from '../../../services/mypage/mypage.service';
import {
  ACTION_TYPE,
  actCallApiGetCommonInfoSuccess,
  actGetContractSuccess,
  actUpadetePasswordSuccess,
  actGetRegularsSuccess,
  actUpdateRegularStatusSuccess,
  actGetBookingsSuccess,
  actGetBookingContentsSuccess,
  actUpdateBookingCancelSuccess,
  actUpdateBookingConfirmSuccess,
  getZipCodeAddressSuccess,
  actCallApiSelectValuesSuccess,
  actCallApiContractEditSuccess,
  actCallApiMemberEditSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetCommonInfo() {
  try {
    const data = yield call(getCommonInfo);
    yield put(actCallApiGetCommonInfoSuccess(data));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiContract() {
  try {
    const datas = yield call(getContract);
    yield put(actGetContractSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdatePassword(param) {
  try {
    yield call(updatePassword, param.password);
    yield put(actUpadetePasswordSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetRegulars(param) {
  try {
    const datas = yield call(getRegulars, param);
    yield put(actGetRegularsSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdateRegularStatus(param) {
  try {
    yield call(updateRegularStatus, param);
    yield put(actUpdateRegularStatusSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetBookings(param) {
  try {
    const datas = yield call(getBookings, param);
    yield put(actGetBookingsSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetBookingContents() {
  try {
    const datas = yield call(getBookingContents);
    yield put(actGetBookingContentsSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdateBookingCancel(param) {
  try {
    const result = yield call(updateBookingCancel, param.param);
    yield put(actUpdateBookingCancelSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdateBookingConfirm({ param }) {
  try {
    const result = yield call(updateBookingConfirm, param);
    yield put(actUpdateBookingConfirmSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetZipCodeAddress({ contidion }) {
  try {
    const zipCodeAddress = yield call(getZipCodeAddress, { zipCode: contidion.zipCode });
    yield put(getZipCodeAddressSuccess(zipCodeAddress));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiSelectValues() {
  try {
    const selectValues = yield call(getSelectValues);
    yield put(actCallApiSelectValuesSuccess(selectValues));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiContractEdit({ contract }) {
  try {
    yield call(contractEdit, contract);
    yield put(actCallApiContractEditSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiMemberEdit({ member }) {
  try {
    yield call(memberEdit, member);
    yield put(actCallApiMemberEditSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallMypage() {
  yield takeLatest(ACTION_TYPE.CALL_API_MYPAGE_COMMON_INFO, callApiGetCommonInfo);
  yield takeLatest(ACTION_TYPE.CALL_API_CONTRACT, callApiContract);
  yield takeLatest(ACTION_TYPE.CALL_API_CHANGE_PASSWORD, callApiUpdatePassword);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_REGULARS, callApiGetRegulars);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_REGULAR_STATUS, callApiUpdateRegularStatus);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_BOOKINGS, callApiGetBookings);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_BOOKING_CONTENTS, callApiGetBookingContents);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_BOOKING_CANCEL, callApiUpdateBookingCancel);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_BOOKING_CONFIRM, callApiUpdateBookingConfirm);
  yield takeLatest(ACTION_TYPE.GET_ZIP_CODE_ADDRESS, callApiGetZipCodeAddress);
  yield takeLatest(ACTION_TYPE.CALL_API_SELECT_VALUES, callApiSelectValues);
  yield takeLatest(ACTION_TYPE.CALL_API_CONTRACT_EDIT, callApiContractEdit);
  yield takeLatest(ACTION_TYPE.CALL_API_MEMBER_EDIT, callApiMemberEdit);
}
