import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container, Tabs, withStyles, IconButton,
  Tab, Box, Typography, Hidden, Toolbar,
  Button, Divider, Dialog, AppBar, Slide, Chip,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import {
  Close as CloseIcon,
  EventNote as EventNoteIcon,
  Event as EventIcon,
  LockOpen as LockOpenIcon,
  RateReview as RateReviewIcon,
  Person as PersonIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@material-ui/icons';
import QRCode from 'qrcode.react';
import { createRegularCondition, createBookingCondition } from './mypage-proc';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import redirectLinks from '../../../constants/redirectLinks';
import BookingList from './tabContent/bookingList/BookingList';
import RegularList from './tabContent/regularList/RegularList';
import ContractInfo from './tabContent/contractInfo/ContractInfo';
import SurveyList from './tabContent/surveyList/SurveyList';
import NoticeList from './tabContent/noticeList/NoticeList';
import ChangePassword from './tabContent/changePassword/ChangePassword';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import InfoDialog from '../../../components/templates/infoDialog/InfoDialog';
import TabPanel from '../../../components/atoms/tabPanel/TabPanel';
import {
  actCallApiGetRegulars, actSetRegularSearchCondition,
  actSetMypageTabValue, actCallApiGetBookingContents, actSetBookingSearchCondition,
  actCallApiGetBookings, actCallApiGetCommonInfo,
} from '../../../redux/mypage/mypage/action';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
  },
  tabRoot: {
    flexGrow: 1,
    display: 'flex',
    marginTop: '30px',
  },
  tabButton: {
    maxWidth: '100%',
    minWidth: '100%',
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  menuRoot: {
    [theme.breakpoints.up('lg')]: {
      minHeight: '560px',
      maxHeight: '560px',
    },
  },
  breakWord: {
    'word-break': 'break-all',
  },
  link: {
    color: '#1976D2',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  chipColor: {
    background: '#f50057',
    color: '#fff',
  },
});
// #endregion

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class MyPage extends React.Component {
  constructor(props) {
    super(props);
    let tabValue = 0;
    if (props.mypage.tabValue) {
      tabValue = props.mypage.tabValue;
    }
    this.getTabDatas(tabValue);

    this.state = {
      tabValue,
      qrOpen: false,
      logoutConfOpen: false,
    };
    this.breadcrumbs = [
      { name: 'マイページ' },
    ];
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (!token) {
      this.props.history.push({
        pathname: redirectLinks.LOGIN,
      });
    }
    this.props.dispatch(actCallApiGetCommonInfo());
  }

  getTabDatas = (value) => {
    let param = createBookingCondition(this.props);
    this.props.dispatch(actSetBookingSearchCondition(param));
    this.props.dispatch(actCallApiGetBookingContents());
    this.props.dispatch(actCallApiGetBookings(param));
    param = createRegularCondition(this.props);
    this.props.dispatch(actSetRegularSearchCondition(param));
    this.props.dispatch(actCallApiGetRegulars(param));
    this.props.dispatch(actSetMypageTabValue(value));
  }

  handleTabChange = (_, newValue) => {
    this.getTabDatas(newValue);
    this.setState({ tabValue: newValue });
  }

  handleQrClose = () => {
    this.setState({ qrOpen: false });
  }

  handleQrOpen = () => {
    this.setState({ qrOpen: true });
  }

  onLogoutClick = () => {
    this.setState({ logoutConfOpen: true });
  }

  onLogoutCancelClick = () => {
    this.setState({ logoutConfOpen: false });
  }

  logout = () => {
    localStorage.clear();
    document.cookie = 'mjwt=; path=/; max-age=0';
    window.location.href = redirectLinks.BASE;
  }

  onPointHistoryClick = () => {
    this.props.history.push({
      pathname: redirectLinks.POINT_HISTORY,
    });
  }

  render() {
    const { classes } = this.props;
    const { tabValue, logoutConfOpen, qrOpen } = this.state;

    const { user } = this.props.common;
    const commonData = this.props.mypage.commonData || {};
    const contractData = this.props.mypage.contractDatas
      ? (this.props.mypage.contractDatas.contract || {})
      : {};
    const userName = user && `${user.user.lastName + user.user.firstName}様`;

    const survey = (
      <Box display={{ sx: 'block', lg: 'flex' }}>
        <Box mr={1}>授業アンケート</Box>
        {commonData.unanswered > 0 && (<Chip size="small" label={`未回答:${commonData.unanswered}`} className={classes.chipColor} />)}
      </Box>
    );

    return (
      <div>
        <Header isNotShowBtn />
        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />
        </Container>
        <Container className={classes.root} maxWidth={false}>
          <Hidden mdDown>
            <div className={classes.tabRoot}>
              <Box boxShadow={3} width="25%" className={classes.menuRoot}>
                <Box fontWeight="fontWeightBold" m={2} fontSize="h6.fontSize" textAlign="center" className={classes.breakWord}>
                  {userName}
                </Box>
                {commonData.pointValid && (
                  <Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Box fontSize="h5.fontSize">{commonData.restPoint}</Box>
                      <Box ml={1}>ポイント</Box>
                    </Box>
                    <Box textAlign="right" mr={1}><Button className={classes.link} onClick={this.onPointHistoryClick}>ポイント履歴</Button></Box>
                  </Box>
                )}
                <Box textAlign="center" mt={3} mb={3}>
                  <Box display={contractData.contractCode === 3 ? 'none' : 'block'}>
                    <Button variant="contained" color="secondary" size="small" onClick={this.handleQrOpen} disableElevation>会員QRコードを表示する</Button>
                  </Box>
                </Box>
                <Divider />
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={this.handleTabChange}
                  aria-label="Vertical tabs"
                >
                  <Tab label="お知らせ" className={classes.tabButton} />
                  <Tab label="予約一覧" className={classes.tabButton} />
                  <Tab label="定期一覧" className={classes.tabButton} style={{ display: commonData.regularContents && commonData.regularContents.length > 0 ? '' : 'none' }} />
                  <Tab label="会員情報" className={classes.tabButton} />
                  <Tab label={survey} className={classes.tabButton} />
                  <Tab label="パスワード変更" className={classes.tabButton} />
                </Tabs>
                <Box textAlign="center" my={1}>
                  <Button variant="contained" onClick={this.onLogoutClick} disableElevation>ログアウトする</Button>
                </Box>
              </Box>
              <NoticeList value={tabValue} index={0} />
              <BookingList value={tabValue} index={1} />
              <RegularList value={tabValue} index={2} />
              <ContractInfo value={tabValue} index={3} />
              <SurveyList value={tabValue} index={4} />
              <ChangePassword value={tabValue} index={5} />
              <TabPanel value={tabValue} index={6}> </TabPanel>
            </div>
          </Hidden>

          <Hidden lgUp>
            <Box mt={2}>
              <Typography variant="h6" gutterBottom>
                {user && (`${`${user.user.lastName} ${user.user.firstName}`}様`)}
              </Typography>
            </Box>
            <Box mt={2} boxShadow={2}>
              <Tabs
                value={tabValue}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="icon label tabs example"
              >
                <Tab icon={<HomeIcon />} label="ホーム" className={classes.tab} />
                <Tab icon={<NotificationsActiveIcon />} label="お知らせ" className={classes.tab} />
                <Tab icon={<EventNoteIcon />} label="予約一覧" className={classes.tab} />
                <Tab icon={<EventIcon />} label="定期一覧" className={classes.tab} style={{ display: commonData.regularContents && commonData.regularContents.length > 0 ? '' : 'none' }} />
                <Tab icon={<PersonIcon />} label="会員情報" className={classes.tab} />
                <Tab icon={<RateReviewIcon />} label={survey} className={classes.tab} />
                <Tab icon={<LockOpenIcon />} label="パスワード変更" className={classes.tab} />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0} style={{ width: '100%' }}>
              <Box mt={3}>
                <Container>
                  {commonData.pointValid && (
                    <Box boxShadow={2} p={2} mt={2}>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Box fontSize="h5.fontSize">{commonData.restPoint}</Box>
                        <Box ml={1}>ポイント</Box>
                      </Box>
                      <Box textAlign="center" mt={1}>
                        <Button variant="contained" color="primary" onClick={this.onPointHistoryClick} disableElevation>ポイント履歴</Button>
                      </Box>
                    </Box>
                  )}
                  <Box textAlign="center" mt={3} mb={3} display={contractData.contractCode === 3 ? 'none' : 'block'}>
                    <Button variant="contained" color="secondary" onClick={this.handleQrOpen}>会員QRコードを表示する</Button>
                  </Box>
                  <Box textAlign="center" my={3}>
                    <Button variant="contained" onClick={this.onLogoutClick}>ログアウトする</Button>
                  </Box>
                </Container>
              </Box>
            </TabPanel>
            <NoticeList value={tabValue} index={1} />
            <BookingList value={tabValue} index={2} />
            <RegularList value={tabValue} index={3} />
            <ContractInfo value={tabValue} index={4} />
            <SurveyList value={tabValue} index={5} />
            <ChangePassword value={tabValue} index={6} />
          </Hidden>
        </Container>

        <Footer />
        <Dialog
          fullScreen
          open={qrOpen}
          onClose={this.handleQrClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleQrClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Box fontSize="h6" fontWeight="fontWeightBold" className={classes.title}>
                会員QRコード
              </Box>
              <Button autoFocus color="inherit" onClick={this.handleQrClose}>
                閉じる
              </Button>
            </Toolbar>
          </AppBar>
          <Box my={5} fontWeight="fontWeightBold" fontSize={15} textAlign="center">{user && (`会員番号：${user.user.code}`)}</Box>
          <Box textAlign="center">
            <QRCode value={user && user.user.code} size={250} />
          </Box>
        </Dialog>
        <InfoDialog
          messages={['ログアウトします。よろしいですか？']}
          firstOnFunc={this.onLogoutCancelClick}
          firstFuncText="キャンセル"
          secondOnFunc={this.logout}
          secondFuncText="ログアウトする"
          open={logoutConfOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, mypage: state.mypage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(MyPage)));
