import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export function sendForgotEmail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.RESET_PASSWORD.FORGOT, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function checkExpiration(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.RESET_PASSWORD.CHECK_EXPIRATION, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function resetPassword(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.RESET_PASSWORD.RESET, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
