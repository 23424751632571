// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_BOOKING_DETAIL: 'CALL_API_GET_BOOKING_DETAIL',
  GET_BOOKING_DETAIL_SUCCESS: 'GET_BOOKING_DETAIL_SUCCESS',
  CALL_API_CREATE_BOOKING: 'CALL_API_CREATE_BOOKING',
  CREATE_BOOKING_SUCCESS: 'CREATE_BOOKING_SUCCESS',
  RESET_CREATE_BOOKING_FLG_RESET: 'RESET_CREATE_BOOKING_FLG_RESET',
};
// #endregion

// #region action
export const actCallApiGetBookingDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKING_DETAIL,
  param,
});

export const actGetBookingDetailSuccess = (data) => ({
  type: ACTION_TYPE.GET_BOOKING_DETAIL_SUCCESS,
  data,
});

export const actCallApiCreateBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_BOOKING,
  param,
});

export const actCreateBookingSuccess = (data) => ({
  type: ACTION_TYPE.CREATE_BOOKING_SUCCESS,
  data,
});

export const actResetCreateBookingFlgReset = () => ({
  type: ACTION_TYPE.RESET_CREATE_BOOKING_FLG_RESET,
});
// #endregion
