import React from 'react';
import { differenceInDays, format } from 'date-fns';
import {
  Typography,
  withStyles,
  Box,
  Tooltip,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import redirectLinks from '../../../../../constants/redirectLinks';

const styles = (theme) => ({
  root: {
    borderRadius: '5px',
    padding: '5px',
    margin: '3px',
    marginBottom: '10px',
    maxWidth: '100%',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
  },
  pointer: {
    cursor: 'pointer',
  },
  remainingRoot: {
    background: '#757575',
    borderRadius: '2px',
    color: '#ffffff',
    marginRight: '2px',
    padding: '1px 2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  menudiv: {
    borderRadius: '5px',
    color: 'black',
    border: '0px!important',
    display: 'block!important',
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  card: {
    width: '100%',
    height: '100%',
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #000',
  },
  tooltipPlacementTop: {
    top: '1000px',
  },
}))(Tooltip);

class BookingCard extends React.Component {
  onClick = (_, data) => {
    let url = '';
    if (data.remaining > 0) {
      url = redirectLinks.BOOKING_DETAIL;
    }
    if (data.remaining === 0 && data.cancelWaitAccepted) {
      url = redirectLinks.BOOKING_DETAIL_CANCEL_WAITE;
    }

    if (!url) {
      return;
    }
    this.props.history.push({
      pathname: url,
      state: { bookingId: data.id, contentId: data.contentId },
    });
  };

  getDispPeriod = (data) => {
    if (data.cultureCloseDay !== 0) {
      return data.receptPeriod;
    }
    return differenceInDays(new Date(data.date), new Date(format(new Date(), 'yyyy/MM/dd')));
  };

  render() {
    const {
      classes,
      data,
      isDayShow,
      style,
      key,
    } = this.props;

    return (
      <div
        style={isDayShow ? style : null}
        className={isDayShow ? `${classes.menudiv} rbc-event` : null}
        key={key}
      >
        <HtmlTooltip
          placement="left-start"
          title={(
            <div>
              <Box mt={1} fontWeight="fontWeightBold" fontSize={15}>{data.menuName}</Box>
              <Typography variant="subtitle1">{`${data.dispDate} (${data.week}) ${data.startTime}～${data.endTime}`}</Typography>
              <Box mt={1}>
                <Box fontWeight="fontWeightBold" fontSize={15}>内容</Box>
                <Box>{data.content && data.content.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
              </Box>
              <Box mt={1} display={data.teacher ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>講師</Box>
                <Typography variant="body2">{data.teacher}</Typography>
              </Box>
              <Box mt={1} display={data.fee ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>教材費</Box>
                <Typography variant="body2">{`${data.fee}円`}</Typography>
              </Box>
              <Box mt={1} display={data.annotation ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>特記事項</Box>
                <Typography variant="body2">{data.annotation}</Typography>
              </Box>
              <Box mt={1}>
                <Box fontWeight="fontWeightBold" fontSize={15}>Room</Box>
                <Typography variant="body2">{data.room}</Typography>
              </Box>
              <Box mt={1} display={data.receptPeriod <= 0 || (data.remaining === 0 && data.cancelWaitAccepted) ? 'none' : 'flex'}>
                <div className={classes.remainingRoot}>残</div>
                <div>{data.remaining}</div>
              </Box>
              <Box mt={1} display={data.remaining === 0 && data.cancelWaitAccepted ? '' : 'none'}>
                <Box className={classes.remainingRoot} width="fit-content">キャンセル待ち受付中</Box>
              </Box>
              <Box mt={1} display={data.receptPeriod <= 0 ? '' : 'none'}>
                <Box className={classes.remainingRoot} width="fit-content">受付終了</Box>
              </Box>
            </div>
          )}
        >
          <div className={isDayShow ? classes.card : null}>
            <Box display={isDayShow ? 'none' : ''}>
              <div
                className={(data.remaining <= 0 && !data.cancelWaitAccepted) ? classes.root : `${classes.root} ${classes.pointer}`}
                style={{ background: (data.remaining <= 0 && !data.cancelWaitAccepted) ? '#808080' : data.backColor }}
                onClick={(event) => this.onClick(event, data)}
                role="button"
                aria-hidden="true"
              >
                <Box display="flex">
                  <Typography variant="subtitle1" className={classes.content}>{`${data.startTime}～${data.endTime}`}</Typography>
                </Box>
                <Box align="left">
                  <Box fontWeight="fontWeightBold" fontSize={15} className={classes.content}>{data.menuName}</Box>
                </Box>
                <Box align="left" mt={1} display={data.receptPeriod > 0 ? '' : 'none'}>
                  <Box className={classes.content}>{`受付終了まで残り${this.getDispPeriod(data)}日`}</Box>
                </Box>
                <Box mt={1} display={data.receptPeriod <= 0 || (data.remaining === 0 && data.cancelWaitAccepted) ? 'none' : 'flex'}>
                  <div className={classes.remainingRoot}>残</div>
                  <div>{data.remaining}</div>
                </Box>
                <Box mt={1} display={data.remaining === 0 && data.cancelWaitAccepted ? '' : 'none'}>
                  <Box className={classes.remainingRoot} width="fit-content">キャンセル待ち受付中</Box>
                </Box>
                <Box align="left" mt={1} display={data.receptPeriod <= 0 ? '' : 'none'}>
                  <Box className={classes.remainingRoot} width="fit-content">受付終了</Box>
                </Box>
              </div>
            </Box>
            <Box display={isDayShow ? '' : 'none'}>
              <div
                onClick={(event) => this.onClick(event, data)}
                role="button"
                aria-hidden="true"
              >
                <Box display="flex">
                  <Typography variant="subtitle1" className={classes.content}>{`${data.startTime}～${data.endTime}`}</Typography>
                </Box>
                <Box align="left">
                  <Box fontWeight="fontWeightBold" fontSize={15} className={classes.content}>{data.menuName}</Box>
                </Box>
                <Box align="left" mt={1} display={data.receptPeriod > 0 ? '' : 'none'}>
                  <Box fontSize={12}>{`受付終了まで残り${this.getDispPeriod(data)}日`}</Box>
                </Box>
                <Box mt={1} display={data.receptPeriod <= 0 || (data.remaining === 0 && data.cancelWaitAccepted) ? 'none' : 'flex'}>
                  <div className={classes.remainingRoot}>残</div>
                  <div>{data.remaining}</div>
                </Box>
                <Box mt={1} display={data.remaining === 0 && data.cancelWaitAccepted ? '' : 'none'}>
                  <Box className={classes.remainingRoot} width="fit-content">キャンセル待ち受付中</Box>
                </Box>
                <Box mt={1} display={data.receptPeriod <= 0 ? '' : 'none'}>
                  <Box className={classes.remainingRoot} width="fit-content">受付終了</Box>
                </Box>
              </div>
            </Box>
          </div>
        </HtmlTooltip>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(BookingCard));
