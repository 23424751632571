import { ACTION_TYPE } from './action';

const top = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_INIT_TOP_SUCCESS:
      return {
        ...state,
        banners: action.banners,
      };
    default:
      return state;
  }
};

export default top;
