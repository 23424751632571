import { ACTION_TYPE } from './action';

// #region Reducer
const attendanceOnlineCulture = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET_ATTENDANCE_BOOKING_UPDATE_SUCCESS:
      return {
        ...state,
        attendanceBookingData: null,
        isAttendanceBookingDataUpdate: false,
      };
    case ACTION_TYPE.GET_ATTENDANCE_BOOKINGS_SUCCESS:
      return {
        ...state,
        attendanceBookingData: action.datas,
        isAttendanceBookingDataUpdate: true,
      };
    default:
      return state;
  }
};

export default attendanceOnlineCulture;
