/* eslint-disable import/prefer-default-export */
const ROOT_URL = 'https://api.enjoy-life-club.co.jp';
// const ROOT_URL = 'https://staging-dot-vertex-api-server.an.r.appspot.com';
// const ROOT_URL = 'http://localhost:8080';
export const COMMON = {
  BASE_URL: `${ROOT_URL}/member`,
  COMMON_URL: `${ROOT_URL}/common`,
  ROOT_URL: `${ROOT_URL}`,
  GUEST_URL: `${ROOT_URL}/guest`,
};
