// #region Type
export const ACTION_TYPE = {
  CALL_API_FORGOT_EMAIL: 'CALL_API_FORGOT_EMAIL',
  FORGOT_EMAIL_SUCCESS: 'FORGOT_EMAIL_SUCCESS',
  CALL_API_CHECK_EXPIRATION: 'CALL_API_CHECK_EXPIRATION',
  CHECK_EXPIRATION_SUCCESS: 'CHECK_EXPIRATION_SUCCESS',
  CALL_API_RESET_PASSWORD: 'CALL_API_RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_RESET_FLG: 'RESET_PASSWORD_RESET_FLG',
};
// #endregion

// #region action
export const actCallApiForgotEmail = (param) => ({
  type: ACTION_TYPE.CALL_API_FORGOT_EMAIL,
  param,
});

export const actForgotEmailSuccess = () => ({
  type: ACTION_TYPE.FORGOT_EMAIL_SUCCESS,
});

export const actCallApiCheckExpiration = (param) => ({
  type: ACTION_TYPE.CALL_API_CHECK_EXPIRATION,
  param,
});

export const actCheckExpirationSuccess = (param) => ({
  type: ACTION_TYPE.CHECK_EXPIRATION_SUCCESS,
  result: param,
});

export const actCallApiResetPassword = (param) => ({
  type: ACTION_TYPE.CALL_API_RESET_PASSWORD,
  param,
});

export const actResetPasswordSuccess = () => ({
  type: ACTION_TYPE.RESET_PASSWORD_SUCCESS,
});

export const actResetPasswordResetFlg = () => ({
  type: ACTION_TYPE.RESET_PASSWORD_RESET_FLG,
});
// #endregion
