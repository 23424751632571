import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Container,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Alert, AlertTitle } from '@material-ui/lab';
import TabPanel from '../../../../../components/atoms/tabPanel/TabPanel';
import LinkLabel from '../../../../../components/atoms/linkLabel/LinkLabel';
import DoingBookingTable from './DoingBookingTable';
import CancelWaitBookingTable from './CancelWaitBookingTable';
import MonthTitle from '../monthTitle/MonthTitle';
import AllBookingTable from './AllBookingTable';
import { actSetBookingSearchCondition, actCallApiGetBookings } from '../../../../../redux/mypage/mypage/action';
import { createBookingCondition } from '../../mypage-proc';

// #region
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  toggleButtonRoot: {
    color: '#000!important',
  },
  ul: {
    paddingInlineStart: '0px',
  },
  serachBox: {
    flex: '0 0 auto',
    padding: '8px',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  searchLeft: {
    flex: '1 1 25%',
  },
  contentRoot: {
    paddingLeft: '0',
  },
  alert: {
    margin: '8px 0px',
  },
  alertMessage: {
    display: 'flex',
    justifyContent: 'flexStart',
  },
});
// #endregion

class BookingList extends React.Component {
  constructor(props) {
    super(props);
    const condition = createBookingCondition(props);

    this.state = {
      condition,
    };
  }

  handleAlignment = (_, newValue) => {
    if (!newValue) {
      return;
    }
    const { condition } = this.state;
    condition.alignment = newValue;
    let reservationType = 3;
    if (newValue === 'booking') {
      reservationType = 1;
    }
    if (newValue === 'cancelWait') {
      reservationType = 2;
    }
    condition.reservationType = reservationType;
    this.props.dispatch(actSetBookingSearchCondition(condition));
    this.props.dispatch(actCallApiGetBookings(condition));
  }

  onContentClick = (_, id) => {
    const content = id === 0 ? null : id;
    const { condition } = this.state;
    condition.content = content;
    condition.page = 0;
    this.props.dispatch(actSetBookingSearchCondition(condition));
    this.props.dispatch(actCallApiGetBookings(condition));
  }

  handleDateChange = (_, values) => {
    const { condition } = this.state;
    condition.targetMonth = values;
    this.props.dispatch(actSetBookingSearchCondition(condition));
    this.props.dispatch(actCallApiGetBookings(condition));
  }

  handleChangePage = (_, newPage) => {
    const { condition } = this.state;
    condition.page = newPage;
    this.props.dispatch(actSetBookingSearchCondition(condition));
    this.props.dispatch(actCallApiGetBookings(condition));
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { condition } = this.state;
    condition.records = rowsPerPage;
    this.props.dispatch(actSetBookingSearchCondition(condition));
    this.props.dispatch(actCallApiGetBookings(condition));
  }

  // 残り予約可能コマ数
  setlimitText = (content, reserveInfo) => {
    const data = reserveInfo || { culLimits: 0, culReserves: 0 };
    const cultureId = 1;
    const remains = data.culLimits - data.culReserves <= 0 ? 0 : data.culLimits - data.culReserves;
    return content === cultureId || !content ? remains : 999;
  }

  setGolfText = (content, reserveInfo, isTargetAccepted) => {
    const data = reserveInfo;
    const golfId = 2;
    if (content !== golfId && content) return '';

    return this.createGolfLimitText(data, isTargetAccepted);
  }

  createGolfLimitText = (data, isTargetAccepted) => (
    <Box className={this.props.classes.alertMessage}>
      <Box display={data.freeLimit - data.freeReserves < 50 ? 'block' : 'none'}>
        シミュレーション：
        <strong>
          {data.freeLimit - data.freeReserves <= 0 ? 0 : data.freeLimit - data.freeReserves }
        </strong>
        コマ
      </Box>
      <Box display={data.lessonLimit - data.lessonReserves < 50 ? 'block' : 'none'} ml={2}>
        レッスン：
        <strong>
          {data.lessonLimit - data.lessonReserves <= 0 ? 0 : data.lessonLimit - data.lessonReserves}
        </strong>
        コマ
      </Box>
      { isTargetAccepted && (
        <Box display={data.targetLimit - data.targetReserves < 50 ? 'block' : 'none'} ml={2}>
          的打ち：
          <strong>
            {data.targetLimit - data.targetReserves <= 0
              ? 0 : data.targetLimit - data.targetReserves}
          </strong>
          コマ
        </Box>
      )}
    </Box>
  )

  render() {
    const { classes, value, index } = this.props;
    const {
      targetMonth, content, page, records, alignment,
    } = this.state.condition;
    const {
      bookingDatas, bookingContents, reserveInfo, isTargetAccepted,
    } = this.props.mypage;
    const cultureAlert = (!reserveInfo || this.setlimitText(content, reserveInfo) > 50)
    || (content && content !== 1);
    const golfAlert = (!reserveInfo || (reserveInfo.freeLimit - reserveInfo.freeReserves > 50
      && reserveInfo.lessonLimit - reserveInfo.lessonReserves > 50
      && reserveInfo.targetLimit - reserveInfo.targetReserves > 50))
      || (content && content !== 2);

    return (
      <TabPanel value={value} index={index} style={{ width: '100%' }}>
        <Box className={classes.root}>
          <Container className={classes.serachBox}>
            <Box className={classes.searchLeft}>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={this.handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="booking" aria-label="left aligned" className={classes.toggleButtonRoot}>
                  予約中
                </ToggleButton>
                <ToggleButton value="cancelWait" aria-label="left aligned" className={classes.toggleButtonRoot}>
                  キャンセル待ち
                </ToggleButton>
                <ToggleButton value="all" aria-label="right aligned" className={classes.toggleButtonRoot}>
                  全て
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Box display={bookingContents && bookingContents.length > 1 ? '' : 'none'}>
              <ul className={classes.ul}>
                <LinkLabel searchContent={content} id={0} onClick={this.onContentClick} isFirst />
                {bookingContents && bookingContents.map((bookingContent) => (
                  <LinkLabel
                    key={bookingContent.name}
                    searchContent={content}
                    targetContent={bookingContent.id}
                    id={bookingContent.id}
                    name={bookingContent.name}
                    onClick={this.onContentClick}
                  />
                ))}
              </ul>
            </Box>
          </Container>

          <Container className={classes.contentRoot}>
            <Box display={cultureAlert ? 'none' : ''} className={classes.alert}>
              <Alert severity="info">
                <AlertTitle>カルチャー事前予約可能数</AlertTitle>
                <strong>
                  {this.setlimitText(content, reserveInfo)}
                </strong>
                コマ
              </Alert>
            </Box>
            <Box display={golfAlert ? 'none' : ''} className={classes.alert}>
              <Alert severity="info">
                <AlertTitle>インドアゴルフ事前予約可能数</AlertTitle>
                {reserveInfo ? this.setGolfText(content, reserveInfo, isTargetAccepted) : ''}
              </Alert>
            </Box>
            <Box display={alignment === 'booking' ? '' : 'none'}>
              <DoingBookingTable
                datas={bookingDatas || []}
                page={page}
                records={records}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Box>
            <Box display={alignment === 'cancelWait' ? '' : 'none'}>
              <CancelWaitBookingTable
                datas={bookingDatas || []}
                page={page}
                records={records}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Box>
          </Container>

          <Box display={alignment === 'all' ? '' : 'none'}>
            <MonthTitle
              targetMonth={targetMonth}
              handleChange={this.handleDateChange}
            />

            <AllBookingTable
              datas={bookingDatas || []}
              page={page}
              records={records}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </TabPanel>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, mypage: state.mypage });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(BookingList)));
