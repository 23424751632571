// #region Type
export const ACTION_TYPE = {

  CALL_API_MYPAGE_COMMON_INFO: 'CALL_API_MYPAGE_COMMON_INFO', // マイページ共通情報
  CALL_API_MYPAGE_COMMON_INFO_SUCCESS: 'CALL_API_MYPAGE_COMMON_INFO_SUCCESS',

  RESET_MYPAGE_FLG: 'RESET_MYPAGE_FLG', // マイページで使用するフラグ

  SET_MYPAGE_TAB_VALUE: 'SET_MYPAGE_TAB_VALUE', // マイページメニュータブ

  CALL_API_CONTRACT: 'CALL_API_CONTRACT', // 契約情報
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',

  CALL_API_CHANGE_PASSWORD: 'CALL_API_CHANGE_PASSWORD', // パスワード変更
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',

  CALL_API_GET_REGULARS: 'CALL_API_GET_REGULARS', // 定期一覧
  GET_REGULARS_SUCCESS: 'GET_REGULARS_SUCCESS',

  SET_REGULAR_SEARCH_CONDITION: 'SET_REGULAR_SEARCH_CONDITION', // 定期検索条件設定

  CALL_API_UPDATE_REGULAR_STATUS: 'CALL_API_UPDATE_REGULAR_STATUS', // 定期ステータス更新
  UPDATE_REGULAR_STATUS_SUCCESS: 'UPDATE_REGULAR_STATUS_SUCCESS',

  CALL_API_GET_BOOKINGS: 'CALL_API_GET_BOOKINGS', // 予約一覧
  GET_BOOKINGS_SUCCESS: 'GET_BOOKINGS_SUCCESS',

  SET_BOOKING_SEARCH_CONDITION: 'SET_BOOKING_SEARCH_CONDITION', // 予約検索条件設定

  CALL_API_GET_BOOKING_CONTENTS: 'CALL_API_GET_BOOKING_CONTENTS', // 予約コンテンツ
  GET_BOOKING_CONTENTS_SUCCESS: 'GET_BOOKING_CONTENTS_SUCCESS',

  CALL_API_UPDATE_BOOKING_CANCEL: 'CALL_API_UPDATE_BOOKING_CANCEL', // 予約キャンセル
  UPDATE_BOOKING_CANCEL_SUCCESS: 'UPDATE_BOOKING_CANCEL_SUCCESS',

  CALL_API_UPDATE_BOOKING_CONFIRM: 'CALL_API_UPDATE_BOOKING_CONFIRM', // 予約確定
  UPDATE_BOOKING_CONFIRM_SUCCESS: 'UPDATE_BOOKING_CONFIRM_SUCCESS',

  GET_ZIP_CODE_ADDRESS: 'GET_ZIP_CODE_ADDRESS', // 郵便番号取得
  GET_ZIP_CODE_ADDRESS_SUCCESS: 'GET_ZIP_CODE_ADDRESS_SUCCESS',

  CALL_API_SELECT_VALUES: 'CALL_API_SELECT_VALUES', // 都道府県、性別取得
  CALL_API_SELECT_VALUES_SUCCESS: 'CALL_API_SELECT_VALUES_SUCCESS',

  CALL_API_CONTRACT_EDIT: 'CALL_API_CONTRACT_EDIT', // 契約者情報更新
  CALL_API_CONTRACT_EDIT_SUCCESS: 'CALL_API_CONTRACT_EDIT_SUCCESS',

  CALL_API_MEMBER_EDIT: 'CALL_API_MEMBER_EDIT', // 会員情報更新
  CALL_API_MEMBER_EDIT_SUCCESS: 'CALL_API_MEMBER_EDIT_SUCCESS',

  EDIT_FLG_RESET: 'EDIT_FLG_RESET', // フラグリセット
};
// #endregion

// #region マイページ共通情報
export const actCallApiGetCommonInfo = () => ({
  type: ACTION_TYPE.CALL_API_MYPAGE_COMMON_INFO,
});
export const actCallApiGetCommonInfoSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_MYPAGE_COMMON_INFO_SUCCESS,
  data,
});
// #endregion

// #region 契約更新
export const actCallApiContract = () => ({
  type: ACTION_TYPE.CALL_API_CONTRACT,
});

export const actGetContractSuccess = (datas) => ({
  type: ACTION_TYPE.GET_CONTRACT_SUCCESS,
  datas,
});
// #endregion

// #region パスワード更新
export const actCallApiChangePassword = (password) => ({
  type: ACTION_TYPE.CALL_API_CHANGE_PASSWORD,
  password,
});
export const actUpadetePasswordSuccess = () => ({
  type: ACTION_TYPE.UPDATE_PASSWORD_SUCCESS,
});
// #endregion

// #region マイページフラグ
export const actMypageResetFlg = () => ({
  type: ACTION_TYPE.RESET_MYPAGE_FLG,
});
// #endregion

// #region 定期タブ
export const actCallApiGetRegulars = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_REGULARS,
  param,
});

export const actGetRegularsSuccess = (datas) => ({
  type: ACTION_TYPE.GET_REGULARS_SUCCESS,
  datas,
});

export const actSetRegularSearchCondition = (condition) => ({
  type: ACTION_TYPE.SET_REGULAR_SEARCH_CONDITION,
  condition,
});

export const actCallApiUpdateRegularStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_REGULAR_STATUS,
  param,
});

export const actUpdateRegularStatusSuccess = () => ({
  type: ACTION_TYPE.UPDATE_REGULAR_STATUS_SUCCESS,
});

// #endregion

// #region マイページメニュータブ
export const actSetMypageTabValue = (value) => ({
  type: ACTION_TYPE.SET_MYPAGE_TAB_VALUE,
  value,
});
// #endregion

// #region 予約タブ
export const actCallApiGetBookings = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKINGS,
  param,
});

export const actGetBookingsSuccess = (datas) => ({
  type: ACTION_TYPE.GET_BOOKINGS_SUCCESS,
  datas,
});

export const actSetBookingSearchCondition = (condition) => ({
  type: ACTION_TYPE.SET_BOOKING_SEARCH_CONDITION,
  condition,
});

export const actCallApiGetBookingContents = () => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKING_CONTENTS,
});

export const actGetBookingContentsSuccess = (datas) => ({
  type: ACTION_TYPE.GET_BOOKING_CONTENTS_SUCCESS,
  datas,
});

export const actCallApiUpdateBookingCancel = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_BOOKING_CANCEL,
  param,
});

export const actUpdateBookingCancelSuccess = (bookingCancelResult) => ({
  type: ACTION_TYPE.UPDATE_BOOKING_CANCEL_SUCCESS,
  bookingCancelResult,
});

export const actCallApiUpdateBookingConfirm = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_BOOKING_CONFIRM,
  param,
});

export const actUpdateBookingConfirmSuccess = (bookingConfirmResult) => ({
  type: ACTION_TYPE.UPDATE_BOOKING_CONFIRM_SUCCESS,
  bookingConfirmResult,
});
// #endregion

// #region 郵便番号取得
export const getZipCodeAddress = (contidion) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS,
  contidion,
});

export const getZipCodeAddressSuccess = (zipcode) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS_SUCCESS,
  zipcode,
});
// #endregion

// #region 都道府県、性別取得
export const actCallApiSelectValues = () => ({
  type: ACTION_TYPE.CALL_API_SELECT_VALUES,
});

export const actCallApiSelectValuesSuccess = (selectValues) => ({
  type: ACTION_TYPE.CALL_API_SELECT_VALUES_SUCCESS,
  selectValues,
});
// #endregion

// #region 契約者情報更新
export const actCallApiContractEdit = (contract) => ({
  type: ACTION_TYPE.CALL_API_CONTRACT_EDIT,
  contract,
});

export const actCallApiContractEditSuccess = () => ({
  type: ACTION_TYPE.CALL_API_CONTRACT_EDIT_SUCCESS,
});
// #endregion

// #region 会員情報更新
export const actCallApiMemberEdit = (member) => ({
  type: ACTION_TYPE.CALL_API_MEMBER_EDIT,
  member,
});

export const actCallApiMemberEditSuccess = () => ({
  type: ACTION_TYPE.CALL_API_MEMBER_EDIT_SUCCESS,
});
// #endregion

// #region フラグリセット
export const actEditFlgReset = () => ({
  type: ACTION_TYPE.EDIT_FLG_RESET,
});
// #endregion
