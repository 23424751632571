import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * マイページ共通情報取得
 * @returns
 */
export default function getNotices() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.NOTICE_LIST.NOTICES)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
