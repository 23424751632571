import {
  ACTION_TYPE,
} from './action';

const surveyDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_SURVEY_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        completeData: action.data,
        isUpdateSuccess: true,
      };
    case ACTION_TYPE.RESET_SURVEY_DETAIL_FLG:
      return {
        ...state,
        isUpdateSuccess: false,
      };
    default:
      return state;
  }
};

export default surveyDetail;
