import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SideDisplayGrid from '../../../../../components/organisms/sideDisplayGrid/SideDisplayGrid';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  accordionRoot: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginTop: '20px',
  },
});

class ContractInfoTable extends React.Component {
  contractClick = () => {
    this.props.onClickContract();
  };

  memberClick = () => {
    this.props.onClickMember();
  };

  render() {
    const { classes, data } = this.props;
    return (
      <Box className={classes.root}>
        <div>
          <Grid container spacing={0}>
            <SideDisplayGrid title="ご利用店舗" content={data.storeName} />
          </Grid>
          <Accordion defaultExpanded className={classes.accordionRoot}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">ご契約者様情報</Box>
            </AccordionSummary>
            <Box textAlign="right" mb={2} mr={2}>
              <Button variant="contained" color="primary" onClick={this.contractClick}>編集</Button>
            </Box>
            <AccordionDetails>
              <Grid container spacing={0}>
                <SideDisplayGrid title="法人名" content={data.contract.corporationName} isNotShow={!data.contract.corporationName} />
                <SideDisplayGrid title="氏名" content={`${data.contract.lastName} ${data.contract.firstName}`} />
                <SideDisplayGrid title="氏名カナ" content={`${data.contract.lastNameKana} ${data.contract.firstNameKana}`} />
                <SideDisplayGrid title="郵便番号" content={data.contract.zipCode} />
                <SideDisplayGrid title="都道府県" content={data.contract.prefecture} />
                <SideDisplayGrid title="市区町村" content={data.contract.city} />
                <SideDisplayGrid title="町名・番地・その他" content={data.contract.street} />
                <SideDisplayGrid title="メールアドレス" content={data.contract.email} />
                <SideDisplayGrid title="電話番号" content={data.contract.phone} />
                <SideDisplayGrid title="電話番号2" content={data.contract.phoneTwo} />
                <SideDisplayGrid title="生年月日" content={data.contract.formatBirthday} />
                <SideDisplayGrid title="性別" content={data.contract.gender} />
                <SideDisplayGrid title="緊急連絡先氏名" content={`${data.contract.emergencyLastName} ${data.contract.emergencyFirstName}`} />
                <SideDisplayGrid title="緊急連絡先電話番号" content={data.contract.emergencyPhone} />
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionRoot} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">ご利用者様情報</Box>
            </AccordionSummary>
            <Box textAlign="right" mb={2} mr={2}>
              <Button variant="contained" color="primary" onClick={this.memberClick}>編集</Button>
            </Box>
            <AccordionDetails>
              <Grid container spacing={0}>
                <SideDisplayGrid title="会員番号" content={data.member.memberNo} />
                <SideDisplayGrid title="氏名" content={`${data.member.lastName} ${data.member.firstName}`} />
                <SideDisplayGrid title="氏名カナ" content={`${data.member.lastNameKana} ${data.member.firstNameKana}`} />
                <SideDisplayGrid title="郵便番号" content={data.member.zipCode} />
                <SideDisplayGrid title="都道府県" content={data.member.prefecture} />
                <SideDisplayGrid title="市区町村" content={data.member.city} />
                <SideDisplayGrid title="町名・番地・その他" content={data.member.street} />
                <SideDisplayGrid title="メールアドレス" content={data.member.email} />
                <SideDisplayGrid title="電話番号" content={data.member.phone} />
                <SideDisplayGrid title="電話番号2" content={data.member.phoneTwo} />
                <SideDisplayGrid title="生年月日" content={data.member.formatBirthday} />
                <SideDisplayGrid title="性別" content={data.member.gender} />
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordionRoot} defaultExpanded style={{ display: data.contractList.length > 0 ? '' : 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">ご契約情報</Box>
            </AccordionSummary>
            {data.contractList.map((contractDetail, loopIndex) => (
              <Box key={`contract-detail-${loopIndex + 1}`} mt={1}>
                <Box fontWeight="fontWeightBold" ml={2}>{`ご契約情報${loopIndex + 1}`}</Box>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <SideDisplayGrid title="契約名" content={contractDetail.productName} />
                    <SideDisplayGrid title="契約開始日" content={contractDetail.startedOn} />
                    <SideDisplayGrid title="契約終了日" content={contractDetail.endedOn} />
                    <SideDisplayGrid isNotShow={!contractDetail.lockerLocation} title="ロッカー場所" content={contractDetail.lockerLocation} />
                    <SideDisplayGrid isNotShow={!contractDetail.pinCode} title="暗証番号" content={contractDetail.pinCode} />
                    <SideDisplayGrid isNotShow={!contractDetail.lockerNumber} title="ロッカー番号" content={contractDetail.lockerNumber} />
                  </Grid>
                </AccordionDetails>
              </Box>
            ))}
          </Accordion>
        </div>
      </Box>
    );
  }
}

export default withStyles(styles)(withRouter(ContractInfoTable));
