import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

class ExtendedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'yyyy年MMMM', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'MMMMd日', { locale: this.locale });
  }
}

export default function Datepicker(props) {
  const {
    label,
    name,
    value,
    onChange,
    disableToolbar,
    variant,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <KeyboardDatePicker
        views={['date']}
        autoComplete="off"
        inputVariant="outlined"
        variant={variant}
        format="yyyy/MM/dd"
        id="date-picker-inline"
        value={value}
        minDate={new Date()}
        onChange={(_, selectValue) => {
          onChange({
            target: {
              name,
              value: selectValue,
            },
          });
        }}
        label={label}
        autoOk
        disableToolbar={disableToolbar}
        helperText=""
      />
    </MuiPickersUtilsProvider>
  );
}

Datepicker.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disableToolbar: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

Datepicker.defaultProps = {
  label: null,
  value: null,
};
