import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { isValid, format } from 'date-fns';
import {
  Button, Paper, Box, Container, withStyles,
} from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import Teacher from '../../../public/assets/top/teacher.svg';
import Contents from '../../../public/assets/top/contents.svg';
import Building from '../../../public/assets/top/building.svg';
import getStyle from './top-design';
import redirectLinks from '../../../constants/redirectLinks';
import { actCallApiInitTop } from '../../../redux/other/top/action';
import { actCallApiGetBookingSelect } from '../../../redux/booking/bookingList/action';
import SearchSelectBox from '../../../components/atoms/searchSelectBox/SearchSelectBox';
import ErrorDialog from '../../../components/templates/errorDialog/ErrorDialog';

const styles = getStyle();

class Top extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.common;

    this.scrollToTop();

    props.dispatch(actCallApiInitTop());
    let userId = null;
    if (user && user.user) {
      userId = this.props.common.user.user.id;
    }

    props.dispatch(actCallApiGetBookingSelect(userId));
    this.state = {
      storeSelectedValue: null,
      contentSelectedValue: null,
      storeSelectedDetailValue: null,
      bookingDate: null,
      errorOpen: false,
      errorMessage: null,
      contents: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.common.user !== this.props.common.user) {
      if (this.props.common.user) {
        this.props.dispatch(actCallApiGetBookingSelect(this.props.common.user.user.id));
      }
    }
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onSearchClick = () => {
    const { storeSelectedValue, contentSelectedValue } = this.state;
    let errorMessage = '';
    if (!storeSelectedValue) {
      errorMessage += '店舗';
    }
    if (!contentSelectedValue) {
      errorMessage += errorMessage === '' ? 'コンテンツ' : '、コンテンツ';
    }
    if (errorMessage !== '') {
      this.setState({ errorOpen: true, errorMessage: [`${errorMessage}を選択してください`] });
      return;
    }
    this.props.history.push({
      pathname: redirectLinks.BOOKING,
      state: {
        storeValue: storeSelectedValue,
        contentValue: contentSelectedValue,
      },
    });
  }

  onDetailSearchClick = () => {
    const message = [];
    const { storeSelectedDetailValue, bookingDate } = this.state;
    if (!storeSelectedDetailValue) {
      message.push('店舗を選択してください');
    }
    if (!bookingDate) {
      message.push('予約日を入力してください');
    }
    const result = isValid(new Date(bookingDate));
    if (bookingDate && !result) {
      message.push('予約日を正しく入力してください');
    }
    const today = format(new Date(), 'yyyy/MM/dd');
    if (bookingDate && bookingDate < today) {
      message.push('予約日は当日以降の値を設定してください');
    }

    if (message.length > 0) {
      this.setState({ errorOpen: true, errorMessage: message });
      return;
    }
    this.props.history.push({
      pathname: redirectLinks.BOOKING,
      state: {
        storeValue: storeSelectedDetailValue,
        bookingDate,
      },
    });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { contents, contentSelectedValue } = this.state;
    let newContents = contents;
    let newContentSelectedValue = contentSelectedValue;
    if (name === 'storeSelectedValue') {
      newContents = this.getContents(value.id);
      newContentSelectedValue = null;
    }
    if (name === 'contentSelectedValue') {
      newContentSelectedValue = value;
      this.setState({
        contentSelectedValue: value,
        contents: newContents,
      });
    }
    if (name !== 'contentSelectedValue') {
      this.setState({
        [name]: value,
        contents: newContents,
        contentSelectedValue: newContentSelectedValue,
      });
    }
  }

  getContents = (storeId) => {
    const links = this.props.bookingList.selects.contentLinks || [];
    const allContents = this.props.bookingList.selects.contents || [];

    return links
      .filter((link) => link.mstStoreId === storeId)
      .map((link) => {
        const targetContent = allContents.find((row) => row.id === link.mstContentId);
        return targetContent ? { id: targetContent.id, name: targetContent.name } : null;
      })
      .filter((content) => content !== null);
  };

  handleErrorDialogClose = () => {
    this.setState({ errorOpen: false });
  }

  render() {
    const {
      classes,
    } = this.props;

    const banners = this.props.top.banners || [];
    const stores = this.props.bookingList.selects ? this.props.bookingList.selects.stores : [];

    const {
      storeSelectedValue,
      contentSelectedValue,
      errorOpen,
      errorMessage,
      contents,
    } = this.state;

    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div>
        <Header />
        <Container maxWidth={false} className={classes.root}>
          <div
            className={`${classes.searchRoot} ${classes.searchImg}`}
            style={{ background: banners.length > 0 && `url(${banners.filter((row) => row.main)[0].url})`, backgroundColor: '#EEF5C9' }}
          >
            <div className={classes.searchCard}>
              <Paper className={classes.serachTitleRoot}>
                <div className={classes.searchTitle}>
                  <Box>&quot;アタマ&quot;をきたえ、&quot;カラダ&quot;を動かす</Box>
                  <Box>豊富なメニューを検索</Box>
                </div>
              </Paper>
              <Paper className={classes.searchCol}>
                <Box className={classes.searchContent}>
                  <Box m={1}>
                    <SearchSelectBox
                      options={stores}
                      classes={classes.searchSelect}
                      onChange={this.onChange}
                      name="storeSelectedValue"
                      label="店舗名"
                      value={storeSelectedValue}
                      disableClearable
                      id="store-select"
                    />
                  </Box>
                  <Box m={1}>
                    <SearchSelectBox
                      options={contents || []}
                      classes={classes.searchSelect}
                      onChange={this.onChange}
                      name="contentSelectedValue"
                      label="コンテンツ名"
                      value={contentSelectedValue}
                      disableClearable
                      id="content-select"
                    />
                  </Box>
                </Box>
                <Box m={1}>
                  <Button
                    onClick={this.onSearchClick}
                    variant="contained"
                    color="primary"
                  >
                    検索する
                  </Button>
                </Box>
              </Paper>
            </div>
          </div>
          <Container>
            <Container maxWidth={false}>
              <Box my="5rem">
                <Slider {...settings}>
                  {banners && banners.filter((row) => !row.main).map((banner) => (
                    <div key={banner.id}>
                      <img
                        src={banner.url}
                        alt="1"
                        className={classes.bannerImg}
                      />
                    </div>
                  ))}
                </Slider>
              </Box>
            </Container>
            <Box my="5rem" className={classes.featureArea}>
              <div className={classes.featureTitle}>
                <span className={classes.featureTitleOne}>
                  ENJOY LIF
                  <span className={classes.mainColor}>E</span>
                  {' CLUBの'}
                </span>
                <span className={classes.featureTitleTwo}>
                  <span className={classes.featureTitleThree}>3</span>
                  つの特徴
                </span>
              </div>
              <div className={classes.featureCaption}>
                エンジョイライフクラブは
                <br className={classes.spShow} />
                大人から子供まで楽しんで頂ける
                <br />
                豊富なサービス、プランをご用意しています。
              </div>
              <div className={classes.featureCardRoot}>
                <div className={classes.featureCard}>
                  <div>
                    <h3 className={classes.featureCardTitle}>実績豊富な講師陣</h3>
                    <img src={Teacher} alt="講師陣" className={classes.featureImg} />
                    <div className={classes.featureCardCaption}>
                      確かな経験と豊富な実績を
                      <br />
                      併せもつ講師陣が
                      <br />
                      指導を行います。
                    </div>
                  </div>
                </div>
                <div className={classes.featureCard}>
                  <div>
                    <h3 className={classes.featureCardTitle}>豊富なコンテンツ</h3>
                    <img src={Contents} alt="コンテンツ" className={classes.featureImg} />
                    <div className={classes.featureCardCaption}>
                      あなたの生活をより豊かにする
                      <br />
                      豊富なコンテンツを
                      <br />
                      ご用意しています。
                    </div>
                  </div>
                </div>
                <div className={classes.featureCard}>
                  <div>
                    <h3 className={classes.feature_card_title}>充実な施設</h3>
                    <img src={Building} alt="施設" className={classes.featureImg} />
                    <div className={classes.featureCardCaption}>
                      &quot;アタマ&quot;や&quot;カラダ&quot;を
                      <br />
                      鍛え、癒す、
                      <br />
                      施設・設備が充実！
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.featureCardRootSp}>
                <div className={`${classes.featureCard} ${classes.featureCardTopSp}`}>
                  <div>
                    <h3 className={classes.featureCardTitle}>実績豊富な講師陣</h3>
                    <img src={Teacher} alt="講師陣" className={classes.featureImg} />
                    <div className={classes.featureCardCaption}>
                      確かな経験と豊富な実績を
                      <br />
                      併せもつ講師陣が
                      <br />
                      指導を行います。
                    </div>
                  </div>
                </div>
                <div className={classes.featureCardSecondSp}>
                  <div className={classes.featureCard}>
                    <div>
                      <h3 className={classes.featureCardTitle}>豊富なコンテンツ</h3>
                      <img src={Contents} alt="コンテンツ" className={classes.featureImg} />
                      <div className={classes.featureCardCaption}>
                        あなたの生活をより豊かにする
                        <br />
                        豊富なコンテンツを
                        <br />
                        ご用意しています。
                      </div>
                    </div>
                  </div>
                  <div className={classes.featureCard}>
                    <div>
                      <h3 className={classes.featureCardTitle}>充実な施設</h3>
                      <img src={Building} alt="施設" className={classes.featureImg} />
                      <div className={classes.featureCardCaption}>
                        &quot;アタマ&quot;や&quot;カラダ&quot;を
                        <br />
                        鍛え、癒す、
                        <br />
                        施設・設備が充実！
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Box className={classes.featureButtonArea}>
                <Button size="large" className={classes.featureButton} href="https://www.vertex-group.co.jp/elc/">
                  <span>詳しくはこちら</span>
                  <ArrowForwardIosIcon className={classes.btnIcon} />
                </Button>
              </Box>
            </Box>
          </Container>
        </Container>
        <Footer />
        <ErrorDialog
          open={errorOpen}
          handleClose={this.handleErrorDialogClose}
          messages={errorMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common, top: state.top, bookingList: state.bookingList,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Top)));
