import React from 'react';
import {
  Step,
  Stepper,
  withStyles,
  makeStyles,
  StepLabel,
  StepConnector,
} from '@material-ui/core';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      background: '#62D298',
    },
  },
  completed: {
    '& $line': {
      background: '#62D298',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    background: '#62D298',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: '#62D298',
  },
});

function ColorlibStepIcon(elementProps) {
  const {
    active, completed, icon,
  } = elementProps;
  const classes = useColorlibStepIconStyles();

  const icons = {
    1: <PersonIcon />,
    2: <DateRangeIcon />,
    3: <GroupAddIcon />,
    4: <LocalLibraryIcon />,
    5: <HowToRegIcon />,
  };
  const iconIndex = icon;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(iconIndex)]}
    </div>
  );
}

export default function CustomizedSteppers(props) {
  const { activeStep } = props;
  const steps = ['お客様情報の確認', '日時・選択メニューの確認', '予約内容の確認', '予約完了'];
  const classes = useStyles();

  return (
    <Stepper
      alternativeLabel
      classes={{
        root: classes.root,
      }}
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(elementProps) => ColorlibStepIcon(elementProps, props)}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
