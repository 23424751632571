import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import redirectLinks from '../../../../../constants/redirectLinks';

class CompleteBooking extends Component {
  onBackClick = () => {
    this.props.history.push(redirectLinks.BOOKING);
  }

  onNextClick = () => {
    this.props.history.push(redirectLinks.MYPAGE);
  }

  render() {
    return (
      <Box my={{ xs: 3, sm: 2, md: 0 }}>
        <Box fontWeight="fontWeightBold" m={1} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} textAlign="center">キャンセル待ち登録が完了しました。</Box>

        <Box mt={5} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} fontWeight="fontWeightBold" textAlign="center">
          キャンセルが出た場合はメールにてお知らさせていただきますので、
        </Box>
        <Box mt={1} fontSize={{ xs: '15px', sm: 'h6.fontSize' }} fontWeight="fontWeightBold" textAlign="center">
          マイページにてご予約の確定をしてください。
        </Box>
        <Box my={5} align="center">
          <Button variant="contained" className="custom-button" onClick={this.onBackClick}>続けて予約する</Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={this.onNextClick}>マイページを確認する</Button>
        </Box>
      </Box>
    );
  }
}

export default (withRouter(CompleteBooking));
