import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import store from '../store/store';
import Main from './other/top/Top';
import '../public/css/style.css';
import Auth from './common/Auth';
import Login from './other/login/Login';
import BookingList from './booking/bookingList/BookingList';
import EntryDescription from './entry/entryDescription/EntryDescription';
import BookingDetail from './booking/bookingDetail/BookingDetail';
import BookingDetailCancelWait from './booking/bookingDetailCancelWait/BookingDetailCancelWait';
import MyPage from './mypage/mypage/MyPage';
import IndividualEntry from './entry/individualEntry/IndividualEntry';
import CorporationEntry from './entry/corporationEntry/CorporationEntry';
import CompleteEntry from './entry/entryForm/complete/CompleteEntry';
import redirectLinks from '../constants/redirectLinks';
import MyPageBookingDetail from './mypage/mypageBookingDetail/MyPageBookingDetail';
import NotFound from './other/notFound/NotFound';
import TransferRegist from './mypage/transferRegist/TransferRegist';
import PointHistory from './mypage/pointHistory/PointHistory';
import ForgotPassword from './other/resetPassword/ForgotPassword';
import ResetPassword from './other/resetPassword/ResetPassword';
import SystemError from './other/systemError/SystemError';
import SurveyDetail from './mypage/surveyDetail/SurveyDetail';
import AttendanceOnlineCulture from './mypage/attendanceOnlineCulture/AttendanceOnlineCulture';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#c0d434',
      main: '#B1CA02',
      dark: '#7b8d01',
      contrastText: '#000',
    },
    secondary: {
      light: '#81dbac',
      main: '#62D298',
      dark: '#44936a',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: '600',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={createHashHistory()}>
          <Auth>
            <Switch>
              <Route exact path={redirectLinks.BASE} component={Main} />
              <Route exact path={redirectLinks.LOGIN} component={Login} />
              <Route exact path={redirectLinks.ENTRY_DESCRIPTION} component={EntryDescription} />
              <Route exact path={redirectLinks.MYPAGE} component={MyPage} />
              <Route exact path={redirectLinks.INDIVIDUAL_ENTRY} component={IndividualEntry} />
              <Route exact path={redirectLinks.CORPORATION_ENTRY} component={CorporationEntry} />
              <Route exact path={redirectLinks.TRANSFER_REGIST} component={TransferRegist} />
              <Route exact path={redirectLinks.BOOKING} component={BookingList} />
              <Route exact path={redirectLinks.BOOKING_DETAIL} component={BookingDetail} />
              <Route
                exact
                path={redirectLinks.BOOKING_DETAIL_CANCEL_WAITE}
                component={BookingDetailCancelWait}
              />
              <Route exact path={redirectLinks.COMPLETE_ENTRY} component={CompleteEntry} />
              <Route exact path={redirectLinks.POINT_HISTORY} component={PointHistory} />
              <Route
                exact
                path={redirectLinks.MYPAGE_BOOKING_DETAIL}
                component={MyPageBookingDetail}
              />
              <Route exact path={redirectLinks.SURVEY_DETAIL} component={SurveyDetail} />
              <Route
                path={redirectLinks.ATTENDANCE_ONLINE_CULTURE}
                component={AttendanceOnlineCulture}
              />
              <Route exact path={redirectLinks.FORGOT_PASSWORD} component={ForgotPassword} />
              <Route exact path={redirectLinks.RESET_PASSWORD} component={ResetPassword} />
              <Route exact path={redirectLinks.ERROR} component={SystemError} />
              <Route component={NotFound} />
            </Switch>
          </Auth>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
