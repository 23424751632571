import {
  isAfter, subDays, subMinutes, differenceInDays, format, isEqual, isBefore,
} from 'date-fns';

export function checkDoCancel(row) {
  const {
    memberStatus, targetedOn, startTime, cancelDatetime, contentId, bookingStatus,
  } = row;
  if ((memberStatus !== 5 && memberStatus !== 10) || bookingStatus === 2) {
    return false;
  }
  const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
  let targetNewDate = subDays(new Date(`${targetedOn}`), cancelDatetime);
  let result = differenceInDays(targetNewDate, today) >= 0;
  if (contentId === '2') {
    targetNewDate = subMinutes(new Date(`${targetedOn} ${startTime}`), cancelDatetime);
    result = isAfter(targetNewDate, today) || isEqual(targetNewDate, today);
  }
  return result;
}

export function checkOnlineCulture(row) {
  const {
    memberStatus, targetedOn, startTime, endTime,
    contentId, bookingStatus, possibleEnterNumber,
  } = row;
  if (contentId !== '17' || (memberStatus !== 5 && memberStatus !== 7) || bookingStatus !== 0) {
    return false;
  }

  const targetNewDate = subMinutes(new Date(`${targetedOn} ${startTime}`), possibleEnterNumber);
  const targetEndDate = new Date(`${targetedOn} ${endTime}`);
  const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
  const result = (isAfter(today, targetNewDate) && isBefore(today, targetEndDate))
              || isEqual(targetNewDate, today);
  return result;
}
