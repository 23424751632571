import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container, Box,
} from '@material-ui/core';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import Loading from '../../../components/atoms/loading/Loading';
import redirectLinks from '../../../constants/redirectLinks';
import {
  actCallApiGetBookingDetailCancelWait,
  actCallApiCreateBookingCancelWait,
  actResetCreateBookingCancelWaitFlg,
} from '../../../redux/booking/bookingDetailCancelWait/action';
import BookingDisplayDetail from './components/bookingDisplayDetail/BookingDisplayDetail';
import BookingDetailError from './components/bookingDetailError/BookingDetailError';
import CompleteBooking from './components/completeBooking/CompleteBooking';

class BookingDetail extends Component {
  constructor(props) {
    super(props);
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    document.cookie = 'b_fi=; path=/; max-age=0';
    document.cookie = 'b_ft=; path=/; max-age=0';
    document.cookie = 'b_fc=; path=/; max-age=0';
    document.cookie = 'b_fk=; path=/; max-age=0';

    let bookingId = null;
    let golfTargetType = '';
    let contentId = null;
    let isError = false;

    if (props.location.state && props.location.state.bookingId) {
      const locationState = props.location.state;
      bookingId = locationState.bookingId;
      if (locationState.golfTargetType) {
        golfTargetType = locationState.golfTargetType;
      }
      contentId = locationState.contentId;
    }

    if (props.location.state && !props.location.state.bookingId) {
      this.props.history.push({
        pathname: redirectLinks.BOOKING,
      });
      isError = true;
    }

    if (!token) {
      this.props.history.push({
        pathname: redirectLinks.LOGIN,
        state: {
          bookingId,
          golfTargetType,
          contentId,
        },
      });
      document.cookie = `b_fi=${bookingId}; path=/; max-age=3600`;
      document.cookie = `b_ft=${golfTargetType}; path=/; max-age=3600`;
      document.cookie = `b_fc=${contentId}; path=/; max-age=3600`;
      document.cookie = 'b_fk=1; path=/; max-age=3600';
      isError = true;
    }

    this.state = {
      key: {
        bookingId,
        golfTargetType,
        contentId,
      },
      bookingData: {
        data: {},
        errorMessages: [],
      },
      isLoading: false,
      activeIndex: 0,
      isComplete: false,
      completeData: {
        errorMessages: [],
      },
    };
    if (!isError) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingDetailCancelWait.data !== this.props.bookingDetailCancelWait.data) {
      if (this.props.bookingDetailCancelWait.data) {
        this.setData(this.props.bookingDetailCancelWait.data);
      }
    }
    if (prevProps.bookingDetailCancelWait.isCreateSuccess
      !== this.props.bookingDetailCancelWait.isCreateSuccess) {
      if (this.props.bookingDetailCancelWait.isCreateSuccess) {
        this.setComplete(this.props.bookingDetailCancelWait.successData);
      }
    }
  }

  getData() {
    const { key } = this.state;

    this.props.dispatch(
      actCallApiGetBookingDetailCancelWait({
        bookingId: key.bookingId,
        golfTargetType: key.golfTargetType || null,
        contentId: key.contentId,
      }),
    );
  }

  setData(bookingData) {
    this.setState({ isLoading: false, bookingData });
  }

  onClickComplete = () => {
    this.scrollToTop();
    const { key, bookingData } = this.state;
    this.props.dispatch(actResetCreateBookingCancelWaitFlg());

    this.props.dispatch(
      actCallApiCreateBookingCancelWait({
        bookingId: key.bookingId,
        storeId: bookingData.data.storeId,
        golfTargetType: key.golfTargetType || null,
        contentId: key.contentId,
      }),
    );
    this.setState({ isLoading: true });
  }

  setComplete(completeData) {
    this.setState({ isLoading: false, isComplete: true, completeData });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onBookingListBackClick = () => {
    this.props.history.push(redirectLinks.BOOKING);
  }

  render() {
    const {
      isLoading, bookingData, activeIndex,
      isComplete, completeData,
    } = this.state;

    return (
      <div>
        <Loading open={isLoading} />
        <Header />
        <Container>
          <Box display={bookingData && bookingData.errorMessages.length === 0 && !isComplete ? '' : 'none'}>
            <Box display={activeIndex === 0 ? '' : 'none'}>
              <BookingDisplayDetail
                data={bookingData.data}
                onBookingListBackClick={this.onBookingListBackClick}
                onClickComplete={this.onClickComplete}
              />
            </Box>
          </Box>
          <Box display={bookingData && bookingData.errorMessages.length > 0 ? '' : 'none'}>
            <BookingDetailError
              errors={bookingData.errorMessages}
              onBookingListBackClick={this.onBookingListBackClick}
            />
          </Box>
          <Box display={completeData && completeData.errorMessages.length > 0 ? '' : 'none'}>
            <BookingDetailError
              errors={completeData.errorMessages}
              onBookingListBackClick={this.onBookingListBackClick}
            />
          </Box>
          <Box display={isComplete && completeData.errorMessages.length === 0 ? '' : 'none'}>
            <CompleteBooking
              data={completeData.data}
            />
          </Box>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  bookingDetailCancelWait: state.bookingDetailCancelWait,
});

export default (withRouter(connect(mapStateToProps)(BookingDetail)));
