import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Grid,
  Paper,
  withStyles,
  Typography,
  TextField,
  Box,
  Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import redirectLinks from '../../../constants/redirectLinks';
import { actCallApiLogin, actLoginDataReset } from '../../../redux/other/login/action';
import getStyle from './login-design';
import { actCallApiMe } from '../../../redux/common/action';
import Loading from '../../../components/atoms/loading/Loading';

const styles = getStyle();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(actLoginDataReset());

    let isFromBooking = false;
    if (props.location.state && props.location.state.bookingId) {
      isFromBooking = true;
    }

    this.state = {
      loginId: '',
      password: '',
      message: '',
      isLoading: false,
      isFromBooking,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.login !== this.props.login) {
      let token = '';
      let message = '';
      if (this.props.login.isError === false
        && !this.props.login.token.notLogin) {
        token = this.props.login.token.token;
        document.cookie = `mjwt=${token}; path=/; max-age=86400`;
        this.goBack();
      }
      if (this.props.login.isError === false
        && this.props.login.token.notLogin) {
        this.onLoading(false);
        message = '店舗にご来店の上、本登録を完了後、ご利用が可能になります。';
      }
      if (this.props.login.isError === true) {
        this.onLoading(false);
        message = 'メールアドレスまたはパスワードが間違っています';
      }
      this.setMessage(message);
    }
  }

  goBack = () => {
    this.props.dispatch(actCallApiMe());

    const ref = document.referrer;
    const host = window.location.hostname;

    const rExp = new RegExp(host, 'i');

    const bookingId = document.cookie.replace(/(?:(?:^|.*;\s*)b_fi\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const golfTargetType = document.cookie.replace(/(?:(?:^|.*;\s*)b_ft\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const contentId = document.cookie.replace(/(?:(?:^|.*;\s*)b_fc\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const cancelWaitFlg = document.cookie.replace(/(?:(?:^|.*;\s*)b_fk\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const bookingCode = document.cookie.replace(/(?:(?:^|.*;\s*)c_fz\s*=\s*([^;]*).*$)|^.*$/, '$1');

    let url = redirectLinks.BOOKING_DETAIL;
    if (cancelWaitFlg === '1') {
      url = redirectLinks.BOOKING_DETAIL_CANCEL_WAITE;
    }

    if (this.props.location.state && this.props.location.state.bookingId) {
      const locationState = this.props.location.state;

      this.props.history.push({
        pathname: url,
        state: {
          bookingId: locationState.bookingId,
          golfTargetType: locationState.golfTargetType || null,
          contentId: locationState.contentId,
        },
      });
    } else if (bookingId) {
      this.props.history.push({
        pathname: url,
        state: {
          bookingId,
          golfTargetType,
          contentId,
        },
      });
    } else if (ref.match(rExp)) {
      this.props.history.goBack();
    } else if (bookingCode) {
      const setUrl = `${redirectLinks.ATTENDANCE_ONLINE_CULTURE}/?key=${bookingCode}`;
      this.props.history.push({
        pathname: setUrl,
      });
    } else {
      this.props.history.replace(redirectLinks.MYPAGE);
    }
  }

  setMessage = (message) => {
    this.setState({ message });
  }

  onEntryClick = () => {
    this.props.history.push(redirectLinks.ENTRY_DESCRIPTION);
  }

  handleBtnLogin = (event) => {
    event.preventDefault();
    const { loginId, password } = this.state;
    this.onLoading(true);
    this.props.dispatch(actCallApiLogin({ loginId, password }));
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onLoading = (result) => {
    this.setState({ isLoading: result });
  }

  onForgotClick = () => {
    this.props.history.push(redirectLinks.FORGOT_PASSWORD);
  }

  render() {
    const { classes } = this.props;
    const {
      loginId, password, message, isLoading, isFromBooking,
    } = this.state;

    return (
      <div>
        <Loading open={isLoading} />
        <Header isNotShowBtn />
        <Container className={classes.root}>
          <Box display={isFromBooking ? '' : 'none'} mb={3}>
            <Alert severity="error">ご利用にはログインまたは会員申込が必要です</Alert>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className={classes.gridRoot}>
              <form onSubmit={this.handleBtnLogin}>
                <Paper className={classes.paper} variant="outlined">
                  <Box fontWeight="fontWeightBold" align="center" className={classes.title}>
                    会員番号をお持ちの方
                  </Box>
                  <Box textAlign="center" mt={3} color="#f50057" fontWeight="fontWeightBold">{message}</Box>
                  <Box fontWeight="fontWeightBold" mt={4} className={classes.loginTitle}>メールアドレスもしくは会員番号</Box>
                  <TextField
                    id="login-id"
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    required
                    name="loginId"
                    onChange={this.onChange}
                    value={loginId}
                    inputProps={{
                      maxLength: 80,
                    }}
                  />
                  <Box fontWeight="fontWeightBold" mt={4} fontSize={17} className={classes.loginTitle}>パスワード</Box>
                  <PasswordField
                    value={password}
                    required
                    name="password"
                    onChange={this.onChange}
                  />
                  <Box align="right" mt={1}>
                    <div
                      role="button"
                      aria-hidden
                      className={classes.link}
                      onClick={this.onForgotClick}
                    >
                      パスワードをお忘れの方はこちら
                    </div>
                  </Box>
                  <Box mt={3}>
                    <Button color="primary" variant="contained" fullWidth className={classes.buttonRoot} type="submit">ログイン</Button>
                  </Box>
                </Paper>
              </form>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper} variant="outlined">
                <Box fontWeight="fontWeightBold" align="center" className={classes.title}>
                  はじめての方
                </Box>
                <Box mt={3}>
                  <Typography align="center">ご利用には利用者登録が必要です。</Typography>
                </Box>
                <Box mt={3}>
                  <Button color="primary" variant="contained" fullWidth className={classes.buttonRoot} onClick={this.onEntryClick}>利用者登録はこちら</Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ login: state.login });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Login)));
