// #region Type
export const ACTION_TYPE = {
  SET_MEMBER_ENTRY_FORM: 'SET_MEMBER_ENTRY_FORM',
  CALL_API_INIT_SELECT_VALUES: 'CALL_API_INIT_SELECT_VALUES',
  CALL_API_INIT_SELECT_VALUES_SUCCESS: 'CALL_API_INIT_SELECT_VALUES_SUCCESS',
  SET_UPDATED_MEMBER_ENTRY: 'SET_UPDATED_MEMBER_ENTRY',
  GET_ZIP_CODE_ADDRESS: 'GET_ZIP_CODE_ADDRESS',
  GET_ZIP_CODE_ADDRESS_SUCCESS: 'GET_ZIP_CODE_ADDRESS_SUCCESS',
  GET_ZIP_CODE_ADDRESS_USER_SUCCESS: 'GET_ZIP_CODE_ADDRESS_USER_SUCCESS',
  CALL_API_SAVE_ENTRY: 'CALL_API_SAVE_ENTRY',
  CALL_API_SAVE_ENTRY_SUCCESS: 'CALL_API_SAVE_ENTRY_SUCCESS',
  CALL_API_DUPLICATION_CHECK_CONTRACT: 'CALL_API_DUPLICATION_CHECK_CONTRACT',
  CALL_API_DUPLICATION_CHECK_CONTRACT_SUCCESS: 'CALL_API_DUPLICATION_CHECK_CONTRACT_SUCCESS',
  CALL_API_DUPLICATION_COUNT_CLEAR: 'CALL_API_DUPLICATION_COUNT_CLEAR',
};
// #endregion

// #region action
export const actCallApiInitSelectValues = () => ({
  type: ACTION_TYPE.CALL_API_INIT_SELECT_VALUES,
});

export const actCallApiInitSelectValuesSuccess = (initSelectValues) => ({
  type: ACTION_TYPE.CALL_API_INIT_SELECT_VALUES_SUCCESS,
  initSelectValues,
});

export const actCallSetMemberEntryForm = (memberEntry) => ({
  type: ACTION_TYPE.SET_MEMBER_ENTRY_FORM,
  memberEntry,
});

export const getZipCodeAddress = (contidion) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS,
  contidion,
});

export const getZipCodeAddressSuccess = (zipcode) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS_SUCCESS,
  zipcode,
});

export const getZipCodeAddressUserSuccess = (zipcode) => ({
  type: ACTION_TYPE.GET_ZIP_CODE_ADDRESS_USER_SUCCESS,
  zipcode,
});

export const actCallSaveEntry = (memberEntry) => ({
  type: ACTION_TYPE.CALL_API_SAVE_ENTRY,
  memberEntry,
});

export const actSaveEntrySuccess = (memberEntry) => ({
  type: ACTION_TYPE.CALL_API_SAVE_ENTRY_SUCCESS,
  memberEntry,
});

export const actCallDuplicationCheckContract = (contract) => ({
  type: ACTION_TYPE.CALL_API_DUPLICATION_CHECK_CONTRACT,
  contract,
});

export const actCallDuplicationCheckContractSuccess = (count) => ({
  type: ACTION_TYPE.CALL_API_DUPLICATION_CHECK_CONTRACT_SUCCESS,
  count,
});

export const actCallDuplicationCountClear = () => ({
  type: ACTION_TYPE.CALL_API_DUPLICATION_COUNT_CLEAR,
});

export const actCallApiServerError = (error) => ({
  type: ACTION_TYPE.CALL_API_SERVER_ERROR,
  errorMessages: error.response.data.errorDetails,
});

// #endregion
