import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
}));

export default function EntryStepper(props) {
  const steps = ['ご契約者様情報の入力', 'ご利用者様情報の入力', 'ログイン情報の入力', 'お客様情報の確認', '登録完了'];
  const classes = useStyles();
  const { activeStep } = props;

  return (
    <Stepper alternativeLabel activeStep={activeStep} className={classes.stepperRoot}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
