import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fontSize: {
    fontSize: '15px',
  },
}));

export default function FormErrorText(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <FormHelperText error className={classes.fontSize}>{children}</FormHelperText>
  );
}

FormErrorText.propTypes = {
  children: PropTypes.node,
};

FormErrorText.defaultProps = {
  children: null,
};
