import React from 'react';
import {
  Card,
  Box,
  Button,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';

export default function BookingDetailError(props) {
  const { errors, onBookingListBackClick } = props;
  return (
    <div>
      <Card variant="outlined">
        <Box my={3} textAlign="center">
          {errors && errors.map((message) => (
            <Box mb={2} key={message}>
              {message}
            </Box>
          ))}
          <Box my={3} textAlign="center">
            <Button
              variant="contained"
              className="custom-button"
              onClick={onBookingListBackClick}
              startIcon={<ArrowBackIosIcon />}
            >
              前の画面に戻る
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
