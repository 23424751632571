import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, withStyles, Box } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../../../public/assets/header/logo.png';
import beginner from '../../../public/assets/header/beginner.svg';
import login from '../../../public/assets/header/login.svg';
import mypage from '../../../public/assets/header/mypage.svg';
import redirectLinks from '../../../constants/redirectLinks';
import InfoDialog from '../../../components/templates/infoDialog/InfoDialog';
import getStyle from './header-design';

const styles = getStyle();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogoutConfOpen: false,
    };
  }

  onLoginClick = () => {
    this.props.history.push(redirectLinks.LOGIN);
  }

  onMyPageClick = () => {
    this.props.history.push(redirectLinks.MYPAGE);
  }

  onEntryClick = () => {
    this.props.history.push(redirectLinks.ENTRY_DESCRIPTION);
  }

  onLogoClick = () => {
    window.location.href = redirectLinks.BASE;
  }

  onLogoutClick = () => {
    this.setState({ isLogoutConfOpen: true });
  }

  onLogoutCancelClick = () => {
    this.setState({ isLogoutConfOpen: false });
  }

  logout = () => {
    localStorage.clear();
    document.cookie = 'mjwt=; path=/; max-age=0';
    window.location.href = redirectLinks.BASE;
  }

  render() {
    const { isLogoutConfOpen } = this.state;
    const { classes, isNotShowBtn } = this.props;
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');

    return (
      <>
        <div className={classes.root} id="back-to-top-anchor">
          <div className={classes.divTop}>
            <Container>
              <h1 className={classes.caption}>アタマとカラダとココロの&quot;ワクワク&quot;が見つかる</h1>
            </Container>
          </div>
          <Container>
            <img src={logo} alt="ロゴ" className={classes.logo} onClick={this.onLogoClick} aria-hidden="true" />
          </Container>
          <Box className={classes.btnArea} display={isNotShowBtn ? 'none!important' : ''}>
            <button type="button" className={`${classes.linkRoot} ${classes.entryLink}`} onClick={this.onEntryClick}>
              <div className={classes.linkBlock}>
                <img src={beginner} alt="ロゴ" className={classes.menuLogo} />
                <span className={classes.linkSpan}>利用者登録はこちら</span>
              </div>
            </button>
            {token
              ? (
                <button type="button" className={`${classes.linkRoot} ${classes.mypageLink}`} onClick={this.onMyPageClick}>
                  <div className={classes.linkBlock}>
                    <img src={mypage} alt="ロゴ" className={classes.menuLogo} />
                    <span className={classes.linkSpan}>会員様マイページ</span>
                  </div>
                </button>
              ) : (
                <button type="button" className={`${classes.linkRoot} ${classes.loginLink}`} onClick={this.onLoginClick}>
                  <div className={classes.linkBlock}>
                    <img src={login} alt="ロゴ" className={classes.menuLogo} />
                    <span className={classes.linkSpan}>会員様ログイン</span>
                  </div>
                </button>
              )}
            {token && (
              <button type="button" className={`${classes.logoutLinkRoot} ${classes.logoutLink}`} onClick={this.onLogoutClick}>
                <div className={classes.linkBlock}>
                  <ExitToAppIcon className={classes.menuLogo} />
                  <span className={classes.linkSpan}>ログアウト</span>
                </div>
              </button>
            )}
          </Box>
        </div>
        <InfoDialog
          messages={['ログアウトします。よろしいですか？']}
          firstOnFunc={this.onLogoutCancelClick}
          firstFuncText="キャンセル"
          secondOnFunc={this.logout}
          secondFuncText="ログアウトする"
          open={isLogoutConfOpen}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter((Header)));
