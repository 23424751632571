import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getSelects, getCultureBooking,
  getRentalCoatTableTennisBooking, getRentalCoatBadmintonBooking,
  getRoomBooking, getGolfBooking, getOnlineCultureBooking,
} from '../../../services/booking/bookingList.service';
import {
  ACTION_TYPE,
  actCallApiGetBookingSelectSuccess,
  actGetCultureBookingSuccess,
  actGetRentalCoatTableTennisBookingSuccess,
  actGetRentalCoatBadmintonBookingSuccess,
  actGetRoomBookingSuccess,
  actGetGolfBookingSuccess,
  actGetOnlineCultureBookingSuccess,
  actBookingServerError,
} from './action';

function* callApiGetGetBookingSelect(memberId) {
  try {
    const selects = yield call(getSelects, memberId);
    yield put(actCallApiGetBookingSelectSuccess(selects));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

function* callApiGetCultureBooking(param) {
  try {
    const result = yield call(getCultureBooking, param.param);
    yield put(actGetCultureBookingSuccess(result));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

function* callApiGetRentalCoatTableTennisBooking(param) {
  try {
    const result = yield call(getRentalCoatTableTennisBooking, param.param);
    yield put(actGetRentalCoatTableTennisBookingSuccess(result));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

function* callApiGetRentalCoatBadmintonBooking(param) {
  try {
    const result = yield call(getRentalCoatBadmintonBooking, param.param);
    yield put(actGetRentalCoatBadmintonBookingSuccess(result));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

function* callApiGetRoomBooking(param) {
  try {
    const result = yield call(getRoomBooking, param.param);
    yield put(actGetRoomBookingSuccess(result));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

function* callApiGetGolfBooking(param) {
  try {
    const result = yield call(getGolfBooking, param.param);
    yield put(actGetGolfBookingSuccess(result));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

function* callApiGetOnlineCultureBooking(param) {
  try {
    const result = yield call(getOnlineCultureBooking, param.param);
    yield put(actGetOnlineCultureBookingSuccess(result));
  } catch (error) {
    yield put(actBookingServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallBookingList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_BOOKING_SELECT, callApiGetGetBookingSelect);
  yield takeLatest(ACTION_TYPE.CALL_API_CULTURE_BOOKING, callApiGetCultureBooking);
  yield takeLatest(
    ACTION_TYPE.CALL_API_RENTAL_COAT_TABLE_TENNIS_BOOKING, callApiGetRentalCoatTableTennisBooking,
  );
  yield takeLatest(
    ACTION_TYPE.CALL_API_RENTAL_COAT_BADMINTON_BOOKING, callApiGetRentalCoatBadmintonBooking,
  );
  yield takeLatest(ACTION_TYPE.CALL_API_ROOM_BOOKING, callApiGetRoomBooking);
  yield takeLatest(ACTION_TYPE.CALL_API_GOLF_BOOKING, callApiGetGolfBooking);
  yield takeLatest(ACTION_TYPE.CALL_API_ONLINE_CULTURE_BOOKING, callApiGetOnlineCultureBooking);
}
