import {
  combineReducers,
} from 'redux';
import common from './common/reducer';
import top from './other/top/reducer';
import login from './other/login/reducer';
import mypage from './mypage/mypage/reducer';
import entry from './entry/reducer';
import resetPassword from './other/resetPassword/reducer';
import transfer from './mypage/transferRegist/reducer';
import bookingList from './booking/bookingList/reducer';
import bookingDetail from './booking/bookingDetail/reducer';
import surveyDetail from './mypage/surveyDetail/reducer';
import bookingDetailCancelWait from './booking/bookingDetailCancelWait/reducer';
import surveyList from './mypage/surveyList/reducer';
import pointHistory from './mypage/pointHistory/reducer';
import attendanceOnlineCulture from './mypage/attendanceOnlineCulture/reducer';
import noticeList from './mypage/noticeList/reducer';

const appReducer = combineReducers({
  common,
  top,
  login,
  mypage,
  entry,
  resetPassword,
  transfer,
  bookingList,
  bookingDetail,
  surveyDetail,
  bookingDetailCancelWait,
  surveyList,
  pointHistory,
  attendanceOnlineCulture,
  noticeList,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
