// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_BOOKING_SELECT: 'CALL_API_GET_BOOKING_SELECT',
  CALL_API_GET_BOOKING_SELECT_SUCCESS: 'CALL_API_GET_BOOKING_SELECT_SUCCESS',
  CALL_API_CULTURE_BOOKING: 'CALL_API_CULTURE_BOOKING',
  GET_CULTURE_BOOKING_SUCCESS: 'GET_CULTURE_BOOKING_SUCCESS',
  CALL_API_RENTAL_COAT_TABLE_TENNIS_BOOKING: 'CALL_API_RENTAL_COAT_TABLE_TENNIS_BOOKING',
  GET_RENTAL_COAT_TABLE_TENNIS_BOOKING_SUCCESS: 'GET_RENTAL_COAT_TABLE_TENNIS_BOOKING_SUCCESS',
  CALL_API_RENTAL_COAT_BADMINTON_BOOKING: 'CALL_API_RENTAL_COAT_BADMINTON_BOOKING',
  GET_RENTAL_COAT_BADMINTON_BOOKING_SUCCESS: 'GET_RENTAL_COAT_BADMINTON_BOOKING_SUCCESS',
  CALL_API_ROOM_BOOKING: 'CALL_API_ROOM_BOOKING',
  GET_ROOM_BOOKING_SUCCESS: 'GET_ROOM_BOOKING_SUCCESS',
  CALL_API_GOLF_BOOKING: 'CALL_API_GOLF_BOOKING',
  GET_GOLF_BOOKING_SUCCESS: 'GET_GOLF_BOOKING_SUCCESS',
  GET_BOOKING_API_ERROR: 'GET_BOOKING_API_ERROR',
  SET_BOOKING_CONDITION: 'SET_BOOKING_CONDITION',
  CALL_API_ONLINE_CULTURE_BOOKING: 'CALL_API_ONLINE_CULTURE_BOOKING',
  GET_ONLINE_CULTURE_BOOKING_SUCCESS: 'GET_ONLINE_CULTURE_BOOKING_SUCCESS',
};
// #endregion

// #region action
export const actCallApiGetBookingSelect = (memberId) => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKING_SELECT,
  memberId,
});

export const actCallApiGetBookingSelectSuccess = (selects) => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKING_SELECT_SUCCESS,
  selects,
});

export const actCallApiCultureBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_CULTURE_BOOKING,
  param,
});

export const actGetCultureBookingSuccess = (datas) => ({
  type: ACTION_TYPE.GET_CULTURE_BOOKING_SUCCESS,
  datas,
});

export const actCallApiRentalCoatTableTennisBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_RENTAL_COAT_TABLE_TENNIS_BOOKING,
  param,
});

export const actGetRentalCoatTableTennisBookingSuccess = (datas) => ({
  type: ACTION_TYPE.GET_RENTAL_COAT_TABLE_TENNIS_BOOKING_SUCCESS,
  datas,
});

export const actCallApiRentalCoatBadmintonBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_RENTAL_COAT_BADMINTON_BOOKING,
  param,
});

export const actGetRentalCoatBadmintonBookingSuccess = (datas) => ({
  type: ACTION_TYPE.GET_RENTAL_COAT_BADMINTON_BOOKING_SUCCESS,
  datas,
});

export const actCallApiRoomBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_ROOM_BOOKING,
  param,
});

export const actGetRoomBookingSuccess = (datas) => ({
  type: ACTION_TYPE.GET_ROOM_BOOKING_SUCCESS,
  datas,
});

export const actCallApiGolfBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_GOLF_BOOKING,
  param,
});

export const actGetGolfBookingSuccess = (datas) => ({
  type: ACTION_TYPE.GET_GOLF_BOOKING_SUCCESS,
  datas,
});

export const actBookingServerError = () => ({
  type: ACTION_TYPE.GET_BOOKING_API_ERROR,
});

export const actSetBookingCondition = (condition) => ({
  type: ACTION_TYPE.SET_BOOKING_CONDITION,
  condition,
});

export const actCallApiOnlineCultureBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_ONLINE_CULTURE_BOOKING,
  param,
});

export const actGetOnlineCultureBookingSuccess = (datas) => ({
  type: ACTION_TYPE.GET_ONLINE_CULTURE_BOOKING_SUCCESS,
  datas,
});

// #endregion
