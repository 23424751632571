import {
  startOfMonth, endOfMonth, subDays,
  format, getDate, eachDayOfInterval, addDays,
  endOfWeek, eachWeekOfInterval, subMonths, isBefore, isEqual,
  addMonths,
} from 'date-fns';
import ja from 'date-fns/locale/ja';
import { getToday } from '../../booking-list-proc';

function isSomeMonth(d1, d2) {
  return (d1.getFullYear() + d1.getMonth()) - (d2.getFullYear() + d2.getMonth()) === 0;
}

export function getMonthly(paramDate, holidays) {
  const targetDate = new Date(paramDate);

  const weeks = eachWeekOfInterval(
    {
      start: startOfMonth(targetDate),
      end: endOfMonth(targetDate),
    },
    { weekStartsOn: 1 },
  );
  const monthly = weeks.map((week) => eachDayOfInterval(
    {
      start: week,
      end: endOfWeek(week, { weekStartsOn: 1 }),
    },
  ));
  const monthArray = [];
  monthly.forEach((item) => {
    const weekArray = [];
    item.forEach((fnsDate) => {
      weekArray.push(
        {
          date: fnsDate,
          day: getDate(fnsDate),
          isSomeMonth: isSomeMonth(fnsDate, targetDate),
          isHoliday: holidays.indexOf(format(fnsDate, 'yyyy/MM/dd')) > -1,
        },
      );
    });
    monthArray.push(weekArray);
  });
  return monthArray;
}

export function getSpecificDateDatas(date, datas) {
  const targetDate = format(date, 'yyyy/MM/dd');
  return datas.filter((row) => row.date === targetDate);
}

function timeRound(date, time) {
  const fnsDate = new Date(`${date} ${time}`);
  const times = format(fnsDate, 'HH:mm');
  return times;
}

function getSameTimeDataLength(datas, data) {
  return datas.filter(
    (row) => timeRound(row.date, row.startTime) === timeRound(data.date, data.startTime),
  ).length;
}

export function getDayStyle(data, startTime, index, datas) {
  const startDate = new Date(`${data.date} ${data.startTime}`);
  const endDate = new Date(`${data.date} ${data.endTime}`);
  const baseDate = new Date(`${data.date} ${startTime}`);

  const diffDate = (endDate.getTime() - startDate.getTime()) / (60 * 1000) / 30;
  const topDiff = (startDate.getTime() - baseDate.getTime()) / (60 * 1000) / 30;
  let width = 100;
  const sameLength = getSameTimeDataLength(datas, data);
  if (sameLength > 1) {
    width = 100 / sameLength;
  }
  let left = 0;
  const exisitingLength = getSameTimeDataLength(datas.slice(0, index), data);
  if (exisitingLength > 0) {
    left = width * exisitingLength;
  }

  return {
    top: `${topDiff * 120}px`,
    left: `${left}%`,
    width: `${width}%`,
    height: `${diffDate * 120}px`,
    backgroundColor: (data.remaining <= 0 && !data.cancelWaitAccepted) ? '#808080' : data.backColor,
    cursor: (data.remaining <= 0 && !data.cancelWaitAccepted) ? 'default' : 'pointer',
  };
}

function createDateObject(targetDate, index, holidays) {
  const fnsDate = addDays(new Date(targetDate), index);

  return {
    date: fnsDate,
    day: getDate(fnsDate),
    isSomeMonth: isSomeMonth(fnsDate, targetDate),
    week: format(fnsDate, 'EEEEEE', { locale: ja }),
    isHoliday: holidays.indexOf(format(fnsDate, 'yyyy/MM/dd')) > -1,
  };
}

export function getWeekly(targetDate, holidays) {
  const weekArray = [];
  for (let i = 0; i < 7; i += 1) {
    weekArray.push(createDateObject(targetDate, i, holidays));
  }
  return weekArray;
}

export function getDayWeek(targetDate) {
  const day = format(targetDate, 'd日');
  const week = format(targetDate, 'EEEEEE', { locale: ja });
  return `${day} (${week}) `;
}

export function isBackDisabled(element, targetDate) {
  const fnsTargetDate = new Date(targetDate);
  const today = getToday();

  let result = false;
  switch (element) {
    case 'month':
    case 'list': {
      const subMonthDate = subMonths(fnsTargetDate, 1);
      const isSame = isSomeMonth(today, subMonthDate);
      if (isSame === false) {
        result = isBefore(subMonthDate, today);
      }
      break;
    }
    case 'week':
    case 'day':
      result = isEqual(today, fnsTargetDate);
      break;
    default:
      break;
  }
  return result;
}

export function getTodayParam(parma) {
  const targetDate = format(getToday(), 'yyyy/MM/dd');

  const isSome = isSomeMonth(new Date(parma.targetDate), new Date(targetDate));

  return {
    isSome,
    param: {
      ...parma,
      targetDate,
    },
  };
}

export function getBackParam(element, param) {
  let resultDate = getToday();
  let isSome = false;
  const fnsTargetDate = new Date(param.targetDate);

  switch (element) {
    case 'month':
    case 'list': {
      resultDate = subMonths(fnsTargetDate, 1);
      break;
    }
    case 'week': {
      resultDate = subDays(fnsTargetDate, 6);
      isSome = isSomeMonth(resultDate, fnsTargetDate);
      break;
    }
    case 'day':
      resultDate = subDays(fnsTargetDate, 1);
      isSome = isSomeMonth(resultDate, fnsTargetDate);
      break;
    default:
      break;
  }
  if (isBefore(resultDate, getToday())) {
    resultDate = getToday();
  }

  return {
    isSome,
    param: {
      ...param,
      targetDate: format(resultDate, 'yyyy/MM/dd'),
    },
  };
}

export function getNextParam(element, param) {
  let resultDate = getToday();
  let isSome = false;
  const fnsTargetDate = new Date(param.targetDate);

  switch (element) {
    case 'month':
    case 'list': {
      resultDate = addMonths(fnsTargetDate, 1);
      break;
    }
    case 'week': {
      resultDate = addDays(fnsTargetDate, 6);
      isSome = isSomeMonth(resultDate, fnsTargetDate);
      if (!isSome) {
        resultDate = new Date(resultDate.getFullYear(), resultDate.getMonth(), 1);
      }
      break;
    }
    case 'day':
      resultDate = addDays(fnsTargetDate, 1);
      isSome = isSomeMonth(resultDate, fnsTargetDate);
      break;
    default:
      break;
  }

  return {
    isSome,
    param: {
      ...param,
      targetDate: format(resultDate, 'yyyy/MM/dd'),
    },
  };
}
