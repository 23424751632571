import { format } from 'date-fns';

export function getToday() {
  return new Date(format(new Date(), 'yyyy/MM/dd'));
}

export function createParam(props, today) {
  let storeValue = { name: '', value: '' };
  let contentValue = { name: '', value: '' };
  let targetDate = today;
  if (props.location && props.location.state) {
    const locationState = props.location.state;
    storeValue = locationState.storeValue;
    contentValue = locationState.contentValue;
    targetDate = locationState.bookingDate || today;
  } else if (props.bookingList && props.bookingList.condition) {
    const bookingListCondition = props.bookingList.condition;
    storeValue = bookingListCondition.storeValue;
    contentValue = bookingListCondition.contentValue;
    targetDate = bookingListCondition.targetDate || today;
  }

  return {
    storeValue,
    contentValue,
    targetDate,
  };
}

export function getBookingParam(parma) {
  return {
    storeId: parma.storeValue.id,
    targetDate: parma.targetDate,
  };
}

export function getTodayParam(parma) {
  const targetDate = format(getToday(), 'yyyy/MM/dd');

  return {
    ...parma,
    targetDate,
  };
}

function getContentIdFormTab(index) {
  let contentId = null;
  switch (index) {
    case 0: {
      contentId = 1;
      break;
    }
    case 1: {
      contentId = 17;
      break;
    }
    case 2:
      contentId = 2;
      break;
    case 3:
      contentId = 5;
      break;
    case 4:
      contentId = 3;
      break;
    case 5:
      contentId = 4;
      break;
    default:
      break;
  }
  return contentId;
}

function getTabFormContentId(contentId) {
  let tabIndex = 0;
  switch (contentId) {
    case 1: {
      tabIndex = 0;
      break;
    }
    case 2: {
      tabIndex = 2;
      break;
    }
    case 3:
      tabIndex = 4;
      break;
    case 4:
      tabIndex = 5;
      break;
    case 5:
      tabIndex = 3;
      break;
    case 17:
      tabIndex = 1;
      break;
    default:
      break;
  }
  return tabIndex;
}

export function getNewContent(storeId, links, tabIndex) {
  const storeLinks = links.filter((option) => option.mstStoreId === storeId);
  if (storeLinks.length <= 0) {
    return 0;
  }

  const count = storeLinks.filter(
    (option) => option.mstContentId === getContentIdFormTab(tabIndex),
  ).length > 0;
  if (count >= 1) {
    return tabIndex;
  }
  storeLinks.sort((a, b) => {
    if (a.mstContentId > b.mstContentId) {
      return 1;
    }
    return -1;
  });
  const contentId = storeLinks.filter((option) => option.mstContentId <= 5)[0].mstContentId;
  return getTabFormContentId(contentId);
}
