import React from 'react';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px;',
    marginBottom: '5rem',
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid #aaa',
    width: '100%',
  },
  headTd: {
    border: '1px solid #aaa',
    backgroundColor: '#f5f5f5',
    width: '30%',
    height: '50px',
    paddingLeft: '10px',
  },
  dataTd: {
    border: '1px solid #aaa',
    paddingLeft: '10px',
  },
}));

export default function CheckBooking(props) {
  const {
    data, user, onBookingDetailBackClick, onCompleteClick,
    dispEndTime, selectedGolfBox, guestInfo,
  } = props;
  const classes = useStyles();
  const cancelPossibleDate = data.cancelPossibleDate || null;

  return (
    <div>
      <Box mb={2}><Alert severity="warning">ご予約はまだ確定していません</Alert></Box>
      <Box fontWeight="fontWeightBold" m={1} fontSize={15}>ご予約者内容の最終確認をしてください。</Box>
      <Box fontWeight="fontWeightBold" m={1} fontSize={15}>内容がよろしければ、「この内容で予約を確定する」ボタンをクリックしてください。</Box>

      <Box mt={5}>
        <Box fontWeight="fontWeightBold" fontSize={20}>ご予約者様の情報</Box>
        <table className={classes.table}>
          <tbody>
            {user && (
              <>
                <tr>
                  <td className={classes.headTd}>会員番号</td>
                  <td className={classes.dataTd}>{user.code}</td>
                </tr>
                <tr>
                  <td className={classes.headTd}>ご予約者氏名</td>
                  <td className={classes.dataTd}>
                    {`${user.lastName} ${user.firstName}`}
                  </td>
                </tr>
              </>
            )}
            {!user && (
              <>
                <tr>
                  <td className={classes.headTd}>氏名</td>
                  <td className={classes.dataTd}>{guestInfo.name}</td>
                </tr>
                <tr>
                  <td className={classes.headTd}>電話番号</td>
                  <td className={classes.dataTd}>{guestInfo.phoneNumber}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </Box>

      <Box mt={5}>
        <Box fontWeight="fontWeightBold" fontSize={20}>ご予約内容</Box>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={classes.headTd}>ご予約日時</td>
              <td className={classes.dataTd}>{`${data.targetDate} (${data.week}) ${data.startTime} ～ ${dispEndTime}`}</td>
            </tr>
            <tr>
              <td className={classes.headTd}>メニュー</td>
              <td className={classes.dataTd}>{data.menuName}</td>
            </tr>
            <tr style={{ display: data.content ? '' : 'none' }}>
              <td className={classes.headTd}>内容</td>
              <td className={classes.dataTd}>
                {data.content && data.content.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </td>
            </tr>
            <tr style={{ display: data.roomName ? '' : 'none' }}>
              <td className={classes.headTd}>Room</td>
              <td className={classes.dataTd}>{data.roomName}</td>
            </tr>
            <tr style={{ display: data.teacherName ? '' : 'none' }}>
              <td className={classes.headTd}>講師</td>
              <td className={classes.dataTd}>{data.teacherName}</td>
            </tr>
            <tr style={{ display: data.annotation ? '' : 'none' }}>
              <td className={classes.headTd}>特記事項</td>
              <td className={classes.dataTd}>{data.annotation}</td>
            </tr>
            <tr style={{ display: data.materialFee ? '' : 'none' }}>
              <td className={classes.headTd}>教材費</td>
              <td className={classes.dataTd}>{`${data.materialFee}円`}</td>
            </tr>
            <tr style={{ display: data.managementFee ? '' : 'none' }}>
              <td className={classes.headTd}>管理費</td>
              <td className={classes.dataTd}>{`${data.managementFee}円`}</td>
            </tr>
            <tr style={{ display: selectedGolfBox ? '' : 'none' }}>
              <td className={classes.headTd}>打席番号</td>
              <td className={classes.dataTd}>{selectedGolfBox}</td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box my={5}>
        { user && (
          <>
            <Box fontWeight="fontWeightBold" fontSize={20}>マイページからのキャンセル可能期限</Box>
            <Box p={{ xs: 2, sm: 3 }} border={1} borderColor="#aaa">
              <Box fontWeight="fontWeightBold" mb={1}>
                {`${format(new Date(cancelPossibleDate), 'yyyy年MM月dd日 (EEEEEE)', { locale: ja })} ${data.cancelPossibleTime}`}
              </Box>
              <Box fontSize={{ xs: '13px', sm: '15px' }}>※それ以降のキャンセルは、直接店舗へご連絡ください。</Box>
              <Box fontSize={{ xs: '13px', sm: '15px' }}>ご連絡のないキャンセルをされた場合、ご予約を承れなくなることがあります。</Box>
            </Box>
          </>
        )}
        { !user && (
          <>
            <Box fontSize={{ xs: '13px', sm: '15px' }}>※キャンセルは、直接店舗へご連絡ください。</Box>
            <Box fontSize={{ xs: '13px', sm: '15px' }}>ご連絡のないキャンセルをされた場合、ご予約を承れなくなることがあります。</Box>
          </>
          // <Box p={{ xs: 2, sm: 3 }} border={1} borderColor="#aaa">
          // </Box>
        )}
      </Box>

      <Box my={3} textAlign="center" display={{ xs: 'block', sm: 'flex' }} justifyContent="center">
        <Box>
          <Button variant="contained" startIcon={<ArrowBackIosIcon />} onClick={onBookingDetailBackClick}>前の画面に戻る</Button>
        </Box>
        <Box mt={{ xs: 3, sm: 0 }}>
          <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={onCompleteClick}>この内容で予約を確定する</Button>
        </Box>
      </Box>
    </div>
  );
}
