import { ACTION_TYPE } from './action';

// #region Reducer
const mypage = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_MYPAGE_COMMON_INFO_SUCCESS:
      return {
        ...state,
        commonData: action.data,
      };
    case ACTION_TYPE.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        contractDatas: action.datas,
      };
    case ACTION_TYPE.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordUpdate: true,
      };
    case ACTION_TYPE.RESET_MYPAGE_FLG:
      return {
        ...state,
        isPasswordUpdate: false,
        isRegularStatusUpdate: false,
      };
    case ACTION_TYPE.GET_REGULARS_SUCCESS:
      return {
        ...state,
        regularDatas: action.datas,
      };
    case ACTION_TYPE.SET_REGULAR_SEARCH_CONDITION:
      return {
        ...state,
        regularCondition: action.condition,
      };
    case ACTION_TYPE.UPDATE_REGULAR_STATUS_SUCCESS:
      return {
        ...state,
        isRegularStatusUpdate: true,
      };
    case ACTION_TYPE.SET_MYPAGE_TAB_VALUE:
      return {
        ...state,
        tabValue: action.value,
      };
    case ACTION_TYPE.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookingDatas: action.datas,
      };
    case ACTION_TYPE.SET_BOOKING_SEARCH_CONDITION:
      return {
        ...state,
        bookingCondition: action.condition,
      };
    case ACTION_TYPE.GET_BOOKING_CONTENTS_SUCCESS:
      return {
        ...state,
        bookingContents: action.datas.bookingContents,
        reserveInfo: action.datas.reserveInfo,
        isTargetAccepted: action.datas.targetAccepted,
      };
    case ACTION_TYPE.UPDATE_BOOKING_CANCEL_SUCCESS:
      return {
        ...state,
        bookingCancelResult: action.bookingCancelResult,
      };
    case ACTION_TYPE.UPDATE_BOOKING_CONFIRM_SUCCESS:
      return {
        ...state,
        bookingConfirmResult: action.bookingConfirmResult,
      };
    case ACTION_TYPE.GET_ZIP_CODE_ADDRESS_SUCCESS:
      return {
        ...state,
        zipcode: action.zipcode,
      };
    case ACTION_TYPE.CALL_API_SELECT_VALUES_SUCCESS:
      return {
        ...state,
        selectValues: action.selectValues,
      };
    case ACTION_TYPE.CALL_API_CONTRACT_EDIT_SUCCESS:
      return {
        ...state,
        updateFlag: true,
      };
    case ACTION_TYPE.CALL_API_MEMBER_EDIT_SUCCESS:
      return {
        ...state,
        updateFlag: true,
      };
    case ACTION_TYPE.EDIT_FLG_RESET:
      return {
        ...state,
        updateFlag: false,
      };
    default:
      return state;
  }
};

export default mypage;
