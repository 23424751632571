const errorCheckText = (value) => {
  if (!value || value.length === 0) return '入力してください';
  return '';
};

const errorCheckPhone = (value) => {
  if (!value || value.length === 0) return '入力してください';
  const regex = /^\d{1,5}-?\d{1,4}-?\d{1,4}$/;
  if (!regex.test(value)) return '正しい電話番号を入力してください';
  return '';
};

const errorCheckPhoneTwo = (value) => {
  if (!value || value.length === 0) return '';
  const regex = /^\d{1,5}-?\d{1,4}-?\d{1,4}$/;
  if (!regex.test(value)) return '正しい電話番号を入力してください';
  return '';
};

const errorCheckZipCode = (value) => {
  if (!value || value.length === 0) return '入力してください';
  const regex = /^\d{3}-?\d{4}$/;
  if (!regex.test(value)) return '正しい郵便番号を入力してください';
  return '';
};

const errorCheckZipCodeNoRequired = (value) => {
  if (!value || value.length === 0) return '';
  const regex = /^\d{3}-?\d{4}$/;
  if (!regex.test(value)) return '正しい郵便番号を入力してください';
  return '';
};

const errorCheckMail = (value) => {
  if (!value || value.length === 0) return '入力してください';
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
  if (!regex.test(value)) return '正しい形式で入力してください';
  return '';
};

const errorCheckSelectList = (value) => {
  if (!value || !value.id) return '選択してください';
  return '';
};

const errorCheckPassword = (value) => {
  if (!value || value.length === 0) return '入力してください';
  const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
  if (!regex.test(value)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
  return '';
};

class validation {
static formValidate = (type, value) => {
  switch (type) {
    case 'selectPrefecture':
      return errorCheckSelectList(value);
    case 'city':
    case 'street':
    case 'emergencyLastName':
    case 'emergencyFirstName':
      return errorCheckText(value);
    case 'email':
      return errorCheckMail(value);
    case 'phone':
    case 'emergencyPhone':
      return errorCheckPhone(value);
    case 'zipCode':
      return errorCheckZipCode(value);
    case 'zipCodeNorequired':
      return errorCheckZipCodeNoRequired(value);
    case 'phoneTwo':
      return errorCheckPhoneTwo(value);
    case 'password':
      return errorCheckPassword(value);
    case 'cityNoRequired':
    case 'streetNoRequired':
    default:
      return '';
  }
};
}

export default validation;
