import React from 'react';
import { isValid, addMonths, format } from 'date-fns';
import {
  Grid,
  makeStyles,
  Box,
  Button,
  Hidden,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Monthpicker from '../../../../../components/atoms/monthpicker/Monthpicker';

const useStyles = makeStyles((theme) => ({
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonRoot: {
    color: '#2196f3',
    textDecoration: 'underline',
    padding: 0,
    '&:hover': {
      color: '#2196f3',
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  spMonthTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
}));

export default function MonthTitle(props) {
  const classes = useStyles();
  const { targetMonth, handleChange } = props;

  const handleDateChange = (event, values) => {
    if (!isValid(new Date(`${values}/01`))) return;
    handleChange(event, values);
  };

  const onMonthClick = (event, index) => {
    const value = format(addMonths(new Date(`${targetMonth}/01`), index), 'yyyy/MM');
    handleChange(event, value);
  };

  return (
    <Box my={3}>
      <Grid container>
        <Grid item xs align="left" className={classes.textCenter}>
          <Button
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            className={classes.buttonRoot}
            onClick={(event) => onMonthClick(event, -1)}
          >
            {format(addMonths(new Date(`${targetMonth}/01`), -1), 'y年M月')}
          </Button>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={5} className={classes.textCenter}>
            <Monthpicker
              value={targetMonth}
              handleChange={handleDateChange}
              variant="inline"
            />
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid item xs={5} className={classes.textCenter}>
            <Box className={classes.spMonthTitle}>{format(new Date(`${targetMonth}/01`), 'y年M月')}</Box>
          </Grid>
        </Hidden>
        <Grid item xs align="right" className={classes.textCenter}>
          <Button
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            className={classes.buttonRoot}
            onClick={(event) => onMonthClick(event, 1)}
          >
            {format(addMonths(new Date(`${targetMonth}/01`), 1), 'y年M月')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
