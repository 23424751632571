import { ACTION_TYPE } from './action';

const bookingList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_BOOKING_SELECT_SUCCESS:
      return {
        ...state,
        selects: action.selects,
      };
    case ACTION_TYPE.GET_CULTURE_BOOKING_SUCCESS:
      return {
        ...state,
        cultures: action.datas,
      };
    case ACTION_TYPE.GET_RENTAL_COAT_TABLE_TENNIS_BOOKING_SUCCESS:
      return {
        ...state,
        rentalCoatTableTennis: action.datas,
      };
    case ACTION_TYPE.GET_RENTAL_COAT_BADMINTON_BOOKING_SUCCESS:
      return {
        ...state,
        rentalCoatBadminton: action.datas,
      };
    case ACTION_TYPE.GET_ROOM_BOOKING_SUCCESS:
      return {
        ...state,
        rooms: action.datas,
      };
    case ACTION_TYPE.GET_GOLF_BOOKING_SUCCESS:
      return {
        ...state,
        golf: action.datas,
      };
    case ACTION_TYPE.GET_BOOKING_API_ERROR:
      return {
        ...state,
      };
    case ACTION_TYPE.SET_BOOKING_CONDITION:
      return {
        ...state,
        condition: action.condition,
      };
    case ACTION_TYPE.GET_ONLINE_CULTURE_BOOKING_SUCCESS:
      return {
        ...state,
        onlineCultures: action.datas,
      };
    default:
      return state;
  }
};

export default bookingList;
