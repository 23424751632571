import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * マイページ共通情報取得
 * @returns
 */
export function getCommonInfo() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.COMMON)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getContract() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.CONTRACT)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updatePassword(password) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.MYPAGE.CHANGE_PASSWORD, null, { password })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRegularContents() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.REGULAR_CONTENTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getRegulars(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.REGULARS, param.param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateRegularStatus(param) {
  const httpConnection = new HttpConnection();
  let url = REST_API.MYPAGE.REGULARS_ABSENCE;
  if (param.param.type === 2) {
    url = REST_API.MYPAGE.REGULARS_ABSENCE_CANCEL;
  }
  if (param.param.type === 3) {
    url = REST_API.MYPAGE.REGULARS_TRANSFER_CANCEL;
  }
  return httpConnection
    .put(url, { id: param.param.id }, null)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getBookingContents() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.BOOKING_CONTENTS)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getBookings(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.BOOKINGS, param.param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateBookingCancel(param) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .put(REST_API.MYPAGE.BOOKING_CANCEL, null, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateBookingConfirm(param) {
  const httpConnection = new HttpConnection();
  return httpConnection
    .put(REST_API.MYPAGE.BOOKING_CONFIRM, null, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getZipCodeAddress(ZipCodeRequest) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.COMMON.ZIPCODEADDRESS, { zipCode: ZipCodeRequest.zipCode })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getSelectValues() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.SELECT_VALUES)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function contractEdit(contract) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MYPAGE.CONTRACT_EDIT, contract)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function memberEdit(member) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .post(REST_API.MYPAGE.MEMBER_EDIT, member)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
