import { ACTION_TYPE } from './action';

// #region Reducer
const surveyList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_SURVEY_UNANSWERED_SUCCESS:
      return {
        ...state,
        unansweredList: action.datas,
      };
    case ACTION_TYPE.CALL_API_GET_SURVEY_ANSWERED_SUCCESS:
      return {
        ...state,
        answeredList: action.datas,
      };
    case ACTION_TYPE.CALL_API_GET_SURVEY_ALL_SUCCESS:
      return {
        ...state,
        allList: action.datas,
      };
    case ACTION_TYPE.SET_SURVEY_LIST_SEARCH_CONDITION:
      return {
        ...state,
        surveyListCondition: action.param,
      };
    default:
      return state;
  }
};

export default surveyList;
