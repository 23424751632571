export default function getStyle() {
  const styles = (theme) => ({
    root: {
      padding: 0,
    },
    searchRoot: {
      position: 'relative',
    },
    searchImg: {
      width: '100%',
      height: '100%',
      position: 'relative',
      maxHeight: '600px',
      minHeight: '400px',
      backgroundSize: 'cover',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '400px',
        minHeight: '400px',
      },
    },
    searchContent: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    searchSelect: {
      [theme.breakpoints.up('sm')]: {
        width: '200px',
      },
    },
    searchCard: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      margin: 0,
      padding: 0,
      width: '500px',
      [theme.breakpoints.down('sm')]: {
        width: '700px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '90%',
      },
    },
    serachTitleRoot: {
      padding: '10px!important',
      backgroundColor: 'rgba(245,245,245,0.70)!important',
      boxShadow: '0px!important',
      marginBottom: '25px',
      border: '2px solid #B1CA02!important',
      textAlign: 'center',
    },
    searchTitle: {
      fontWeight: 600,
      color: '#000000',
      fontSize: '25px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '19px',
      },
    },
    searchCol: {
      padding: '17px 24px!important',
      backgroundColor: 'rgba(245,245,245,0.85)!important',
      boxShadow: '0px!important',
      border: '1px solid #B1CA02!important',
      textAlign: 'center',
    },
    searchStoreBox: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10px',
      },
    },
    featureArea: {
      textAlign: 'center',
      backgroundColor: '#F5F5F5',
      padding: '30px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '30px',
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingBottom: '30px',
      },
    },
    featureTitle: {
      fontWeight: 700,
      fontSize: '30px',
    },
    featureTitleOne: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '30px',
      },
    },
    featureTitleTwo: {
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
      },
    },
    featureTitleThree: {
      color: theme.palette.secondary.main,
      fontSize: '45px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '40px',
      },
    },
    featureCaption: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
      },
    },
    mainColor: {
      color: theme.palette.primary.main,
    },
    spShow: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    featureCardRoot: {
      marginTop: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginTop: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    featureCardRootSp: {
      display: 'block',
      marginTop: '30px',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    featureCard: {
      width: '300px',
      height: '300px',
      textAlign: 'center',
      border: 'solid #B1CA02',
      borderRadius: '50%',
      backgroundColor: '#fff',
      marginLeft: '30px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
        width: '200px',
        height: '200px',
        marginLeft: '5px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '1px',
        width: '160px',
        height: '160px',
        marginLeft: '1px',
      },
    },
    featureCardTitle: {
      marginTop: '10px',
      marginBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    featureImg: {
      width: '100px',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '50px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '40px',
      },
    },
    featureCardCaption: {
      fontSize: '14px',
      fontWeight: 400,
      marginTop: '10px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
        marginTop: '0px',
      },
    },
    featureCardTopSp: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    featureCardSecondSp: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    featureButtonArea: {
      margin: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginTop: '2rem',
        marginBottom: '0px',
      },
    },
    featureButton: {
      fontSize: '1.8rem',
      width: '400px',
      padding: '10px',
      transition: 'all 0.3s',
      color: '#fff',
      backgroundColor: '#338888',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)',
      [theme.breakpoints.down('xs')]: {
        width: '250px',
      },
      '&:hover': {
        transform: 'scale(1.1)',
        backgroundColor: '#338888',
        color: '#fff',
      },
    },
    btnIcon: {
      position: 'absolute',
      right: '50px',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    bannerImg: {
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
    },
    selectInline: {
      width: '200px',
      marginRight: '15px',
    },
  });
  return styles;
}
