import React from 'react';
import {
  Hidden,
  Box,
  Button,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import DangerButton from '../../../../../components/atoms/dangerButton/DangerButton';
import TableFrameCell from '../../../../../components/atoms/tableFrameCell/TableFrameCell';
import {
  checkDoTransfer,
  checkDoAbsence,
  checkDoTransferCancel,
  checkDoAbsenceCancel,
} from './regular-list-proc';
import Pagination from '../../../../../components/atoms/pagination/Pagination';

const useStyles = makeStyles(() => ({
  chip: {
    height: '25px',
  },
  closedChip: {
    height: '25px',
    background: '#f50057',
    color: '#fff',
  },
  box: {
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ResponsiveBookingTable(props) {
  const {
    datas,
    page,
    records,
    handleChangePage,
    handleChangeRowsPerPage,
    onAbsenceClick,
    onAbsenceCancelClick,
    onTransferCancelClick,
    onTransferClick,
  } = props;
  const classes = useStyles();
  const rows = datas.content || [];

  return (
    <>
      <Hidden mdDown>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableFrameCell>日時</TableFrameCell>
                <TableFrameCell>コンテンツ</TableFrameCell>
                <TableFrameCell>メニュー</TableFrameCell>
                <TableFrameCell>状態</TableFrameCell>
                <TableFrameCell> </TableFrameCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableFrameCell>
                    <>
                      { row.bookingStatus === '2' && (
                        <Box mb={1}><Chip label="休講" className={classes.closedChip} /></Box>
                      )}
                      { row.transferDateTime !== '' && (<Box mb={1}><Chip label={`${row.transferDateTime}から振替`} color="primary" className={classes.chip} /></Box>)}
                      {`${row.targetDate} (${row.weekName}) ${row.startTime}～${row.endTime}`}
                    </>
                  </TableFrameCell>
                  <TableFrameCell>{row.contentName}</TableFrameCell>
                  <TableFrameCell>{row.menuName}</TableFrameCell>
                  <TableFrameCell>{row.memberStatusName}</TableFrameCell>
                  <TableFrameCell>
                    <Box display="flex">
                      { checkDoTransfer(row) && (<Box mr={2}><Button variant="contained" size="small" onClick={(event) => onTransferClick(event, row)}>振替をする</Button></Box>)}
                      { checkDoAbsence(row) && (<Box><Button variant="contained" size="small" onClick={(event) => onAbsenceClick(event, row)}>欠席する</Button></Box>)}
                      { checkDoAbsenceCancel(row) && (<Box><DangerButton variant="contained" label="欠席を取消する" size="small" onClick={(event) => onAbsenceCancelClick(event, row)} /></Box>)}
                      { checkDoTransferCancel(row) && (<Box><DangerButton variant="contained" label="振替をキャンセルする" onClick={(event) => onTransferCancelClick(event, row)} size="small" /></Box>)}
                    </Box>
                  </TableFrameCell>
                </TableRow>
              ))}
              {
                rows.length < 1 && (
                  <TableRow key={0}>
                    <TableFrameCell colSpan={6} align="center">
                      予定されている定期情報はありません
                    </TableFrameCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden lgUp>
        {rows.map((row) => (
          <Box boxShadow={3} mb={3} p={2} key={row.id} className={classes.box}>
            <Box>
              <Box fontWeight="fontWeightBold">日時</Box>
              <Box>
                { row.bookingStatus === '2' && (
                  <Box mb={1}><Chip label="休講" className={classes.closedChip} /></Box>
                )}
                { row.transferDateTime !== '' && (<Box mb={1}><Chip label={`${row.transferDateTime}から振替`} color="primary" className={classes.chip} /></Box>)}
                {`${row.targetDate} (${row.weekName}) ${row.startTime}～${row.endTime}`}
              </Box>
            </Box>
            <Box mt={2}>
              <Box fontWeight="fontWeightBold">コンテンツ</Box>
              <Box>{row.contentName}</Box>
            </Box>
            <Box mt={2}>
              <Box fontWeight="fontWeightBold">メニュー</Box>
              <Box>{row.menuName}</Box>
            </Box>
            <Box mt={2}>
              <Box fontWeight="fontWeightBold">状態</Box>
              <Box>{row.memberStatusName}</Box>
            </Box>
            <Box display="flex" mt={2}>
              { checkDoTransfer(row) && (<Box><Button variant="contained" size="small" disableElevation onClick={(event) => onTransferClick(event, row)}>振替をする</Button></Box>)}
              { checkDoAbsence(row) && (<Box><Button variant="contained" size="small" disableElevation onClick={(event) => onAbsenceClick(event, row)}>欠席する</Button></Box>)}
              { checkDoAbsenceCancel(row) && (<Box ml={2}><DangerButton variant="contained" disableElevation label="欠席を取消する" size="small" onClick={(event) => onAbsenceCancelClick(event, row)} /></Box>)}
              { checkDoTransferCancel(row) && (<Box><DangerButton variant="contained" disableElevation label="振替をキャンセルする" onClick={(event) => onTransferCancelClick(event, row)} size="small" /></Box>)}
            </Box>
          </Box>
        ))}
        {
          rows.length < 1 && (
            <Box textAlign="center" mt={2}>予定されている定期情報がありません</Box>
          )
        }
      </Hidden>
      <Pagination
        totalElements={datas.totalElements}
        records={records}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
