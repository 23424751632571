import {
  isSameMonth, isAfter, subSeconds, isBefore,
} from 'date-fns';

export function checkDoTransfer(row) {
  const {
    memberStatus, targetedOn, possibleTransfer,
    transferDateTime,
  } = row;

  if (memberStatus !== '3') {
    return false;
  }

  if (transferDateTime) {
    return false;
  }
  if (!possibleTransfer) {
    return false;
  }
  const today = new Date();
  const periodDifference = (
    today.getFullYear() - targetedOn.substring(0, 4)
  ) * 12 + today.getMonth() + 1 - targetedOn.substring(5, 7);
  if (periodDifference !== 0 && periodDifference !== 1) {
    return false;
  }
  const targetNewDate = new Date(targetedOn.replace(/-/g, '/'));
  return (isSameMonth(today, targetNewDate))
    || (isAfter(targetNewDate, today))
    || (isBefore(targetNewDate, today));
}

export function checkDoAbsence(row) {
  const {
    memberStatus, targetedOn, startTime, transferDateTime, bookingStatus,
  } = row;
  if (memberStatus !== '0' || transferDateTime || bookingStatus !== '0') {
    return false;
  }
  const today = new Date();
  const targetNewDate = subSeconds(new Date(`${targetedOn.replace(/-/g, '/')} ${startTime}`), 59);
  return isAfter(targetNewDate, today);
}

export function checkDoTransferCancel(row) {
  const {
    targetedOn, transferDateTime, startTime, memberStatus,
  } = row;
  if (!transferDateTime || memberStatus !== '0') {
    return false;
  }
  const today = new Date();
  const targetNewDate = subSeconds(new Date(`${targetedOn.replace(/-/g, '/')} ${startTime}`), 59);
  return isAfter(targetNewDate, today);
}

export function checkDoAbsenceCancel(row) {
  const {
    targetedOn, startTime, memberStatus, transferDateTime, bookingStatus,
  } = row;
  if (memberStatus !== '3' || transferDateTime || bookingStatus !== '0') {
    return false;
  }
  const today = new Date();
  const targetNewDate = subSeconds(new Date(`${targetedOn.replace(/-/g, '/')} ${startTime}`), 59);
  return isAfter(targetNewDate, today);
}
