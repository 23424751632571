import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Card, Chip,
} from '@material-ui/core';
import TabPanel from '../../../../../components/atoms/tabPanel/TabPanel';
import { actCallApiGetNotices } from '../../../../../redux/mypage/noticeList/action';

// #region css
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  boxRoot: {
    margin: 'auto',
    maxWidth: '600px',
  },
  noticeTitle: {
    padding: '8px 10px 4px',
    backgroundColor: '#f2f2f2',
    borderBottom: '2px solid #ccc',
    marginBottom: '10px',
  },
  border: {
    columnRule: '1px',
  },
  nonNotice: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
});
// #endregion

class NoticeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeList: [],
    };
    this.props.dispatch(actCallApiGetNotices());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.noticeList.noticeList !== this.props.noticeList.noticeList) {
      if (this.props.noticeList.noticeList) {
        this.setNoticeList(this.props.noticeList.noticeList);
      }
    }
  }

  setNoticeList = (noticeList) => {
    this.setState({
      noticeList,
    });
  }

  render() {
    const { classes, value, index } = this.props;
    const { noticeList } = this.state;
    return (
      <TabPanel value={value} index={index} style={{ width: '100%' }}>
        <Card className={classes.root}>
          <Box fontWeight="fontWeightBold" m={2} fontSize="h6.fontSize">
            お知らせ
          </Box>
          {noticeList.length === 0 && (
            <Box className={classes.nonNotice}>お知らせはありません</Box>
          )}
          <Box className={classes.boxRoot}>
            {noticeList.length > 0 && noticeList.map((notice) => (
              <Box>
                <Box fontWeight="fontWeightBold" pl={{ xs: 2, md: 0 }} className={classes.noticeTitle}>{notice.title}</Box>
                {notice.category && (
                  <Box pr={1} textAlign="right"><Chip label={notice.category} variant="outlined" /></Box>
                )}
                <Box pr={1} textAlign="right">{notice.startDate}</Box>
                <Box
                  p={{ xs: 1, lg: 0 }}
                  className="notice-detail"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: notice.content,
                  }}
                />
              </Box>
            ))}
          </Box>
        </Card>
      </TabPanel>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, noticeList: state.noticeList });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(NoticeList)));
