import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Grid,
  AccordionActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SideDisplayGrid from '../../../../components/organisms/sideDisplayGrid/SideDisplayGrid';
import {
  actCallSaveEntry,
} from '../../../../redux/entry/action';
import redirectLinks from '../../../../constants/redirectLinks';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  title: {
    background: '#E7E6E6',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    margin: '0 -1px -1px 0',
  },
  inputArea: {
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    margin: '0 -1px -1px 0',
  },
  ruleRoot: {
    overflow: 'auto',
    border: '1px solid #e5e4e4',
    height: '260px',
    fontSize: '14px',
    lineHeight: 1.4,
  },
  accordionRoot: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    marginTop: '20px',
  },
});
// #endregion

class ConfirmForm extends React.Component {
  onClickNextComplete = () => {
    const { isSameContractUser } = this.props;
    const { memberEntry } = this.props.entry;
    const { contract, members } = memberEntry;
    const tempContract = {
      ...contract,
      mstCodePrefectureCode: contract.mstCodePrefectureCode.id,
    };

    if (isSameContractUser === '0') {
      members[0] = contract;
    }

    const tempMembers = members.map((member) => ({
      ...member,
      mstCodePrefectureCode: member.mstCodePrefectureCode && member.mstCodePrefectureCode.id,
    }));

    const tempMemberEntry = {
      ...memberEntry,
      mstStoreId: memberEntry.mstStoreId.id,
      contract: tempContract,
      members: tempMembers,
    };

    this.props.dispatch(actCallSaveEntry(tempMemberEntry));
    this.props.history.push({
      pathname: redirectLinks.COMPLETE_ENTRY,
      state: { successFlg: true },
    });
  }

  onClickBackContract = () => {
    const { onClickGoBackFromConfirm } = this.props;
    onClickGoBackFromConfirm(0, true);
  }

  onClickBackUser = () => {
    const { onClickGoBackFromConfirm } = this.props;
    onClickGoBackFromConfirm(1, true);
  }

  render() {
    const {
      classes,
      isSameContractUser,
      initSelectValues,
    } = this.props;

    const { memberEntry } = this.props.entry;

    const {
      mstCodeContractCode,
      userType,
    } = memberEntry;

    const settings = memberEntry.mstStoreId
    && initSelectValues.membershipAgreementSelectList.filter((object) => (
      (memberEntry.mstStoreId.id === object.mstStoreId)
    ));
    const membership = settings && settings.length > 0 ? settings[0].membership : '';
    const privacyPolicy = settings && settings.length > 0 ? settings[0].privacyPolicy : '';

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item md={3} xs={12} className={classes.title}>
            <Box fontWeight="fontWeightBold">
              ご利用店舗
            </Box>
          </Grid>
          <Grid item md={9} xs={12} className={classes.inputArea}>
            <Box>{memberEntry.mstStoreId && memberEntry.mstStoreId.name}</Box>
          </Grid>
        </Grid>

        <Accordion defaultExpanded className={classes.accordionRoot}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1b-content"
            id="panel1b-header"
          >
            <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">ご契約者様情報</Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0}>
              <SideDisplayGrid isNotShow={memberEntry.contract.corporationName === ''} title="法人名" content={memberEntry.contract.corporationName} />
              <SideDisplayGrid title="氏名" content={`${memberEntry.contract.lastName} ${memberEntry.contract.firstName}`} />
              <SideDisplayGrid title="氏名カナ" content={`${memberEntry.contract.lastNameKana} ${memberEntry.contract.firstNameKana}`} />
              <SideDisplayGrid title="郵便番号" content={`${memberEntry.contract.zipCodeBefore}-${memberEntry.contract.zipCodeAfter}`} />
              <SideDisplayGrid title="都道府県" content={memberEntry.contract.mstCodePrefectureCode && memberEntry.contract.mstCodePrefectureCode.name} />
              <SideDisplayGrid title="市区町村" content={memberEntry.contract.city} />
              <SideDisplayGrid title="町名・番地・その他" content={memberEntry.contract.street} />
              <SideDisplayGrid title=" メールアドレス" content={memberEntry.contract.email} />
              <SideDisplayGrid title=" 電話番号" content={`${memberEntry.contract.phoneBefore}-${memberEntry.contract.phoneMiddle}-${memberEntry.contract.phoneAfter}`} />
              <SideDisplayGrid
                title="電話番号2"
                content={
                  memberEntry.contract.phoneTwoBefore && `${memberEntry.contract.phoneTwoBefore}-${memberEntry.contract.phoneTwoMiddle}-${memberEntry.contract.phoneTwoAfter}`
                }
              />
              <SideDisplayGrid title="生年月日" content={`${memberEntry.contract.birthdayYear}年${memberEntry.contract.birthdayMonth}月${memberEntry.contract.birthdayDay}日`} />
              <SideDisplayGrid
                title="性別"
                content={
                  initSelectValues.genderSelectList
                    .filter((option) => option.id === memberEntry.contract.mstCodeGenderCode)
                    .map((data) => data.name)[0]
                }
              />
              <SideDisplayGrid title="緊急連絡先氏名" content={`${memberEntry.contract.emergencyLastName} ${memberEntry.contract.emergencyFirstName}`} />
              <SideDisplayGrid title="緊急連絡先電話番号" content={`${memberEntry.contract.emergencyPhoneBefore}-${memberEntry.contract.emergencyPhoneMiddle}-${memberEntry.contract.emergencyPhoneAfter}`} />
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <Box>
              <Button onClick={this.onClickBackContract} color="primary">ご契約者様情報を修正する</Button>
            </Box>
          </AccordionActions>
        </Accordion>

        <Box mt={3} fontWeight="fontWeightBold" fontSize="h6.fontSize">ご利用者様情報</Box>
        <Box display={isSameContractUser === '0' ? '' : 'none'}>
          <hr />
          ご契約者様と同じ
        </Box>
        <Box display={mstCodeContractCode === 3 ? '' : 'none'}>
          <hr />
          <Grid container spacing={0}>
            <SideDisplayGrid
              title="ご利用タイプ"
              content={
                initSelectValues.applicantTypeSelectList
                  .filter((option) => (option.id === userType))
                  .map((data) => data.name)[0]
              }
            />
          </Grid>
        </Box>
        {
          (mstCodeContractCode === 3 || isSameContractUser === '0') && (
            <Box display="flex" flexDirection="row-reverse">
              <Button onClick={this.onClickBackUser} color="primary">ご利用者情報を修正する</Button>
            </Box>
          )
        }

        {((isSameContractUser === '1' && mstCodeContractCode === 1) || mstCodeContractCode === 2) && memberEntry.members.map((member, index) => {
          const keyIdx = index + 1;
          return (
            <Accordion className={classes.accordionRoot} defaultExpanded key={keyIdx}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                  {`${index + 1}人目確認画面`}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <SideDisplayGrid title="氏名" content={`${member.lastName} ${member.firstName}`} />
                  <SideDisplayGrid title="氏名カナ" content={`${member.lastNameKana} ${member.firstNameKana}`} />
                  <SideDisplayGrid title="郵便番号" content={member.zipCodeBefore && `${member.zipCodeBefore}-${member.zipCodeAfter}`} />
                  <SideDisplayGrid title="都道府県" content={member.mstCodePrefectureCode && member.mstCodePrefectureCode.name} />
                  <SideDisplayGrid title="市区町村" content={member.city} />
                  <SideDisplayGrid title="町名・番地・その他" content={member.street} />
                  <SideDisplayGrid title="メールアドレス" content={member.email} />
                  <SideDisplayGrid title="電話番号" content={member.phoneBefore && `${member.phoneBefore}-${member.phoneMiddle}-${member.phoneAfter}`} />
                  <SideDisplayGrid title="電話番号2" content={member.phoneTwoBefore && `${member.phoneTwoBefore}-${member.phoneTwoMiddle}-${member.phoneTwoAfter}`} />
                  <SideDisplayGrid title="生年月日" content={member.birthdayYear && `${member.birthdayYear}年${member.birthdayMonth}月${member.birthdayDay}日`} />
                  <SideDisplayGrid
                    title="性別"
                    content={
                      initSelectValues.genderSelectList.filter(
                        (option) => option.id === member.mstCodeGenderCode,
                      ).map((data) => data.name)[0]
                    }
                  />
                </Grid>
              </AccordionDetails>
              <AccordionActions>
                <Box>
                  <Button onClick={this.onClickBackUser} color="primary">ご利用者情報を修正する</Button>
                </Box>
              </AccordionActions>
            </Accordion>
          );
        })}

        <Box mb={1}>
          <Box mt={3} fontWeight="fontWeightBold" fontSize="h6.fontSize">会員規約</Box>
          <hr />
          <div className={classes.ruleRoot}>
            {membership && membership.split('\n').map((t, i) => (
              t === '' ? <br key={`${t} ${i + 1}`} /> : <Box key={`${t} ${i + 1}`}>{t}</Box>
            ))}
          </div>
        </Box>

        <Box mb={1}>
          <Box mt={3} fontWeight="fontWeightBold" fontSize="h6.fontSize">プライバシーポリシー</Box>
          <hr />
          <div className={classes.ruleRoot}>
            {privacyPolicy && privacyPolicy.split('\n').map((t, i) => (
              t === '' ? <br key={`${t} ${i + 1}`} /> : <Box key={`${t} ${i + 1}`}>{t}</Box>
            ))}
          </div>
        </Box>

        <Box>
          <Box mt={3} textAlign="center" fontSize="14px">
            <div>入力いただきました内容をご確認、</div>
            <div>会員規約、プライバシーポリシーにご同意の上</div>
            <div>登録ボタンを押してください。</div>
          </Box>
        </Box>

        <Box align="center" my={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.onClickNextComplete}
          >
            登録する
          </Button>
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ entry: state.entry });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ConfirmForm)));
