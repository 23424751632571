export default function getStyle() {
  const styles = (theme) => ({
    root: {
      marginBottom: theme.spacing(10),
    },
    gridRoot: {
      [theme.breakpoints.down('xs')]: {
        marginTop: '20px',
      },
    },
    paper: {
      padding: theme.spacing(4),
    },
    link: {
      color: '#2196f3',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    buttonRoot: {
      height: '50px',
      color: '#fff',
      [theme.breakpoints.down('xs')]: {
        height: '45px',
      },
    },
    title: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '25px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    loginTitle: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '17px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
      },
    },
  });
  return styles;
}
