import { call, put, takeLatest } from 'redux-saga/effects';
import login from '../../../services/other/login.service';
import {
  ACTION_TYPE,
  actLoginSuccess, actLoginError,
} from './action';

/**
 * Function using for call api login
 *
 * @param userInfo
 */
function* callApiLogin({ userInfo }) {
  try {
    const dataUserModel = yield call(login, userInfo);
    yield put(actLoginSuccess(dataUserModel));
  } catch (error) {
    yield put(actLoginError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallLogin() {
  yield takeLatest(ACTION_TYPE.CALL_API_LOGIN, callApiLogin);
}
