import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Box, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  breakAll: {
    wordBreak: 'break-all',
    color: '#000',
  },
}));

export default function MemberRuleButton(props) {
  const { value } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: value ? 'block' : 'none' }}>
      <Box display="flex" alignItems="center">
        <Box mr={1}>ご予約前に</Box>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          こちら
        </Button>
        <Box ml={1}>をご確認ください</Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ご予約前にご確認ください</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.breakAll}>{value && value.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>閉じる</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
