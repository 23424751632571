import { call, put, takeLatest } from 'redux-saga/effects';
import getBanners from '../../../services/other/top.service';
import {
  ACTION_TYPE, actGetInitTopSuccess,
} from './action';
import { actNotProcServerError } from '../../common/action';

function* callApiGetBanners() {
  try {
    const banners = yield call(getBanners);
    yield put(actGetInitTopSuccess(banners));
  } catch (error) {
    yield put(actNotProcServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallTop() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_TOP, callApiGetBanners);
}
