import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * 未回答一覧
 * @param {*} param
 * @returns
 */
export function getUnanswered(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.SURVEY.UNANSWERED, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 回答済み一覧
 * @param {*} param
 * @returns
 */
export function getAnswered(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.SURVEY.ANSWERED, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 全て一覧
 * @param {*} param
 * @returns
 */
export function getAll(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.SURVEY.ALL, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
