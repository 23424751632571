import {
  all,
} from 'redux-saga/effects';
import watchCallCommon from './common/saga';
import watchCallTop from './other/top/saga';
import watchCallLogin from './other/login/saga';
import watchCallMypage from './mypage/mypage/saga';
import watchCallEntry from './entry/saga';
import watchCallResetPassword from './other/resetPassword/saga';
import watchCallTransfer from './mypage/transferRegist/saga';
import watchCallBookingList from './booking/bookingList/saga';
import watchCallBookingDetail from './booking/bookingDetail/saga';
import watchCallSurveyDetail from './mypage/surveyDetail/saga';
import watchCallBookingDetailCancelWait from './booking/bookingDetailCancelWait/saga';
import watchCallSurveyList from './mypage/surveyList/saga';
import watchCallPointHistory from './mypage/pointHistory/saga';
import watchCallAttendanceOnlineCulture from './mypage/attendanceOnlineCulture/saga';
import watchCallNoticeList from './mypage/noticeList/saga';

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */

export default function* rootSaga() {
  yield all([
    watchCallCommon(),
    watchCallTop(),
    watchCallLogin(),
    watchCallMypage(),
    watchCallEntry(),
    watchCallResetPassword(),
    watchCallTransfer(),
    watchCallBookingList(),
    watchCallBookingDetail(),
    watchCallSurveyDetail(),
    watchCallBookingDetailCancelWait(),
    watchCallSurveyList(),
    watchCallPointHistory(),
    watchCallAttendanceOnlineCulture(),
    watchCallNoticeList(),
  ]);
}
