import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Route, withRouter } from 'react-router-dom';
import {
  withStyles, Button, Dialog, DialogTitle, Box,
  DialogContentText, DialogContent, DialogActions,
} from '@material-ui/core';
import { actCallApiMe, actCallApiNoticesAgreeEntry, actCallApiResetServerError } from '../../redux/common/action';
import redirectLinks from '../../constants/redirectLinks';

const styles = () => ({
  root: {
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: '120px',
    boxSizing: 'border-box',
  },
});

class Auth extends React.Component {
  constructor(props) {
    super(props);
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token) {
      this.props.dispatch(actCallApiMe());
    }
    this.state = {
      dialogPage: 0,
      dialogOpen: false,
      noticeList: [
        {
          id: null,
          title: '',
          content: '',
        },
      ],
      noticesIds: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.common.isGetMeError === true
      || (this.props.common.isGetMeError === false && !this.props.common.user)) {
      this.reset();
    }
    if (prevProps.common.noticeList !== this.props.common.noticeList) {
      if (this.props.common.noticeList.length) {
        this.setNoticeList(this.props.common.noticeList);
      }
    }
    if (this.props.common.isServerError) {
      this.props.dispatch(actCallApiResetServerError());
      this.goErrorPage();
    }
  }

  setNoticeList = (noticeList) => {
    this.setState({
      noticeList,
      dialogOpen: true,
      dialogPage: noticeList.length - 1,
    });
  }

  nextDialog = () => {
    const { dialogPage, noticesIds, noticeList } = this.state;
    noticesIds.push(noticeList[dialogPage].id);
    this.setState({
      dialogPage: dialogPage - 1,
    });
  };

  noticesAgreeEntry = () => {
    const { noticesIds, noticeList } = this.state;
    noticesIds.push(noticeList[0].id);
    this.setState({
      dialogOpen: false,
    });
    this.props.dispatch(actCallApiNoticesAgreeEntry(noticesIds));
  }

  reset() {
    document.cookie = 'mjwt=; path=/; max-age=0';
    localStorage.clear();
  }

  goErrorPage() {
    window.location.href = redirectLinks.ERROR;
  }

  render() {
    const { classes } = this.props;
    const { noticeList, dialogOpen, dialogPage } = this.state;

    return (
      <div className={classes.root}>
        <Dialog
          open={dialogOpen}
          onClose={this.handleClose}
          disableBackdropClick
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">以下の内容を確認してください。</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" color="blcak">
              【
              {noticeList[dialogPage].title}
              】
              <Box>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: noticeList[dialogPage].content,
                  }}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box display={dialogPage === 0 ? 'none' : ''}>
              <Button onClick={this.nextDialog} color="secondary">
                確認して次へ
              </Button>
            </Box>
            <Box display={dialogPage === 0 ? '' : 'none'}>
              <Button onClick={this.noticesAgreeEntry} color="secondary">
                確認
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Route>
          {this.props.children}
        </Route>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Auth)));
