import React from 'react';
import {
  Box, Button, TextField, makeStyles,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';

const useStyles = makeStyles((theme) => ({
  iconSize: {
    fontSize: '100px',
    [theme.breakpoints.down('md')]: { fontSize: '50px' },
    [theme.breakpoints.down('xs')]: { fontSize: '20px' },
  },
  textSize: {
    [theme.breakpoints.down('md')]: { fontSize: '25px' },
    [theme.breakpoints.down('xs')]: { fontSize: '13px' },
  },
}));

export default function SurveyDetailInput(props) {
  const {
    surveyData, rating, comment, onChange, onClickSend, errorMessage,
  } = props;

  const classInfo = surveyData.data || {};
  const selects = surveyData.surveySelects || [];

  const IconContainer = (containerPorps) => {
    const { value, ...other } = containerPorps;
    const select = selects.find((x) => x.code === value) || {};
    const classes = useStyles();

    return (
      <Box mx={3} {...other}>
        <Box textAlign="center">
          <Box>
            <StarIcon
              fontSize="large"
              classes={{
                fontSizeLarge: classes.iconSize,
              }}
            />
          </Box>
          <Box className={classes.textSize}>{select.name}</Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box border={1} borderColor="grey.500" my={5} p={1}>
      <Box display="flex" justifyContent="center" alignItems="center" my={3}>
        <Box fontSize={30} fontWeight="fontWeightBold">{surveyData.data.menu}</Box>
        <Box>はいかがでしたか？</Box>
      </Box>

      <Box fontSize={{ xs: 15, sm: 20 }} display={{ xs: 'block', sm: 'flex' }} justifyContent="center" alignItems="center" mt={3} mb={{ xs: 2, sm: 0 }}>
        <Box fontWeight="fontWeightBold">受講日：</Box>
        <Box>{classInfo.attendDayTime}</Box>
      </Box>

      <Box fontSize={{ xs: 15, sm: 20 }} display={{ xs: 'block', sm: 'flex' }} justifyContent="center" alignItems="center" mb={3}>
        <Box fontWeight="fontWeightBold">講師：</Box>
        <Box>{classInfo.teacherName}</Box>
      </Box>

      <Box textAlign="center" fontSize={{ xs: 13, sm: 20 }}>受講された講義の内容について選択してください</Box>
      <Box mt={1} mb={5} textAlign="center">
        <Rating
          name="rating"
          IconContainerComponent={IconContainer}
          value={rating}
          onChange={onChange}
        />
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <FormErrorText>{errorMessage}</FormErrorText>
        </Box>
      </Box>

      <Box textAlign="center" fontSize={{ xs: 13, sm: 20 }}>
        <Box display={{ xs: 'block', md: 'flex' }} justifyContent="center" alignItems="center">
          <Box>講義について、</Box>
          <Box>率直なご意見をコメントでお寄せください。</Box>
        </Box>
        <Box>講義の質向上のため、参考にさせていただきます。</Box>
        <Box display={{ xs: 'block', md: 'flex' }} justifyContent="center" alignItems="center">
          <Box>講師側で</Box>
          <Box>会員様の特定はできませんのでご安心ください。</Box>
        </Box>
        <Box color="grey.700" display={{ xs: 'block', md: 'flex' }} mt={{ xs: 1, md: 0 }} justifyContent="center" alignItems="center">
          <Box>※全てのご意見が反映されるとは限りませんので、</Box>
          <Box>予めご了承ください。</Box>
        </Box>
      </Box>

      <Box mt={{ xs: 2, md: 5 }}>
        <TextField
          name="comment"
          multiline
          rows={10}
          placeholder="例）教室が整えられ、気持ちよく受講できた。&#13;例）講師の方がとても優しい人でした。質問にも的確に答えてくれた。よく笑う人でした。"
          variant="outlined"
          fullWidth
          value={comment}
          onChange={onChange}
        />
      </Box>
      <Box textAlign="center" my={3}>
        <Button variant="contained" color="primary" onClick={onClickSend}>
          送信
        </Button>
      </Box>
    </Box>
  );
}
