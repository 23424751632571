import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getBookingDetail, createBookingDetail,
} from '../../../services/booking/bookingDetailCancelWait.service';
import {
  ACTION_TYPE,
  actCallApiGetBookingDetailCancelWaitSuccess,
  actCallApiCreateBookingCancelWaitSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetBookingDetail({ param }) {
  try {
    const result = yield call(getBookingDetail, param);
    yield put(actCallApiGetBookingDetailCancelWaitSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiCreateBooking({ param }) {
  try {
    const result = yield call(createBookingDetail, param);
    yield put(actCallApiCreateBookingCancelWaitSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallBookingDetailCancelWait() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT, callApiGetBookingDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_CREATE_BOOKING_CANCEL_WAIT, callApiCreateBooking);
}
