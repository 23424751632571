import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * 集計
 * @returns
 */
export function getSummary() {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.POINT_HISTORY.SUMMARY)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

/**
 * 履歴一覧
 * @param {*} param
 * @returns
 */
export function getHistory(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.MYPAGE.POINT_HISTORY.HISTORY, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
