import React from 'react';
import {
  makeStyles,
  Container,
  Paper,
  Box,
  Button,
} from '@material-ui/core';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import redirectLinks from '../../../constants/redirectLinks';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    lineHeight: '2',
  },
  root: {
    padding: theme.spacing(2),
  },
  iconSize: {
    'font-size': '65px',
  },
}));

export default function NotFound() {
  const classes = useStyles();
  const onClick = () => {
    window.location.href = redirectLinks.BASE;
  };

  return (
    <div>
      <Header />
      <Container className={classes.container}>
        <Paper elevation={0} className={classes.root}>
          <Box fontWeight="fontWeightBold" fontSize={{ xs: 22, sm: 30 }} textAlign="center">
            ページが見つかりませんでした
          </Box>
          <Box mt={2} textAlign="center">
            <SentimentSatisfiedIcon className={classes.iconSize} />
          </Box>
          <Box mt={2} textAlign="center" fontSize={{ xs: 14, sm: 18 }}>
            <Box>申し訳ございませんが、お探しのページが見つかりませんでした。</Box>
            <Box>お探しのページは削除されたか、URLが変更された可能性がございます。</Box>
          </Box>
          <Box textAlign="center" mt={3}>
            <Button variant="contained" color="primary" onClick={onClick}>TOPへ戻る</Button>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
}
