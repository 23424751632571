import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import redirectLinks from '../../../constants/redirectLinks';

export default function SurveyDetailCorrect(props) {
  const { classInfo, completeData } = props;

  const onMypage = () => {
    window.location.href = redirectLinks.MYPAGE;
  };

  return (
    <Box border={1} borderColor="grey.500" textAlign="center" p={1} my={5}>

      <Box fontWeight="fontWeightBold" my={1}>アンケートの回答を送信しました。</Box>
      <Box mb={5}>ご協力ありがとうございました。</Box>

      <Box border={1} borderColor="grey.500" textAlign="left" p={1} style={{ margin: 'auto', width: '50%' }}>
        <Box fontWeight="fontWeightBold" mb={1}>アンケート対象</Box>
        <Box>{`授業：${classInfo.menu}`}</Box>
        <Box>{`受講日：${classInfo.attendDayTime}`}</Box>
        <Box>{`講師：${classInfo.teacherName}`}</Box>
        {
          completeData.pointed && (
            <>
              <Box fontWeight="fontWeightBold" mt={2}>付与ポイント</Box>
              <Box>{`${completeData.point}ポイント`}</Box>
            </>
          )
        }
      </Box>
      <Box my={3}>
        <Button variant="contained" color="primary" onClick={onMypage}>
          マイページに戻る
        </Button>
      </Box>
    </Box>
  );
}
