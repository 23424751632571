// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT: 'CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT',
  CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT_SUCCESS: 'CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT_SUCCESS',
  CALL_API_CREATE_BOOKING_CANCEL_WAIT: 'CALL_API_CREATE_BOOKING_CANCEL_WAIT',
  CALL_API_CREATE_BOOKING_CANCEL_WAIT_SUCCESS: 'CALL_API_CREATE_BOOKING_CANCEL_WAIT_SUCCESS',
  RESET_CREATE_BOOKING_CANCEL_WAIT_FLG: 'RESET_CREATE_BOOKING_CANCEL_WAIT_FLG',
};
// #endregion

// #region action
export const actCallApiGetBookingDetailCancelWait = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT,
  param,
});

export const actCallApiGetBookingDetailCancelWaitSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT_SUCCESS,
  data,
});

export const actCallApiCreateBookingCancelWait = (param) => ({
  type: ACTION_TYPE.CALL_API_CREATE_BOOKING_CANCEL_WAIT,
  param,
});

export const actCallApiCreateBookingCancelWaitSuccess = (data) => ({
  type: ACTION_TYPE.CALL_API_CREATE_BOOKING_CANCEL_WAIT_SUCCESS,
  data,
});

export const actResetCreateBookingCancelWaitFlg = () => ({
  type: ACTION_TYPE.RESET_CREATE_BOOKING_CANCEL_WAIT_FLG,
});
// #endregion
