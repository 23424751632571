import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import format from 'date-fns/format';
import { withRouter } from 'react-router-dom';
import {
  Hidden, Card, CardContent,
  Grid, AppBar, Typography,
  Button, Dialog, Box,
  withStyles, Slide, Toolbar, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import '../../../public/css/calendar.css';
import Footer from '../../common/footer/Footer';
import BookingCalendar from './components/bookingCalendar/BookingCalendar';
import Golf from './components/golf/Golf';
import Room from './components/room/Room';
import Header from '../../common/header/Header';
import SearchForm from './components/SearchForm';
import redirectLinks from '../../../constants/redirectLinks';
import {
  actSetBookingCondition, actCallApiCultureBooking,
  actCallApiRentalCoatTableTennisBooking, actCallApiRentalCoatBadmintonBooking,
  actCallApiRoomBooking, actCallApiGolfBooking, actCallApiGetBookingSelect,
  actCallApiOnlineCultureBooking,
} from '../../../redux/booking/bookingList/action';
import { getBookingParam, createParam } from './booking-list-proc';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  searchCardRoot: {
    flexGrow: 1,
    margin: '10px',
  },
  searchDivRoot: {
    border: '1px solid #7b8d01',
    borderRadius: '10px 10px 0px 0px',
    textAlign: 'center',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '24px',
  },
  searchTitle: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px 10px 0px 0px',
    padding: '15px',
    fontWeight: 700,
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class BookingList extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop();
    if (!props.location.state && !props.bookingList.condition) {
      props.history.push({
        pathname: redirectLinks.BASE,
      });
    }
    const today = format(new Date(), 'yyyy/MM/dd');
    const initParam = createParam(props, today);

    if (props.location.state) {
      props.dispatch(
        actSetBookingCondition(initParam),
      );
    }

    props.dispatch(actCallApiGetBookingSelect());

    this.state = {
      initParam,
      today,
      searchModalOpen: false,
    };

    this.getTabDatas();
  }

  scrollToTop = () => window.scrollTo(0, 0);

  handleSearchModal = () => {
    const { searchModalOpen } = this.state;
    this.setState({ searchModalOpen: !searchModalOpen });
  }

  getTabDatas = () => {
    const { initParam } = this.state;
    let param = getBookingParam(initParam);
    if (this.props.bookingList.condition) {
      param = getBookingParam(this.props.bookingList.condition);
    }

    switch (initParam.contentValue.id) {
      case 1:
        this.props.dispatch(actCallApiCultureBooking(param));
        break;
      case 17:
        this.props.dispatch(actCallApiOnlineCultureBooking(param));
        break;
      case 2:
        this.props.dispatch(actCallApiGolfBooking(param));
        break;
      case 5:
        this.props.dispatch(actCallApiRoomBooking(param));
        break;
      case 3:
        this.props.dispatch(actCallApiRentalCoatTableTennisBooking(param));
        break;
      case 4:
        this.props.dispatch(actCallApiRentalCoatBadmintonBooking(param));
        break;
      default:
        break;
    }
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      cultures,
      rentalCoatTableTennis,
      rentalCoatBadminton,
      rooms,
      golf,
      onlineCultures,
      condition,
    } = this.props.bookingList;

    const {
      searchModalOpen,
      today,
    } = this.state;

    const targetDate = condition ? condition.targetDate : today;

    return (
      <div>
        <Header />

        <div className={classes.root}>
          <Grid container>
            <Hidden mdDown>
              <Grid item lg={3} align="left">
                <Box className={classes.searchDivRoot}>
                  <Box className={classes.searchTitle}>検索条件を変更する</Box>
                  <SearchForm />
                </Box>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Card className={classes.searchCardRoot} variant="outlined">
                <CardContent>
                  <Box fontWeight="fontWeightBold">現在の検索条件</Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box mt={1}>{`予約日：${condition && format(new Date(condition.targetDate), 'yyyy年MM月dd日')}`}</Box>
                    <Box mt={1} ml={{ xs: 0, sm: 2 }}>{`店舗：${(condition && condition.storeValue) && condition.storeValue.name}`}</Box>
                  </Box>
                </CardContent>
                <Box textAlign="center" m={2}>
                  <Button size="small" color="primary" variant="contained" onClick={this.handleSearchModal}>検索条件を変更する</Button>
                </Box>
              </Card>
            </Hidden>
            <Grid item lg={9} md={12} xs={12}>
              {condition && condition.contentValue && condition.contentValue.id === 1 && (
                <BookingCalendar
                  datas={cultures ? cultures.datas : []}
                  times={cultures ? cultures.times : []}
                  memberRule={cultures ? cultures.memberRule : ''}
                  targetDate={targetDate}
                  holidays={cultures ? cultures.holidays : []}
                  contentId={condition.contentValue.id}
                />
              )}
              {condition && condition.contentValue && condition.contentValue.id === 17 && (
                <BookingCalendar
                  datas={onlineCultures ? onlineCultures.datas : []}
                  times={onlineCultures ? onlineCultures.times : []}
                  memberRule={onlineCultures ? onlineCultures.memberRule : ''}
                  targetDate={targetDate}
                  holidays={onlineCultures ? onlineCultures.holidays : []}
                  contentId={condition.contentValue.id}
                />
              )}
              {condition && condition.contentValue && condition.contentValue.id === 2 && (
                <Golf
                  targetDate={targetDate}
                  times={golf ? golf.times : []}
                  datas={golf ? golf.datas : []}
                  memberRule={golf ? golf.memberRule : ''}
                  holidays={golf ? golf.holidays : []}
                  isTargetAccepted={golf && golf.targetAccepted}
                  storeId={condition && condition.storeValue ? condition.storeValue.id : ''}
                />
              )}
              {condition && condition.contentValue && condition.contentValue.id === 5 && (
                <Room
                  targetDate={targetDate}
                  times={rooms ? rooms.times : []}
                  datas={rooms ? rooms.datas : []}
                  rooms={rooms ? rooms.rooms : []}
                  memberRule={rooms ? rooms.memberRule : ''}
                  holidays={rooms ? rooms.holidays : []}
                />
              )}
              {condition && condition.contentValue && condition.contentValue.id === 3 && (
                <BookingCalendar
                  targetDate={targetDate}
                  datas={rentalCoatTableTennis ? rentalCoatTableTennis.datas : []}
                  times={rentalCoatTableTennis ? rentalCoatTableTennis.times : []}
                  memberRule={rentalCoatTableTennis ? rentalCoatTableTennis.memberRule : ''}
                  holidays={rentalCoatTableTennis ? rentalCoatTableTennis.holidays : []}
                  contentId={condition.contentValue.id}
                />
              )}
              {condition && condition.contentValue && condition.contentValue.id === 4 && (
                <BookingCalendar
                  targetDate={targetDate}
                  datas={rentalCoatBadminton ? rentalCoatBadminton.datas : []}
                  times={rentalCoatBadminton ? rentalCoatBadminton.times : []}
                  memberRule={rentalCoatBadminton ? rentalCoatBadminton.memberRule : ''}
                  holidays={rentalCoatBadminton ? rentalCoatBadminton.holidays : []}
                  contentId={condition.contentValue.id}
                />
              )}
            </Grid>
          </Grid>
        </div>

        <Dialog
          fullScreen
          open={searchModalOpen}
          onClose={this.handleSearchModal}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleSearchModal} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>検索条件の変更</Typography>
              <Button autoFocus color="inherit" onClick={this.handleSearchModal}>閉じる</Button>
            </Toolbar>
          </AppBar>
          <SearchForm
            onModalClose={this.handleSearchModal}
          />
        </Dialog>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ bookingList: state.bookingList });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(BookingList)));
