import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

/**
 * Function use for call api transfer
 *
 * @param {object}
 */
export function getTransfers(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.TRANSFER.TRANSFER, param.param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateTransfer(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.TRANSFER.TRANSFER, null, param.param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
