import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container,
  withStyles,
  Box,
  Button,
  Paper,
} from '@material-ui/core';
import Header from '../../../common/header/Header';
import Footer from '../../../common/footer/Footer';
import redirectLinks from '../../../../constants/redirectLinks';
import BasicBreadcrumbs from '../../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import Loading from '../../../../components/atoms/loading/Loading';
import EntryStepper from '../stepper/EntryStepper';

// #region css
const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  storeTitle: {
    width: '100%',
    background: '#E7E6E6',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    margin: '0 -1px -1px 0',
    fontSize: '20px',
    justifyContent: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  storeInputArea: {
    width: '100%',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(1),
    margin: '0 -1px -1px 0',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  title: {
    width: '60%',
    background: '#E7E6E6',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    margin: '0 -1px -1px 0',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  inputArea: {
    width: '40%',
    border: '1px solid #BFBFBF',
    padding: theme.spacing(2),
    margin: '0 -1px -1px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  listRootContractor: {
    width: '100%',
    maxWidth: '60ch',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '2ch',
    margin: 'auto',
  },
  listRootUser: {
    width: '100%',
    maxWidth: '60ch',
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
  titleText: {
    fontSize: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '17px',
    },
  },
  explainText: {
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  stepperRoot: {
    padding: '0px',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
});
// #endregion

class CompleteEntry extends React.Component {
  constructor(props) {
    super(props);
    this.breadcrumbs = [
      { url: redirectLinks.ENTRY_DESCRIPTION, name: 'Web会員申込' },
      { name: '会員申込' },
    ];

    if (!props.location.state || !props.location.state.successFlg) {
      this.props.history.push({
        pathname: redirectLinks.BASE,
      });
    }

    this.state = {
      memberEntryId: {},
      isLoading: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entry.memberEntryId !== this.props.entry.memberEntryId) {
      if (this.props.entry.memberEntryId) {
        this.setMemberEntry(this.props.entry.memberEntryId);
      }
    }
  }

  setMemberEntry = (memberEntryId) => {
    this.setState({ memberEntryId, isLoading: false });
  }

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    const { classes } = this.props;
    const {
      memberEntryId,
      isLoading,
    } = this.state;
    const store = memberEntryId.store || {};

    return (
      <div>
        <Header isNotShowBtn />
        <Loading open={isLoading} />
        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />
          <EntryStepper
            activeStep={4}
            classes={classes}
          />
          <Paper variant="outlined" className={classes.root}>
            <Box fontWeight="fontWeightBold" textAlign="center" className={classes.titleText}>Web会員申込登録ありがとうございます。</Box>
            <Box textAlign="center" mt={2} mb={4} className={classes.explainText}>ご契約者様のご登録メールアドレスに「仮登録完了のお知らせ」を送信いたしました。</Box>

            <Box className={classes.listRootContractor} display="flex" style={{ display: memberEntryId.contract ? '' : 'none' }}>
              <Box md={3} xs={12} className={classes.title} fontWeight="fontWeightBold">
                申込番号
              </Box>
              <Box md={9} xs={12} className={classes.inputArea}>
                {memberEntryId.contract && memberEntryId.contract.requestNo}
              </Box>
            </Box>

            <Box textAlign="center" mt={2} className={classes.explainText}>まだ会員本登録は完了していません。</Box>
            <Box textAlign="center" mb={4} className={classes.explainText}>店舗にご来店の上、本登録を行ってください。</Box>
            <Box className={classes.listRootContractor}>
              <Box className={classes.storeTitle}>店舗情報</Box>
              <Box className={classes.storeInputArea}>
                <Box fontWeight="fontWeightBold">{store.name}</Box>
                {store.phone && (<Box mt={1}>{`電話番号:${store.phone}`}</Box>)}
                {store.zipCode && (<Box mt={1}>{`〒${store.zipCode}`}</Box>)}
                <Box>{store.address && store.address.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}</Box>
              </Box>
            </Box>
            <Box textAlign="center" mt={3}>
              <Button variant="contained" color="primary" href={redirectLinks.BASE} rel="noreferrer">
                トップ画面に戻る
              </Button>
            </Box>
          </Paper>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ entry: state.entry });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(CompleteEntry)));
