import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  withStyles,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Update as UpdateIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@material-ui/icons';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import redirectLinks from '../../../constants/redirectLinks';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import getStyle from './entry-description-design';

const styles = getStyle();

class EntryDescription extends React.Component {
  constructor() {
    super();
    this.scrollToTop();
    this.breadcrumbs = [
      { name: '会員申込' },
    ];
    this.flows = [
      { no: 'STEP.01', title: 'お申込み', content: 'ご入会店舗を選び、お客様情報を登録' },
      { no: 'STEP.02', title: 'ご来館', content: 'ご入会希望店舗にご来店いただき、本登録' },
      { no: 'STEP.03', title: 'ご利用開始', content: 'さあ、新しい人生を！' },
    ];
  }

  onLoginClick = () => {
    this.props.history.push(redirectLinks.LOGIN);
  }

  onMemberClick = () => {
    this.props.history.push(redirectLinks.INDIVIDUAL_ENTRY);
  }

  scrollToTop = () => window.scrollTo(0, 0);

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Header isNotShowBtn />
        <Container className={classes.root}>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />
          <Box display="flex" justifyContent="center" mt={4}>
            <Box className={classes.meritTitle}>
              ＼Web会員申込がラクラク＆便利／
            </Box>
          </Box>
          <Box mt={2} justifyContent="center" className={classes.meritContent}>
            <Box mr={4} variant="h2" display="flex">
              <UpdateIcon className={classes.meritIcon} />
              <div>
                <Typography variant="h5" className={classes.meritChard}>24時間</Typography>
                <Typography variant="h5" className={classes.meritChard}>申込可能</Typography>
              </div>
            </Box>
            <Box mr={4} variant="h2" display="flex" className={classes.meritXsMt}>
              <HourglassEmptyIcon className={classes.meritIcon} />
              <div>
                <Typography variant="h5" className={classes.meritChard}>店舗での手続き時間を</Typography>
                <Typography variant="h5" className={classes.meritChard}>大幅短縮</Typography>
              </div>
            </Box>
          </Box>
          <Box mt={5}>
            <Typography variant="h4" className={classes.title}>ご注意事項</Typography>
            <div className={classes.attention}>
              ※会員申込のご契約者様は16歳以上の方が対象となります。
            </div>
          </Box>
          <Box mt={6} textAlign="center">
            <Button size="large" className={`${classes.buttonRoot} ${classes.entryBtn}`} onClick={this.onMemberClick}>
              <span>利用者登録はこちら</span>
              <ArrowForwardIosIcon className={classes.btnIcon} />
            </Button>
            <Button size="large" className={`${classes.buttonRoot} ${classes.companyBtn} ${classes.btnXsMt}`} onClick={this.onLoginClick}>
              <span>会員の方はこちら</span>
              <ArrowForwardIosIcon className={classes.btnIcon} />
            </Button>
          </Box>
          <Box mt={6}>
            <Typography variant="h4" className={classes.title}>ご利用までの流れ</Typography>
            <ul className={classes.flowRoot}>
              {this.flows.map((flow) => (
                <li className={classes.flowLi} key={flow.no}>
                  <dl className={classes.flowDl}>
                    <dt className={classes.flowdt}>
                      <span className={classes.flowIcon}>{flow.no}</span>
                      <span className={classes.flowTitle}>{flow.title}</span>
                    </dt>
                    <div className={classes.flowContent}>{flow.content}</div>
                  </dl>
                </li>
              ))}
            </ul>
          </Box>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(EntryDescription));
