// #region Type
export const ACTION_TYPE = {
  RESET_ATTENDANCE_BOOKING_UPDATE_SUCCESS: 'RESET_ATTENDANCE_BOOKING_UPDATE_SUCCESS',
  CALL_API_GET_ATTENDANCE_BOOKINGS: 'CALL_API_GET_ATTENDANCE_BOOKINGS', // 予約情報
  GET_ATTENDANCE_BOOKINGS_SUCCESS: 'GET_ATTENDANCE_BOOKINGS_SUCCESS',
};
// #endregion

export const actResetAttendanceBookingUpdateSuccess = () => ({
  type: ACTION_TYPE.RESET_ATTENDANCE_BOOKING_UPDATE_SUCCESS,
});

// #region 予約タブ
export const actCallApiGetAttendanceBookings = (searchCondition) => ({
  type: ACTION_TYPE.CALL_API_GET_ATTENDANCE_BOOKINGS,
  searchCondition,
});

export const actGetAttendanceBookingsSuccess = (datas) => ({
  type: ACTION_TYPE.GET_ATTENDANCE_BOOKINGS_SUCCESS,
  datas,
});
// #endregion
