import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    background: '#f50057  ',
    color: '#fff',
    '&:hover': {
      background: '#ab003c',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}));

export default function DangerButton(props) {
  const classes = useStyles();

  const {
    variant,
    label,
    onClick,
    size,
    disableElevation,
    className,
  } = props;

  return (
    <Button
      variant={variant}
      className={`${classes.root} ${className}`}
      onClick={onClick}
      size={size}
      disableElevation={disableElevation}
    >
      {label}
    </Button>
  );
}

DangerButton.propTypes = {
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
