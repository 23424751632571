import React from 'react';
import {
  Card, CardContent,
  Box, Button, makeStyles,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
}));

export default function BookingDisplayDetail(props) {
  const {
    data, onBookingListBackClick, onClickComplete,
  } = props;
  const classes = useStyles();

  return (
    <Box display={data.id ? '' : 'none'}>
      <Box fontWeight="fontWeightBold" my={1} fontSize={15}>現在定員に達しているため、キャンセル待ちを受け付けています。</Box>
      <Box fontWeight="fontWeightBold" my={1} fontSize={15}>選択されたメニューを確認してください。</Box>
      <Box fontWeight="fontWeightBold" my={1} fontSize={15}>内容がよろしければ、「この内容でキャンセル待ち登録する」ボタンをクリックしてください。</Box>

      <Card variant="outlined">
        <CardContent>
          <Box>
            <Box fontWeight="fontWeightBold" fontSize={18}>日時</Box>
            <Box>{`${data.targetDate} (${data.week}) ${data.startTime} ～ ${data.contentId !== 5 ? data.endTime : ''}`}</Box>
          </Box>
          <Box mt={2}>
            <Box fontWeight="fontWeightBold" fontSize={18}>メニュー</Box>
            <Box>{data.menuName}</Box>
          </Box>
          <Box mt={2} display={data.content ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" fontSize={18}>内容</Box>
            <Box>
              {data.content && data.content.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
            </Box>
          </Box>
          <Box mt={2} display={data.roomName ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" fontSize={18}>Room</Box>
            <Box>{data.roomName}</Box>
          </Box>
          <Box mt={2} display={data.teacherName ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" fontSize={18}>講師</Box>
            <Box>{data.teacherName}</Box>
          </Box>
          <Box mt={2} display={data.annotation ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" fontSize={18}>特記事項</Box>
            <Box>{data.annotation}</Box>
          </Box>
          <Box mt={2} display={data.materialFee ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" fontSize={18}>教材費</Box>
            <Box>{`${data.materialFee}円`}</Box>
          </Box>
          <Box mt={2} display={data.managementFee ? '' : 'none'}>
            <Box fontWeight="fontWeightBold" fontSize={18}>管理費</Box>
            <Box>{`${data.managementFee}円`}</Box>
          </Box>
        </CardContent>
      </Card>

      <Box my={3} textAlign="center">
        <Button
          variant="contained"
          className="custom-button"
          onClick={onBookingListBackClick}
          startIcon={<ArrowBackIosIcon />}
        >
          前の画面に戻る
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonRoot}
          onClick={onClickComplete}
        >
          この内容でキャンセル待ち登録する
        </Button>
      </Box>
    </Box>
  );
}
