import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Hidden,
  TableBody,
  Chip,
} from '@material-ui/core';
import DangerButton from '../../../../../components/atoms/dangerButton/DangerButton';
import redirectLinks from '../../../../../constants/redirectLinks';
import { checkDoCancel, checkOnlineCulture } from './booking-list-proc';
import Pagination from '../../../../../components/atoms/pagination/Pagination';
import TableFrameCell from '../../../../../components/atoms/tableFrameCell/TableFrameCell';

const styles = () => ({
  box: {
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  openedChip: {
    height: '25px',
    background: '#0070c0',
    color: '#fff',
  },
  closedChip: {
    height: '25px',
    background: '#f50057',
    color: '#fff',
  },
});

class AllBookingTable extends React.Component {
  onClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 0 },
    });
  }

  onCancelClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 1 },
    });
  }

  onBookingClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.MYPAGE_BOOKING_DETAIL,
      state: { data: row, procType: 2 },
    });
  }

  onAttendanceClick = (_, row) => {
    const setUrl = `${redirectLinks.ATTENDANCE_ONLINE_CULTURE}/?key=${row.bookingCode}`;
    this.props.history.push({
      pathname: setUrl,
    });
  }

  render() {
    const {
      classes,
      datas,
      records,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
    } = this.props;
    const rows = datas.content || [];

    return (
      <>
        <Hidden mdDown>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableFrameCell>日時</TableFrameCell>
                  <TableFrameCell>コンテンツ</TableFrameCell>
                  <TableFrameCell>メニュー</TableFrameCell>
                  <TableFrameCell>ご予約状況</TableFrameCell>
                  <TableFrameCell> </TableFrameCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.bookingCode}>
                    <TableFrameCell>
                      { row.bookingStatus === 3 && (
                        <Box mb={1}><Chip label="開講" className={classes.openedChip} /></Box>
                      )}
                      { row.bookingStatus === 2 && (
                        <Box mb={1}><Chip label="休講" className={classes.closedChip} /></Box>
                      )}
                      { row.bookingEnabledReception && (
                        <Box mb={1}><Chip label="キャンセルが出たため、空きがあります" color="primary" size="small" /></Box>
                      )}
                      {`${row.targetDate} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}
                    </TableFrameCell>
                    <TableFrameCell>{row.contentName}</TableFrameCell>
                    <TableFrameCell>{row.menuName}</TableFrameCell>
                    <TableFrameCell>{row.memberStatusName}</TableFrameCell>
                    <TableFrameCell>
                      <Box display="flex">
                        { row.bookingEnabledReception && (<Box mr={2}><Button variant="contained" color="primary" onClick={(event) => this.onBookingClick(event, row)}>予約する</Button></Box>)}
                        { checkOnlineCulture(row) && (<Box mr={2}><Button variant="contained" color="primary" onClick={(event) => this.onAttendanceClick(event, row)}>出席する</Button></Box>)}
                        { checkDoCancel(row) && (<Box mr={2}><DangerButton variant="contained" label="キャンセルする" onClick={(event) => this.onCancelClick(event, row)} /></Box>)}
                        <Button variant="contained" size="small" onClick={(event) => this.onClick(event, row)}>予約内容詳細</Button>
                      </Box>
                    </TableFrameCell>
                  </TableRow>
                ))}
                {
                  rows.length < 1 && (
                    <TableRow key={0}>
                      <TableFrameCell align="center" colSpan={6}>
                        予約情報はありません
                      </TableFrameCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Hidden>
        <Hidden lgUp>
          {rows.map((row) => (
            <Box boxShadow={3} mb={3} p={2} key={row.bookingCode} className={classes.box}>
              <Box mt={2}>
                <Box>
                  { row.bookingStatus === 3 && (
                    <Box mb={1}><Chip label="開講" className={classes.openedChip} /></Box>
                  )}
                  { row.bookingStatus === 2 && (
                    <Box mb={1}><Chip label="休講" className={classes.closedChip} /></Box>
                  )}
                  { row.bookingEnabledReception && (
                    <Box mb={1}><Chip label="キャンセルが出たため、空きがあります" color="primary" size="small" /></Box>
                  )}
                </Box>
                <Box>
                  <Box fontWeight="fontWeightBold">日時</Box>
                  {`${row.targetDate} (${row.weekName}) ${row.startTime}～${row.endTime}`}
                </Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold">コンテンツ</Box>
                <Box>{row.contentName}</Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold">メニュー</Box>
                <Box>{row.menuName}</Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold">状態</Box>
                <Box>{row.memberStatusName}</Box>
              </Box>
              <Box display="flex" mt={2}>
                { row.bookingEnabledReception && (<Box mr={2}><Button variant="contained" color="primary" onClick={(event) => this.onBookingClick(event, row)}>予約する</Button></Box>)}
                { checkOnlineCulture(row) ? (<Box mr={2}><Button variant="contained" onClick={(event) => this.onAttendanceClick(event, row)}>出席する</Button></Box>) : (<div />)}
                { checkDoCancel(row) ? (<Box mr={2}><DangerButton variant="contained" label="キャンセルする" onClick={(event) => this.onCancelClick(event, row)} /></Box>) : (<div />)}
                <Button variant="contained" size="small" onClick={(event) => this.onClick(event, row)}>予約内容詳細</Button>
              </Box>
            </Box>
          ))}
          {
            rows.length < 1 && (
              <Box textAlign="center" mt={2}>予約情報はありません</Box>
            )
          }
        </Hidden>
        <Pagination
          totalElements={datas.totalElements}
          records={records}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(AllBookingTable));
