import React from 'react';
import {
  Button, Box, makeStyles,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import pointGrantLogo from '../../../public/assets/survey/point.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '150px',
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
  },
}));

export default function SurveyDetailPointGrantModal(props) {
  const { open, point, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      maxWidth="sm"
    >
      <DialogContent dividers>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={pointGrantLogo} alt="ロゴ" className={classes.logo} aria-hidden="true" />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Box fontSize={{ xs: 15, sm: 30 }} fontWeight="fontWeightBold" mr={1}>{`${point}ポイント`}</Box>
          <Box>獲得しました！</Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}
