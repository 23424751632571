const redirectLinks = {
  BASE: '/',
  LOGIN: '/login',
  ENTRY_DESCRIPTION: '/entry-description',
  MYPAGE: '/mypage',
  MYPAGE_BOOKING_DETAIL: '/mypage/booking-detail',
  TRANSFER_REGIST: '/transfer-regist',
  BOOKING: '/booking',
  BOOKING_DETAIL: '/booking-detail',
  BOOKING_DETAIL_CANCEL_WAITE: '/booking-detail-cancel-wait',
  INDIVIDUAL_ENTRY: '/individual-entry',
  CORPORATION_ENTRY: '/corporation-entry',
  COMPLETE_ENTRY: '/complete-entry',
  POINT_HISTORY: '/points',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SURVEY_DETAIL: '/surveys',
  ATTENDANCE_ONLINE_CULTURE: '/attendance-online-culture',
  ERROR: '/error',
};
export default redirectLinks;
