// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_SURVEY_UNANSWERED: 'CALL_API_GET_SURVEY_UNANSWERED',
  CALL_API_GET_SURVEY_UNANSWERED_SUCCESS: 'CALL_API_GET_SURVEY_UNANSWERED_SUCCESS',

  CALL_API_GET_SURVEY_ANSWERED: 'CALL_API_GET_SURVEY_ANSWERED',
  CALL_API_GET_SURVEY_ANSWERED_SUCCESS: 'CALL_API_GET_SURVEY_ANSWERED_SUCCESS',

  CALL_API_GET_SURVEY_ALL: 'CALL_API_GET_SURVEY_ALL',
  CALL_API_GET_SURVEY_ALL_SUCCESS: 'CALL_API_GET_SURVEY_ALL_SUCCESS',

  SET_SURVEY_LIST_SEARCH_CONDITION: 'SET_SURVEY_LIST_SEARCH_CONDITION',
};
// #endregion

export const actSetSearchCondition = (param) => ({
  type: ACTION_TYPE.SET_SURVEY_LIST_SEARCH_CONDITION,
  param,
});

export const actCallApiGetSurveyUnanswered = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_UNANSWERED,
  param,
});
export const actCallApiGetSurveyUnansweredSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_UNANSWERED_SUCCESS,
  datas,
});

export const actCallApiGetSurveyAnswered = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_ANSWERED,
  param,
});
export const actCallApiGetSurveyAnsweredSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_ANSWERED_SUCCESS,
  datas,
});

export const actCallApiGetSurveyAll = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_ALL,
  param,
});
export const actCallApiGetSurveyAllSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_GET_SURVEY_ALL_SUCCESS,
  datas,
});
