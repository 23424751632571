import React from 'react';
import {
  Button, Box, makeStyles,
  Dialog, DialogActions, DialogContent,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    background: '#f50057',
    color: '#fff',
    fontWeight: '600',
  },
  content: {
    color: '#000',
    paddingTop: '20px',
  },
}));

export default function ErrorDialog(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    messages,
  } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>以下の内容を確認してください</DialogTitle>
      <DialogContent>
        <Box id="alert-dialog-slide-description" className={classes.content}>
          {messages && messages.map((message) => (
            <Box mb={2} key={message}>
              {message}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
}
