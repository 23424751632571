import React from 'react';
import format from 'date-fns/format';
import connect from 'react-redux/es/connect/connect';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import {
  Grid,
  withStyles,
  ButtonGroup,
  Button,
  Hidden,
  Box,
} from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';
import Monthly from './Monthly';
import Weekly from './Weekly';
import Daily from './Daily';
// import List from './List';
import {
  getTodayParam,
  isBackDisabled,
  getBackParam,
  getNextParam,
} from './booking-calendar-proc';
import { getBookingParam } from '../../booking-list-proc';
import {
  actCallApiCultureBooking, actSetBookingCondition,
  actCallApiRentalCoatBadmintonBooking, actCallApiRentalCoatTableTennisBooking,
  actCallApiOnlineCultureBooking,
} from '../../../../../redux/booking/bookingList/action';
import MemberRuleButton from '../MemberRuleButton';

const styles = (theme) => ({
  root: {
    marginBottom: '100px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorBlack: {
    color: '#000000!important',
  },
  buttonGroupRoot: {
    marginTop: 'auto',
  },
  gridRoot: {
    marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  xsBox: {
    margin: '10px',
  },
});

class BookingCalendar extends React.Component {
  constructor() {
    super();
    this.state = {
      viewAlignment: 'month',
    };
  }

  scrollToTop = () => window.scrollTo(0, 0);

  handleViewAlignment = (_, newAlignment) => {
    if (!newAlignment) {
      return;
    }
    this.setState({ viewAlignment: newAlignment });
  }

  changeViewMoreLink = (_, date) => {
    const { condition } = this.props.bookingList;
    const param = {
      ...condition,
      targetDate: date,
    };
    this.props.dispatch(actSetBookingCondition(param));
    this.setState({ viewAlignment: 'day' });
    this.scrollToTop();
  }

  onTodayClick = () => {
    const param = getTodayParam(this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param.param));
    if (!param.isSome) {
      this.getData(param.param);
    }
  }

  onBackClick = () => {
    const { viewAlignment } = this.state;
    const param = getBackParam(viewAlignment, this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param.param));
    if (!param.isSome) {
      this.getData(param.param);
    }
  }

  onNextClick = () => {
    const { viewAlignment } = this.state;
    const param = getNextParam(viewAlignment, this.props.bookingList.condition);
    this.props.dispatch(actSetBookingCondition(param.param));
    if (!param.isSome) {
      this.getData(param.param);
    }
  }

  getData = (parameter) => {
    const { contentId } = this.props;
    const param = getBookingParam(parameter);
    switch (contentId) {
      case 1:
        this.props.dispatch(actCallApiCultureBooking(param));
        break;
      case 3:
        this.props.dispatch(actCallApiRentalCoatTableTennisBooking(param));
        break;
      case 4:
        this.props.dispatch(actCallApiRentalCoatBadmintonBooking(param));
        break;
      case 17:
        this.props.dispatch(actCallApiOnlineCultureBooking(param));
        break;
      default:
        break;
    }
  }

  render() {
    const {
      classes,
      datas,
      times,
      targetDate,
      memberRule,
      holidays,
    } = this.props;

    const {
      viewAlignment,
    } = this.state;

    const date = new Date(targetDate);

    return (

      <div className={classes.root}>
        <MemberRuleButton value={memberRule} />
        <Hidden xsDown>
          <Grid container className={classes.gridRoot}>
            <Grid item xs align="left" className={classes.buttonGroupRoot}>
              <ButtonGroup aria-label="large outlined button group">
                <Button onClick={this.onTodayClick}>今日</Button>
                <Button disabled={isBackDisabled(viewAlignment, targetDate)} onClick={this.onBackClick}><ArrowBackIosIcon fontSize="small" /></Button>
                <Button onClick={this.onNextClick}><ArrowForwardIosIcon fontSize="small" /></Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={6} className={classes.textCenter}>
              {format(date, 'yyyy年M月')}
            </Grid>
            <Grid item xs align="right">
              <ToggleButtonGroup
                value={viewAlignment}
                exclusive
                onChange={this.handleViewAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="month" aria-label="left aligned" className={classes.colorBlack}>月</ToggleButton>
                <ToggleButton value="week" aria-label="centered" className={classes.colorBlack}>週</ToggleButton>
                <ToggleButton value="day" aria-label="right aligned" className={classes.colorBlack}>日</ToggleButton>
                {/*
                  <ToggleButton value="list"
                  aria-label="justified" className={classes.colorBlack}>一覧</ToggleButton>
                */}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Box className={classes.xsBox}>
            <Box className={classes.textCenter}>
              {format(date, 'yyyy年M月')}
            </Box>
            <Grid container className={classes.gridRoot}>
              <Grid item xs align="left" className={classes.buttonGroupRoot}>
                <ButtonGroup aria-label="large outlined button group">
                  <Button onClick={this.onTodayClick}>今日</Button>
                  <Button disabled={isBackDisabled(viewAlignment, targetDate)} onClick={this.onBackClick}><ArrowBackIosIcon fontSize="small" /></Button>
                  <Button onClick={this.onNextClick}><ArrowForwardIosIcon fontSize="small" /></Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs align="right">
                <ToggleButtonGroup
                  size="small"
                  value={viewAlignment}
                  exclusive
                  onChange={this.handleViewAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton value="month" aria-label="left aligned" className={classes.colorBlack}>月</ToggleButton>
                  <ToggleButton value="week" aria-label="centered" className={classes.colorBlack}>週</ToggleButton>
                  <ToggleButton value="day" aria-label="right aligned" className={classes.colorBlack}>日</ToggleButton>
                  {/*
                    <ToggleButton
                      value="list" aria-label="justified" className={classes.colorBlack}>
                      一覧
                    </ToggleButton>
                  */}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>
        </Hidden>

        <Monthly
          isView={viewAlignment === 'month'}
          targetDate={date}
          datas={datas}
          changeViewMoreLink={this.changeViewMoreLink}
          holidays={holidays}
        />

        <Weekly
          isView={viewAlignment === 'week'}
          targetDate={date}
          datas={datas}
          changeViewMoreLink={this.changeViewMoreLink}
          holidays={holidays}
        />
        <Daily
          isView={viewAlignment === 'day'}
          targetDate={date}
          datas={datas}
          times={times}
          holidays={holidays}
        />

        {/* <List
          isView={viewAlignment === 'list'}
          targetDate={date}
          datas={datas}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ bookingList: state.bookingList });

export default withStyles(styles)(connect(mapStateToProps)(BookingCalendar));
