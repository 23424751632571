import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Button,
  Chip,
} from '@material-ui/core';
import redirectLinks from '../../../../../constants/redirectLinks';
import Pagination from '../../../../../components/atoms/pagination/Pagination';

const styles = () => ({
  flexTitle: {
    flex: '1 1',
  },
  cardTop: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  unansweredChip: {
    background: '#f50057',
    color: '#fff',
  },
});

class UnansweredTable extends React.Component {
  onClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.SURVEY_DETAIL,
      state: { bookingId: row.bookingId, contentId: row.contentId },
    });
  }

  render() {
    const {
      classes,
      datas,
      page,
      records,
      handleChangeRowsPerPage,
      handleChangePage,
    } = this.props;
    const rows = datas.content || [];

    return (
      <>
        {rows.map((row) => (
          <Box boxShadow={3} mt={3} key={`unswered-${row.id}`}>
            <Box p={3}>
              <div className={classes.cardTop}>
                <div className={classes.flexTitle}>
                  <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">{row.menuName}</Box>
                </div>
                <div>
                  <Chip className={classes.unansweredChip} label="未回答" />
                </div>
              </div>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={15}>受講日</Box>
                <Box fontSize={15}>{`${row.targetedOn} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}</Box>
              </Box>
              <Box mt={2} color="pink.A400">
                <Box fontWeight="fontWeightBold" fontSize={15}>講師</Box>
                <Box fontSize={15}>{row.teacherName}</Box>
              </Box>
              <Box mt={2} color="#e91e63">
                <Box fontWeight="fontWeightBold" fontSize={15}>回答期限</Box>
                <Box fontSize={15}>{`${row.surveyAnswerPeriod} (${row.periodWeekName}) 23:59まで`}</Box>
              </Box>
            </Box>
            <Box pr={4} pb={2} textAlign="right">
              <Button variant="contained" onClick={(event) => this.onClick(event, row)}>回答する</Button>
            </Box>
          </Box>
        ))}
        <Box border={1} borderColor="grey.500" display={rows.length === 0 ? 'flex' : 'none'} minHeight={100} textAlign="center" justifyContent="center" alignItems="center">
          現在アンケートは募集していません
        </Box>
        <Pagination
          totalElements={datas.totalElements}
          records={records}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(UnansweredTable));
