import { ACTION_TYPE } from './action';

const bookingDetailCancelWait = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_GET_BOOKING_DETAIL_CANCEL_WAIT_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCreateSuccess: false,
      };
    case ACTION_TYPE.CALL_API_CREATE_BOOKING_CANCEL_WAIT_SUCCESS:
      return {
        ...state,
        successData: action.data,
        isCreateSuccess: true,
      };
    case ACTION_TYPE.RESET_CREATE_BOOKING_CANCEL_WAIT_FLG:
      return {
        ...state,
        isCreateSuccess: false,
      };
    default:
      return state;
  }
};

export default bookingDetailCancelWait;
