import { ACTION_TYPE } from './action';

const entry = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_SELECT_VALUES_SUCCESS:
      return {
        ...state,
        initSelectValues: action.initSelectValues,
        count: null,
      };
    case ACTION_TYPE.SET_MEMBER_ENTRY_FORM:
      return {
        ...state,
        memberEntry: action.memberEntry,
      };
    case ACTION_TYPE.GET_ZIP_CODE_ADDRESS_SUCCESS:
      return {
        ...state,
        zipcode: action.zipcode,
      };
    case ACTION_TYPE.GET_ZIP_CODE_ADDRESS_USER_SUCCESS:
      return {
        ...state,
        zipcodeUser: action.zipcode,
      };
    case ACTION_TYPE.CALL_API_SAVE_ENTRY_SUCCESS:
      return {
        ...state,
        memberEntryId: action.memberEntry,
      };
    case ACTION_TYPE.CALL_API_DUPLICATION_CHECK_CONTRACT_SUCCESS:
      return {
        ...state,
        count: action.count,
      };

    case ACTION_TYPE.CALL_API_DUPLICATION_COUNT_CLEAR:
      return {
        ...state,
        count: null,
      };
    default:
      return state;
  }
};

export default entry;
