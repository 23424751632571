/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export default class UrlHelper {
  /**
   * Function use for convert get url
   * convert url get

   * @returns {string}
   */
  static convertGetUrl(url, parameters) {
    if (!parameters) return url;

    let newUrl = url;
    // eslint-disable-next-line guard-for-in
    for (const key in parameters) {
      newUrl = this.updateQueryStringParameter(newUrl, key, parameters[key]);
    }
    return newUrl;
  }

  /**
   * Function using for update query string parameter
   *
   * @param uri
   * @param key
   * @param value
   * @returns {*}
   */
  static updateQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    const newValue = value === null ? '' : value;
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${newValue}$2`);
    }

    return `${uri + separator + key}=${newValue}`;
  }

  /**
   * Function use for convert query url
   * convert query get

   * @returns {string}
   */
  static convertQueryUrl(url, parameters) {
    if (!parameters) return url;
    let newUrl = url;
    for (const key in parameters) {
      newUrl = newUrl.replace(`{${key}}`, parameters[key]);
    }
    return newUrl;
  }
}
