import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  withStyles, Box, Chip, Button,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import redirectLinks from '../../../../../constants/redirectLinks';
import Pagination from '../../../../../components/atoms/pagination/Pagination';

const styles = () => ({
  flexTitle: {
    flex: '1 1',
  },
  cardTop: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  answeredChip: {
    background: '#0d47a1',
    color: '#fff',
  },
  unansweredChip: {
    background: '#f50057',
    color: '#fff',
  },
  startIcon: {
    color: '#FFB400',
  },
});

class AllTable extends React.Component {
  onClick = (_, row) => {
    this.props.history.push({
      pathname: redirectLinks.SURVEY_DETAIL,
      state: { bookingId: row.bookingId, contentId: row.contentId },
    });
  }

  render() {
    const {
      classes,
      datas,
      records,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
    } = this.props;
    const rows = datas.content || [];

    return (
      <>
        {rows.map((row) => (
          <Box boxShadow={3} mt={3} key={`doingBooking-${row.bookingCode}`}>
            <Box p={3}>
              <div className={classes.cardTop}>
                <div className={classes.flexTitle}>
                  <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">{row.menuName}</Box>
                </div>
                <Box>
                  {!row.unanswered && (<Chip className={classes.answeredChip} label="回答済" />)}
                  {row.unanswered && (<Chip className={classes.unansweredChip} label="未回答" />)}
                </Box>
              </div>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" fontSize={15}>受講日</Box>
                <Box fontSize={15}>{`${row.targetedOn} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}</Box>
              </Box>
              <Box mt={2} color="pink.A400">
                <Box fontWeight="fontWeightBold" fontSize={15}>講師</Box>
                <Box fontSize={15}>{row.teacherName}</Box>
              </Box>
              <Box mt={2} display={!row.unanswered ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>評価</Box>
                {[...Array(row.surveyCode)].map(() => (
                  <StarIcon className={classes.startIcon} />
                ))}
              </Box>
              <Box mt={2} color="#e91e63" display={row.unanswered ? '' : 'none'}>
                <Box fontWeight="fontWeightBold" fontSize={15}>回答期限</Box>
                <Box fontSize={15}>{`${row.surveyAnswerPeriod} (${row.periodWeekName}) 23:59まで`}</Box>
              </Box>
              {row.unanswered && (
                <Box textAlign="right">
                  <Button variant="contained" onClick={(event) => this.onClick(event, row)}>回答する</Button>
                </Box>
              )}
            </Box>
          </Box>
        ))}
        <Box border={1} borderColor="grey.500" display={rows.length === 0 ? 'flex' : 'none'} minHeight={100} textAlign="center" justifyContent="center" alignItems="center">
          アンケートはありません
        </Box>
        <Pagination
          totalElements={datas.totalElements}
          records={records}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </>
    );
  }
}

export default withStyles(styles)(withRouter(AllTable));
