import React from 'react';
import format from 'date-fns/format';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Container,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MonthTitle from '../mypage/tabContent/monthTitle/MonthTitle';
import redirectLinks from '../../../constants/redirectLinks';
import Header from '../../common/header/Header';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import Pagination from '../../../components/atoms/pagination/Pagination';
import {
  actCallApiGetPointSummary, actCallApiGetPointHistory,
} from '../../../redux/mypage/pointHistory/action';

// #region
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  toggleButtonRoot: {
    color: '#000!important',
  },
  serachBox: {
    padding: '8px',
    alignItems: 'center',
  },
  contentRoot: {
    paddingLeft: '0',
  },
});
// #endregion

class PointHistory extends React.Component {
  constructor(props) {
    super(props);
    const targetMonth = format(new Date(), 'yyyy/MM');
    const condition = {
      targetMonth,
      page: 0,
      records: 50,
      alignment: '1',
    };
    this.state = { condition };
    this.breadcrumbs = [
      { name: 'マイページ', url: redirectLinks.MYPAGE },
      { name: 'ポイント履歴' },
    ];
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (!token) {
      this.props.history.push({
        pathname: redirectLinks.LOGIN,
      });
      return;
    }
    this.props.dispatch(actCallApiGetPointSummary());
    this.search(condition);
  }

  handleAlignment = (_, newValue) => {
    if (!newValue) {
      return;
    }
    const { condition } = this.state;
    condition.alignment = newValue;
    this.search(condition);
  }

  handleDateChange = (_, values) => {
    const { condition } = this.state;
    condition.targetMonth = values;
    this.search(condition);
  }

  handleChangePage = (_, newPage) => {
    const { condition } = this.state;
    condition.page = newPage;
    this.search(condition);
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { condition } = this.state;
    condition.records = rowsPerPage;
    this.search(condition);
  }

  search = (condition) => {
    this.props.dispatch(actCallApiGetPointHistory(condition));
  }

  render() {
    const { classes } = this.props;
    const {
      targetMonth, page, records, alignment,
    } = this.state.condition;
    const {
      summary, history,
    } = this.props.pointHistory;
    const rows = history ? history.datas.content : [];

    return (
      <>
        <Header isNotShowBtn />
        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />

          <Box my={4} border={1} borderColor="grey.500" width="fit-content" p={2}>
            <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">保有ポイント</Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box fontWeight="fontWeightBold" fontSize="h4.fontSize">{summary && summary.restPoint.toLocaleString()}</Box>
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">ポイント</Box>
            </Box>
          </Box>

          <Box>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={this.handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="1" className={classes.toggleButtonRoot}>
                獲得
              </ToggleButton>
              <ToggleButton value="2" className={classes.toggleButtonRoot}>
                利用
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <MonthTitle
            targetMonth={targetMonth}
            handleChange={this.handleDateChange}
          />

          {rows.map((row) => (
            <Box boxShadow={3} mt={3} key={`point-${row.id}-${alignment}`}>
              <Box p={3}>
                <div className={classes.cardTop}>
                  <div className={classes.flexTitle}>
                    <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">{row.menuName}</Box>
                  </div>
                </div>
                <Box mt={2}>
                  {alignment === '1' && (
                    <>
                      <Box fontWeight="fontWeightBold" fontSize={15}>有効期間</Box>
                      <Box fontSize={15}>{`${row.startedOn} ～ ${row.endedOn}`}</Box>
                    </>
                  )}
                  {alignment === '2' && (
                    <>
                      <Box fontWeight="fontWeightBold" fontSize={15}>利用日</Box>
                      <Box fontSize={15}>{row.startedOn}</Box>
                    </>
                  )}
                </Box>
                <Box mt={2}>
                  <Box fontSize={15}>{row.content}</Box>
                </Box>
                <Box mt={2}>
                  <Box fontSize={15}>{`${row.point}P`}</Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box border={1} borderColor="grey.500" display={rows.length === 0 ? 'flex' : 'none'} minHeight={100} textAlign="center" justifyContent="center" alignItems="center">
            ポイント履歴はありません
          </Box>
          <Pagination
            totalElements={history ? history.datas.totalElements : 0}
            records={records}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ pointHistory: state.pointHistory });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(PointHistory)));
