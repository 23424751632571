import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export default function getBookings(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.MYPAGE.ATTENDANCE_ONLINE_CULTURE, { id: param.bookingCode }, null)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
