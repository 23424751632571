import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getInitSelectValues, getZipCodeAddress, saveEntry, getDuplicationCheckContract,
} from '../../services/entry/entry.service';
import {
  ACTION_TYPE,
  actCallApiInitSelectValuesSuccess,
  getZipCodeAddressSuccess,
  getZipCodeAddressUserSuccess,
  actSaveEntrySuccess,
  actCallDuplicationCheckContractSuccess,
} from './action';
import { actCallApiServerError } from '../common/action';

function* callApiInitSelectValues() {
  try {
    const initSelectValues = yield call(getInitSelectValues);
    yield put(actCallApiInitSelectValuesSuccess(initSelectValues));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetZipCodeAddress({ contidion }) {
  try {
    const zipCodeAddress = yield call(getZipCodeAddress, { zipCode: contidion.zipCode });
    if (contidion.pageFlg === 0) {
      yield put(getZipCodeAddressSuccess(zipCodeAddress));
    } else {
      yield put(getZipCodeAddressUserSuccess(zipCodeAddress));
    }
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiSaveEntry(memberEntry) {
  try {
    const memberId = yield call(saveEntry, memberEntry.memberEntry);
    yield put(actSaveEntrySuccess(memberId));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiDuplicationCheckContract(contract) {
  try {
    const count = yield call(getDuplicationCheckContract, contract.contract);
    yield put(actCallDuplicationCheckContractSuccess(count));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallEntry() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_SELECT_VALUES, callApiInitSelectValues);
  yield takeLatest(ACTION_TYPE.GET_ZIP_CODE_ADDRESS, callApiGetZipCodeAddress);
  yield takeLatest(ACTION_TYPE.CALL_API_SAVE_ENTRY, callApiSaveEntry);
  yield takeLatest(
    ACTION_TYPE.CALL_API_DUPLICATION_CHECK_CONTRACT, callApiDuplicationCheckContract,
  );
}
