import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, Checkbox,
  DialogContentText, DialogTitle, Box, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  breakAll: {
    display: 'block',
    whiteSpace: 'pre-wrap',
    color: '#000',
  },
}));

export default function MemberRuleButton(props) {
  const { value, onComplete } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [opend, setOpened] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onComplete(event);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpened(true);
  };

  return (
    <div style={{ display: value ? 'block' : 'none' }}>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <Checkbox
            disabled={!opend}
            checked={checked}
            onChange={handleChange}
          />
        </Box>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          利用規約
        </Button>
        <Box ml={1}>に同意します。</Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ご予約前にご確認ください</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.breakAll}>
              {value && value.split('\n').map((t) => (
                <Box key={`value${t}`}>{t === '' ? <br /> : t}</Box>
              ))}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>閉じる</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
