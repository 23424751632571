import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getUnanswered, getAnswered, getAll,
} from '../../../services/mypage/surveyList.service';
import {
  ACTION_TYPE,
  actCallApiGetSurveyUnansweredSuccess,
  actCallApiGetSurveyAnsweredSuccess,
  actCallApiGetSurveyAllSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetUnanswered({ param }) {
  try {
    const datas = yield call(getUnanswered, param);
    yield put(actCallApiGetSurveyUnansweredSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetAnswered({ param }) {
  try {
    const datas = yield call(getAnswered, param);
    yield put(actCallApiGetSurveyAnsweredSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetAll({ param }) {
  try {
    const datas = yield call(getAll, param);
    yield put(actCallApiGetSurveyAllSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallSurveyList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SURVEY_UNANSWERED, callApiGetUnanswered);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SURVEY_ANSWERED, callApiGetAnswered);
  yield takeLatest(ACTION_TYPE.CALL_API_GET_SURVEY_ALL, callApiGetAll);
}
