import { call, put, takeLatest } from 'redux-saga/effects';
import getBookings from '../../../services/mypage/attendanceOnlineCulture.service';
import {
  ACTION_TYPE,
  actGetAttendanceBookingsSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetAttendanceBookings(param) {
  try {
    const datas = yield call(getBookings, param.searchCondition);
    yield put(actGetAttendanceBookingsSuccess(datas));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallAttendanceOnlineCulture() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ATTENDANCE_BOOKINGS, callApiGetAttendanceBookings);
}
