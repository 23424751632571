import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Button,
  withStyles,
} from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon, ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import SideInputGrid from '../../../../components/organisms/sideInputGrid/SideInputGrid';
import FormErrorText from '../../../../components/atoms/formErrorText/FormErrorText';
import PasswordField from '../../../../components/atoms/passwordFeild/PasswordField';
import { actCallSetMemberEntryForm } from '../../../../redux/entry/action';
import validation from '../validation';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  buttonFirst: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginBottom: '20px',
    },
  },
});
// #endregion

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      password: '',
    };
  }

  onClickBack = () => {
    const { onClickToNext } = this.props;
    onClickToNext(1);
  }

  onClickNext = () => {
    const { memberEntry } = this.props.entry;
    const { onClickToNext } = this.props;
    const { password } = this.state;

    const errorMessage = validation.formValidate('password', password);

    const tmpMemberEntry = {
      ...memberEntry,
      password,
    };

    if (errorMessage === '') {
      this.props.dispatch(actCallSetMemberEntryForm(tmpMemberEntry));
      onClickToNext(3);
    }

    this.setState({ errorMessage });
  }

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ password: value, errorMessage: validation.formValidate('password', value) });
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      errorMessage,
      password,
    } = this.state;

    return (
      <div className={classes.root}>
        <Box mb={3}>
          <Box my={2}>
            <Card variant="outlined">
              <CardContent>
                <Box fontWeight="fontWeightBold">ご利用にはログインが必須です。</Box>
                <Box mt={0.5} fontSize="14px">ログイン時に使用するパスワードを入力してください。</Box>
                <Box mt={0.5} fontSize="14px">ご利用者様が複数名いらっしゃる場合は、下記に入力して頂いたパスワードを皆様に設定します。</Box>
                <Box mt={0.5} fontSize="14px">登録完了後マイページにて、ご利用者様自身でパスワードの変更をお願いします。</Box>
              </CardContent>
            </Card>
          </Box>
          <SideInputGrid title="パスワード" isRequired>
            <Box>
              <PasswordField name="password" id="password" onChange={this.onChange} value={password} error={errorMessage !== ''} />
              <FormErrorText>{errorMessage}</FormErrorText>
            </Box>
          </SideInputGrid>
        </Box>

        <Box align="center" my={2}>
          <Button
            variant="contained"
            onClick={this.onClickBack}
            startIcon={<ArrowBackIosIcon />}
            className={classes.buttonFirst}
          >
            ご利用者様情報へ戻る
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.onClickNext}
            endIcon={<ArrowForwardIosIcon />}
          >
            入力内容の確認へ進む
          </Button>
        </Box>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({ entry: state.entry });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(PasswordForm)));
