import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Hidden,
  Container,
  Paper,
  Card,
} from '@material-ui/core';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import redirectLinks from '../../../constants/redirectLinks';
import TableFrameCell from '../../../components/atoms/tableFrameCell/TableFrameCell';
import BasicBreadcrumbs from '../../../components/organisms/basicBreadcrumbs/BasicBreadcrumbs';
import {
  actCallApiResetTransferFlg,
  actCallApiGetTransfers,
  actCallApiUpdateTransfer,
} from '../../../redux/mypage/transferRegist/action';
import Pagination from '../../../components/atoms/pagination/Pagination';
import Loading from '../../../components/atoms/loading/Loading';

// #region css
const styles = () => ({
  paper: {
    width: 'fit-content',
  },
  marginX: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
});
// #endregion

class TransferRegist extends React.Component {
  constructor(props) {
    super(props);

    if (!props.location.state) {
      props.history.push({
        pathname: redirectLinks.MYPAGE,
      });
    }

    const { row } = props.location.state;

    this.state = {
      page: 0,
      records: 10,
      originRow: row,
      aheadRow: row,
      datas: {},
      rows: [],
      isLoading: true,
      isComplete: false,
      errorMessages: [],
    };

    this.breadcrumbs = [
      { name: 'マイページ', url: redirectLinks.MYPAGE },
      { name: '振替登録' },
    ];
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (!token) {
      this.props.history.push({
        pathname: redirectLinks.LOGIN,
      });
    }
    this.props.dispatch(actCallApiResetTransferFlg());
    this.props.dispatch(actCallApiGetTransfers({
      page: 0,
      records: 10,
      storeId: row.storeId,
      contentId: row.contentId,
      menuId: row.menuId,
      bookingMemberId: row.id,
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transfer.datas !== this.props.transfer.datas) {
      if (this.props.transfer.datas) {
        this.setDatas(this.props.transfer.datas);
      }
    }
    if (prevProps.transfer.isUpdateSuccess !== this.props.transfer.isUpdateSuccess) {
      if (this.props.transfer.isUpdateSuccess) {
        this.setComplete(this.props.transfer.updateDatas);
      }
    }
  }

  onClick = (_, row) => {
    const { originRow } = this.state;
    this.props.dispatch(actCallApiUpdateTransfer({
      originBookingMemberId: originRow.id,
      aheadBookingId: row.id,
    }));
    this.setState({ aheadRow: row, isLoading: true });
  }

  handleChangePage = (_, newPage) => {
    const {
      originRow, records,
    } = this.state;
    this.setState({ page: newPage });

    this.props.dispatch(actCallApiGetTransfers({
      page: newPage,
      records,
      storeId: originRow.storeId,
      contentId: originRow.contentId,
      menuId: originRow.menuId,
      bookingMemberId: originRow.id,
    }));
  }

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const {
      originRow, page,
    } = this.state;
    this.setState({ records: rowsPerPage });
    this.props.dispatch(actCallApiGetTransfers({
      page,
      records: rowsPerPage,
      storeId: originRow.storeId,
      contentId: originRow.contentId,
      menuId: originRow.menuId,
      bookingMemberId: originRow.id,
    }));
  }

  setDatas(datas) {
    const rows = datas && datas.content ? datas.content : [];
    this.setState({ rows, datas, isLoading: false });
  }

  setComplete(data) {
    this.setState({ isComplete: true, isLoading: false, errorMessages: data.errorMessages });
  }

  render() {
    const { classes } = this.props;
    const {
      datas, page, records, rows, originRow, isLoading, isComplete, aheadRow, errorMessages,
    } = this.state;

    return (
      <>
        <Loading open={isLoading} />
        <Header isNotShowBtn />
        <Container>
          <BasicBreadcrumbs breadcrumbs={this.breadcrumbs} />

          <Box display={isComplete ? 'none' : ''}>
            <Box mt={5}>
              <Paper elevation={0} variant="outlined" className={classes.paper}>
                <Box p={2}>
                  <Box fontWeight="fontWeightBold" mb={1}>以下のスケジュールから振替を行います。</Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box>
                      <Box fontWeight="fontWeightBold" mb={1}>日時</Box>
                      <Box>{`${originRow.targetDate} (${originRow.weekName}) ${originRow.startTime} ～ ${originRow.endTime}`}</Box>
                    </Box>
                    <Box ml={{ xs: 0, sm: 3 }} mt={{ xs: 3, sm: 0 }}>
                      <Box fontWeight="fontWeightBold" mb={1}>コンテンツ</Box>
                      <Box>{originRow.contentName}</Box>
                    </Box>
                    <Box ml={{ xs: 0, sm: 3 }} mt={{ xs: 3, sm: 0 }}>
                      <Box fontWeight="fontWeightBold" mb={1}>メニュー名</Box>
                      <Box>{originRow.menuName}</Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box fontWeight="fontWeightBold" my={4}>振替先を以下から選択してください。</Box>
            <Hidden xsDown>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableFrameCell>日時</TableFrameCell>
                      <TableFrameCell>メニュー名</TableFrameCell>
                      <TableFrameCell> </TableFrameCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableFrameCell>
                          {`${row.targetDate} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}
                        </TableFrameCell>
                        <TableFrameCell>
                          {row.menuName}
                        </TableFrameCell>
                        <TableFrameCell>
                          <Box display="flex">
                            <Button variant="contained" size="small" onClick={(event) => this.onClick(event, row)}>この日に振替する</Button>
                          </Box>
                        </TableFrameCell>
                      </TableRow>
                    ))}
                    {
                      rows.length < 1 && (
                        <TableRow key={0}>
                          <TableFrameCell align="center" colSpan={6}>
                            振替できるスケジュールがありません
                          </TableFrameCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Hidden>
            <Hidden smUp>
              {rows.map((row) => (
                <Box boxShadow={3} mb={3} p={2} key={row.id}>
                  <Box>
                    <Box fontWeight="fontWeightBold">日時</Box>
                    <Box>
                      {`${row.targetDate} (${row.weekName}) ${row.startTime} ～ ${row.endTime}`}
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Box fontWeight="fontWeightBold">メニュー</Box>
                    <Box>{row.menuName}</Box>
                  </Box>
                  <Box mt={2} textAlign="right">
                    <Button variant="contained" size="small" onClick={(event) => this.onClick(event, row)}>この日に振替する</Button>
                  </Box>
                </Box>
              ))}
              {
                rows.length < 1 && (
                  <Box textAlign="center" mt={2}>振替できるスケジュールがありません</Box>
                )
              }
            </Hidden>
            <Pagination
              totalElements={datas && datas.totalElements}
              records={records}
              page={page}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Box>
          <Box display={isComplete && errorMessages.length > 0 ? '' : 'none'} mt={5}>
            <Card variant="outlined">
              <Box my={3} textAlign="center">
                {errorMessages.map((message) => (
                  <Box mb={2} key={message}>
                    {message}
                  </Box>
                ))}
              </Box>
            </Card>
          </Box>
          <Box display={isComplete && errorMessages.length === 0 ? '' : 'none'}>
            <Box mt={5}>
              <Paper elevation={0} variant="outlined" className={`${classes.paper} ${classes.marginX}`}>
                <Box p={2}>
                  <Box fontWeight="fontWeightBold" mb={1}>以下の通り振替が完了しました。</Box>
                  <Box display={{ xs: 'block', md: 'flex' }}>
                    <Box>
                      <Box fontWeight="fontWeightBold" mb={1}>振替前</Box>
                      <Box>{`${originRow.targetDate} (${originRow.weekName}) ${originRow.startTime} ～ ${originRow.endTime}`}</Box>
                    </Box>
                    <Box ml={{ xs: 0, md: 3 }} mt={{ xs: 3, md: 0 }}>
                      <Box fontWeight="fontWeightBold" mb={1}>振替先</Box>
                      <Box>{`${aheadRow.targetDate} (${aheadRow.weekName}) ${aheadRow.startTime} ～ ${aheadRow.endTime}`}</Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common, transfer: state.transfer });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(TransferRegist)));
